<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <form @submit.prevent="handleProfile">
          <!-- /add -->
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Modifier les données de configuration de l'utilisateur</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Nom complet</label>
                          <input
                            type="text"
                            name="full_name"
                            v-model="form.full_name"
                          
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Mobile</label>
                          <input type="text" name="phone" v-model="form.personal_phone" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Email</label>
                          <input
                            type="text"
                            name="email"
                            readonly
                            v-model="form.email"
                          
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Rôle</label>
                          <vue-select
                            :options="roles"
                            name="role_id"
                            placeholder="Select"
                            v-model="form.role"
                          />
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-md-6"></div>
                <div class="col-lg-12">
                  <button
                    class="btn btn-primary btn-md"
                    type="submit"
                    :disabled="loading"
                  >
                    <div v-if="loading" class="btn-content">
                      <b-spinner small type="grow"></b-spinner>
                      <span class="ms-2">Veuillez patienter...</span>
                    </div>

                    <div v-else class="btn-content">Mettre à jour</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- /add -->
        </form>
        <!-- /add -->

        <form @submit.prevent="handlePassword">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Réinitialisation du mot de passe de l'utilisateur</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nouveau mot de passe</label>
                    <div class="pass-group">
                      <input
                        v-if="showPassword"
                        type="text"
                        class="pass-input"
                        name="password"
                        v-model="formPasswordChange.password"
                      />
                      <input
                        v-else
                        type="password"
                        class="pass-input"
                        name="password"
                        v-model="formPasswordChange.password"
                      />
                      <span
                        class="fas toggle-password"
                        @click="toggleShow"
                        :class="{
                          'fa-eye': showPassword,
                          'fa-eye-slash': !showPassword,
                        }"
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Confirmez le nouveau mot de passe</label>
                    <div class="pass-group">
                      <input
                        v-if="showPassword1"
                        type="text"
                        class="pass-input"
                        v-model="formPasswordChange.confirmpassword"
                        name="confirmpassword"
                      />
                      <input
                        v-else
                        type="password"
                        class="pass-input"
                        v-model="formPasswordChange.confirmpassword"
                        name="confirmpassword"
                      />
                      <span
                        class="fas toggle-password"
                        @click="toggleShow1"
                        :class="{
                          'fa-eye': showPassword1,
                          'fa-eye-slash': !showPassword1,
                        }"
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button
                    class="btn btn-primary btn-md"
                    type="submit"
                    :disabled="loadingPassword"
                  >
                    <div v-if="loadingPassword" class="btn-content">
                      <b-spinner small type="grow"></b-spinner>
                      <span class="ms-2">Veuillez patienter...</span>
                    </div>

                    <div v-else class="btn-content">Mettre à jour</div>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>roles
<script setup>
import axios from "axios";
import { ref, onMounted, reactive } from "vue";
import useRoles  from '@/composables/roles'
import useUsers from '@/composables/users'


const { roles, selectRoles } = useRoles()
const { loading, errors, updateUser, loadingPassword, updatePassword } = useUsers()


const formPasswordChange = ref({
  'confirmpassword':'',
  'password':''
})


const form = ref({
  'full_name':'',
  'email':'',
  'personal_phone':'',
  'role': '',
})

const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

const getUser = async () => {
  let response = await axios.get("/users/view/" + props.id);
  form.value.full_name = response.data.data.user.full_name
  form.value.email = response.data.data.user.email
  form.value.personal_phone = response.data.data.userInfo.personal_phone
  form.value.role = response.data.data.userRole
};

const handleProfile = async () =>{
    errors.value = ''
    loading.value = true;

    let data = {
      full_name:form.value.full_name,
      personal_phone:form.value.personal_phone,
      role: form.value.role,
      email: form.value.email,
    }
    await updateUser(props.id, data)
}

const handlePassword = async () => {

  let data = {
    confirmpassword: formPasswordChange.value.confirmpassword,
    password: formPasswordChange.value.password
  }
  await updatePassword(props.id, data)
}

onMounted(() => {
  getUser()
  selectRoles()
})

</script>
<script>

export default {
  data() {
    return {
      filter: true,
      showPassword: false,
      showPassword1: false,
      password: null,
      password1: null,
      title: "Utilisateurs",
      title1: "Modifier/mettre à jour l'utilisateur",
    };
  },
  props: {
    id: String,
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
  },

  name: "newuseredit",
};
</script>
