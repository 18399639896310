<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                      ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                      ><img src="../../../assets/img/icons/excel.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                      ><img src="../../../assets/img/icons/printer.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->
            <!-- <div
              class="card"
              id="filter_inputs"
              :style="{
                display: filter ? 'block' : 'none',
              }"
            >
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Category</label>
                      <vue-select :options="Category" placeholder="Choose Category" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Sub Category</label>
                      <vue-select :options="Choose" placeholder="Choose Sub Category" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Category Code</label>
                      <vue-select :options="CT001" placeholder="CT001" />
                    </div>
                  </div>
                  <div class="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <a class="btn btn-filters ms-auto"
                        ><img src="../../../assets/img/icons/search-whites.svg" alt="img"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- /Filter -->
            <div class="table-responsive">
              <a-table
                :columns="columns"
                :data-source="subcategories"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'action'">
                    <router-link  v-if="hasPermission('modifier-sous-categorie')" class="me-3" :to="{ name: 'editsubcategory', params: { id: record.id } }">
                      <img src="../../../assets/img/icons/edit.svg" alt="img" />
                    </router-link>
                    <a
                      v-if="hasPermission('supprimer-categorie')"
                      class="me-3 confirm-text"
                      href="javascript:void(0);"
                      @click="deleteRecord(record.id)"
                    >
                      <img src="../../../assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>
</template>

<script setup>

import { onMounted } from 'vue';
import useSubCategories from '@/composables/subcategories';
import usePermissions from "@/composables/usePermissions"

const { subcategories, getSubCategories, destroySubCategory } = useSubCategories()
const { loadUserPermissions, hasPermission } = usePermissions()

onMounted(() => {
  getSubCategories()
  loadUserPermissions()
})

const deleteRecord = (id)  =>{
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Voulez‑vous vraiment supprimer cet enregistrement?",
    showCancelButton: true,
    confirmButtonColor: "#FF9F43",
    cancelButtonColor: "#d33",
    confirmButtonText: "Oui, supprimer!",
    cancelButtonText: "Non, Annuler!",
  }).then(async(result) => {
    if (result.isConfirmed) {
      await destroySubCategory(id)
      await getSubCategories()
      Swal.fire("Supprimer!", "Vous avez supprimé !.");
    }
  });
}

</script>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "LIBELLÉ",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "CATÉGORIE PARENT",
    dataIndex: "category",
    sorter: {
      compare: (a, b) => {
        a = a.category.toLowerCase();
        b = b.category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
 
  {
    title: "DATE DE CRÉATION",
    dataIndex: "created_at",
    sorter: {
      compare: (a, b) => {
        a = a.created_at.toLowerCase();
        b = b.created_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "AUTEUR",
  //   dataIndex: "auteur",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.auteur.toLowerCase();
  //       b = b.auteur.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "ACTION",
    key: "action",
    sorter: false,
  },
];

export default {
  data() {
    return {
      filter: false,
      title: "Sous catégorie",
      title1: "Liste sous catégories",
      items: [
        {
          text: "Ajouter une sous-catégorie",
          addname: "/subaddcategory",
        },
      ],
      columns,
    };
  },
  name: "subcategorylist",
};
</script>
