import { ref } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";

export default function useCustomers() {

    const customers = ref([])
    const customer = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false)


    const getCustomers = async () => {
        let response = await axios.get('/customers')
        customers.value = response.data.data
    }

    const getCustomer = async (id) => {
        let response = await axios.get('/customers/'+id)
        customer.value = response.data.data;
    }

    const viewCustomer = async (id) => {
        let response = await axios.get('/customers/view/'+id)
        customer.value = response.data.data;
    }

    const storeCustomer = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            let response = await axios.post('/customers/', data)
            
            await router.push({name: 'customerlist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const selectCustomers = async () => {
        let response = await axios.get('/customer-select')
        customers.value = response.data.data;
    }


    const updateCustomer = async (id) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.put('/customers/' +id, customer.value)
            await router.push({name: 'customerlist'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false;
            }
        }
    }

    const destroyCustomer = async (id) => {
        await axios.delete('/customers/' + id)
    }

    return {
        customer,
        customers,
        destroyCustomer,
        getCustomer,
        getCustomers,
        loading,
        errors,
        storeCustomer,
        updateCustomer,
        viewCustomer,
        selectCustomers
    }
}