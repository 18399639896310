<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <div class="search-path">
                  <a
                    class="btn btn-filter"
                    id="filter_search"
                    v-on:click="filter = !filter"
                    :class="{ setclose: filter }"
                  >
                    <img src="../../../assets/img/icons/filter.svg" alt="img" />
                    <span
                      ><img src="../../../assets/img/icons/closes.svg" alt="img"
                    /></span>
                  </a>
                </div>
                <div class="search-input">
                  <a class="btn btn-searchset"
                    ><img src="../../../assets/img/icons/search-white.svg" alt="img"
                  /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search..."
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                      ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                      ><img src="../../../assets/img/icons/excel.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                      ><img src="../../../assets/img/icons/printer.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->
            <div
              class="card"
              id="filter_inputs"
              :style="{
                display: filter ? 'block' : 'none',
              }"
            >
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Enter Name" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Enter Reference No" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <vue-select :options="Completed" placeholder="Completed" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <a class="btn btn-filters ms-auto"
                        ><img src="../../../assets/img/icons/search-whites.svg" alt="img"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Filter -->
            <div class="table-responsive">
              <a-table
                :columns="columns"
                :data-source="deliveries"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'delivery_status'">
                    <span
                      class="badges bg-lightgreen"
                      v-if="record.status === 2"
                      >Livré</span
                    >
                    <span
                      class="badges bg-lightyellow"
                      v-if="record.status === 0"
                      >En cours de traitement</span>
                      <span
                      class="badges bg-lightblue"
                      v-if="record.status === 1"
                      >Livraison en cours</span
                    >
                    <span
                      class="badges bg-lightred"
                      v-if="record.status === 3"
                      >Annulé</span>
                    </template
                  >
                  <template v-if="column.key === 'total_amount'">
                    <span>{{ $globalFunction.formatCurrency(record.total_amount) }}</span>
                  </template>
                  <!-- <template v-else-if="column.key === 'pay'">
                    <span class="badges bg-lightgreen" v-if="record.payment_status === 'paid'">{{
                      record.payment_status
                    }}</span>
                    <span class="badges bg-lightred" v-else="record.payment_status === 'due'">{{
                      record.payment_status
                    }}</span></template
                  > -->
                  <template v-else-if="column.key === 'action'">
                    <div class="text-center">
                      <a
                        class="action-set"
                        href="javascript:void(0);"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <router-link :to="{name:'deliveries-details', params:{uuid:record.uuid}}" class="dropdown-item"
                            ><img
                              src="../../../assets/img/icons/eye1.svg"
                              class="me-2"
                              alt="img"
                            />Detail</router-link
                          >
                        </li>
                        <!-- <li> -->
                        <template v-if="record.status == 0">
                          <li>
                            <a href="javascript:void(0);" @click="handlerSendDelivery(record.id)" class="dropdown-item confirm-text"
                              ><img
                                src="../../../assets/img/icons/transfer1.svg"
                                class="me-2"
                                alt="img"
                              />Expédié</a
                            >
                          </li>
                        </template>

                        <template v-if="record.status == 1">
                          <li>
                            <a href="javascript:void(0);" @click="handlerCanceledDelivery(record.id)" class="dropdown-item confirm-text"
                              ><img
                                src="../../../assets/img/icons/closes.svg"
                                class="me-2"
                                alt="img"
                              />Annulé</a
                            >
                          </li>
                        </template>
                         
                        <!-- </li> -->
                        <!-- <li>
                          <a
                            href="javascript:void(0);"
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#showpayment"
                            ><img
                              src="../../../assets/img/icons/dollar-square.svg"
                              class="me-2"
                              alt="img"
                            />Paiements</a
                          >
                        </li> -->
                        <!-- <li>
                          <a
                            href="javascript:void(0);"
                            class="dropdown-item"
                            @click.prevent="addPayment(record)"
                            ><img
                              src="../../../assets/img/icons/plus-circle.svg"
                              class="me-2"
                              alt="img"
                            />Nouveau paiement</a
                          >
                        </li> -->
                        <!-- <li>
                          <a href="ja  vascript:void(0);" class="dropdown-item"
                            ><img
                              src="../../../assets/img/icons/download.svg"
                              class="me-2"
                              alt="img"
                            />Download pdf</a
                          >
                        </li> -->
                        <li>
                          <a v-if="hasPermission('supprimer-livraison')" href="javascript:void(0);" @click="deleteRecord(record.id)" class="dropdown-item confirm-text"
                            ><img
                              src="../../../assets/img/icons/delete1.svg"
                              class="me-2"
                              alt="img"
                            />Supprimer</a
                          >
                        </li>
                      </ul>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>

</template>
<script setup>
import useDeliveries from '@/composables/deliveries'
import usePermissions from "@/composables/usePermissions"
import { onMounted } from "vue";

const { deliveries, getDeliveries, deleteDelivery, sendDelivered, cancelDelivered } = useDeliveries()
const { loadUserPermissions, hasPermission } = usePermissions()

onMounted(() => {
  getDeliveries()
  loadUserPermissions()
})

const deleteRecord = (id)  =>{

  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Voulez‑vous vraiment supprimer cet enregistrement?",
    showCancelButton: true,
    confirmButtonColor: "#FF9F43",
    cancelButtonColor: "#d33",
    confirmButtonText: "Oui, supprimer!",
    cancelButtonText: "Non, Annuler!",
  }).then(async(result) => {
    if (result.isConfirmed) {
      await deleteDelivery(id)
      await getDeliveries()
      Swal.fire("Supprimer!", "Vous avez supprimé !.");
    }
  });
}


const handlerSendDelivery = async (id) => {

  await sendDelivered(id)
  await getDeliveries()
}

const handlerCanceledDelivery = async (id) => {

  await cancelDelivered(id)
  await getDeliveries()
}

</script>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "NOM DU LIVREUR",
    dataIndex: "delivery_person",
    sorter: {
      compare: (a, b) => {
        a = a.delivery_person.toLowerCase();
        b = b.delivery_person.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "BOUTIQUE",
    dataIndex: "warehouse_name",
    sorter: {
      compare: (a, b) => {
        a = a.warehouse_name.toLowerCase();
        b = b.warehouse_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "DATE DE CRÉATION",
    dataIndex: "add_date",
    sorter: (a, b) => a.add_date.localeCompare(b.add_date),
  },
  {
    title: "DATE PRÉVUE DE LIVRAISON",
    dataIndex: "expected_delivery_date",
    sorter: {
      compare: (a, b) => {
        a = a.expected_delivery_date.toLowerCase();
        b = b.expected_delivery_date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "RÉFÉRENCE",
    dataIndex: "delivery_reference",
    sorter: {
      compare: (a, b) => {
        a = a.delivery_reference.toLowerCase();
        b = b.delivery_reference.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "COMMANDE",
    dataIndex: "order_reference",
    sorter: {
      compare: (a, b) => {
        a = a.order_reference.toLowerCase();
        b = b.order_reference.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  
  // {
  //   title: "Remise",
  //   dataIndex: "discount_amount",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.discount_amount.toLowerCase();
  //       b = b.discount_amount.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Livraison",
  //   dataIndex: "shipping_amount",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.shipping_amount.toLowerCase();
  //       b = b.shipping_amount.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "MONTANT TTC",
    dataIndex: "total_amount",
    key: "total_amount",
    sorter: {
      compare: (a, b) => {
        a = a.total_amount.toLowerCase();
        b = b.total_amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "STATUT",
    dataIndex: "status",
    key: "delivery_status",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Auteur",
  //   dataIndex: "auteur",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.auteur.toLowerCase();
  //       b = b.auteur.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Date de creation",
  //   dataIndex: "add_date",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.add_date.toLowerCase();
  //       b = b.add_date.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "ACTION",
    key: "action",
    sorter: true,
  },
];
export default {
  data() {
    return {
      filter: false,
      title: "Livraisons",
      title1: "Liste des livraisons",
      // items: [
      //   {
      //     text: "Ajouter une nouvelle commande",
      //     addname: "/add-sales",
      //   },
      // ],
      columns,
      Completed: ["Completed", "Paid"],
    };
  },
  name: "deliverieslist",
};
</script>
