import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useZones() {
    const zones = ref([])
    const zone = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);

    const getZones = async () => {
        let response = await axios.get('/zones')
        zones.value = response.data.data;
    }


    const getZone = async (id) => {
        let response = await axios.get('/zones/'+id)
        zone.value = response.data.data;
    }

    const storeZone = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            await axios.post('/zones/', data)
            await router.push({name: 'zone-list'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const updateZone = async (id) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.put('/zones/' +id, zone.value)
            await router.push({name: 'zone-list'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false;
            }
        }
    }

    const destroyZone = async (id) => {
        await axios.delete('/zones/' + id)
    }

    const updateStatusZone = async (id) => {
        
        let response = await axios.put('/update-status-zone/' + id)
    }

    return {
        zone,
        zones,
        errors,
        getZones,
        getZone,
        storeZone,
        updateZone,
        destroyZone,
        updateStatusZone,
        loading,
    }
}