<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <form @submit.prevent="handlerSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label> Nom de catégorie</label>
                    <input type="text" name="name" v-model="form.name" />
                    <span class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      id="textarea"
                      v-model="form.comment"
                      name="comment"
                      rows="3"
                      max-rows="6"
                    ></textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label> Cliquez ici pour télécharger une image</label>
                    <div class="image-upload">
                      <input type="file" style="height: 200px;" accept="image/*" @change="handleImageSelected"/>
                      <div class="image-uploads" >
                        <img style="height: 150px; width: 150px"  v-show="imageUrl" :src="imageUrl" alt="img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">

                  <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                    <div v-if="loading" class="btn-content">
                      <b-spinner small type="grow"></b-spinner>
                      <span class="ms-2">Veuillez patienter...</span>
                    </div>

                    <div v-else class="btn-content">Enregistrer</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<script setup>
import useCategories from '../../../composables/categories'
import useImageUpload from "@/composables/imageUpload";
import { reactive} from 'vue'


const { imageFile, imageUrl, handleImageSelected } = useImageUpload();
const { errors, storeCategory, loading } = useCategories()


const form = reactive({
    name: '',
    comment: '',
})

const handlerSubmit = async () => {
  const formData = new FormData()
  formData.append("name", form.name)
  formData.append("comment", form.comment)
  formData.append("image", imageFile.value)
  await storeCategory(formData)
}

</script>
<script>
export default {
  data() {
    return {
      title: "Catégories",
      title1: "Ajouter / Modifier une catégorie",
    };
  },
  name: "addcategory",
};
</script>
