<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <!-- /product list -->
        <form @submit.prevent="handlerSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="form-group">
                    <label>Permission</label>
                    <input type="text" name="name" v-model="permission.name" />
                    <span class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-12">
                    <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                      <div v-if="loading" class="btn-content">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="ms-2">Veuillez patienter...</span>
                      </div>
                      <div v-else class="btn-content">Enregistrer</div>
                    </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- /product list -->
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import usePermissions from '@/composables/permissions'

const { errors, getPermission, permission, updatePermission, loading } = usePermissions()

const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

onMounted(() => {
  getPermission(props.id)
})

const handlerSubmit = async () => {
  await updatePermission(props.id)
}
</script>
<script>
export default {
  data() {
    return {
      title: "Autorisations",
      title1: "Modifier une autorisation",
    };
  },
  name: "editpermission",
};
</script>
