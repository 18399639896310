<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar></sidebar>
      <div class="page-wrapper">
        <div class="content">
          <pageheader :title="title" :title1="title1" />
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Boutique </label>
                    <vue-select disabled :options="warehouses" placeholder="Choisir" name="warehouse_id" v-model="order.warehouse_id"/>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Date de commande</label>
                    <VueDatePicker v-model="order.order_date" name="purchase_date" disabled  :format="format" :format-locale="fr"></VueDatePicker>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Date prévue de la livraison</label>
                    <VueDatePicker v-model="order.expected_delivery_date" name="expected_delivery_date"  :format="format" :format-locale="fr"></VueDatePicker>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>N° </label>
                    <input type="text" v-model="order.reference" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Statut livraison</label>
                    <vue-select :options="status" name="status" v-model="form.status" placeholder="Statut livraison" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Choisir un livreur</label>
                    <vue-select :options="users" name="status" v-model="form.delivery_person_id" placeholder="Choisir un livreur" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Modes de livraison</label>
                    <vue-select :options="Delivery_method" name="subcategory_id" disabled v-model="order.be_delivered" @select="deliveryMethodChangeEvent($event)" placeholder="Choisir un mode de livraison" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Livraison avec facture ?</label>
                    <vue-select :options="Deliver_with_invoice" name="subcategory_id" disabled v-model="order.delivery_with_invoice" placeholder="Choisir" />
                  </div>
                </div>
<!--                <div class="col-lg-12 col-sm-6 col-12">
                  <div class="auto-complete-product-container">
                      <div class="form-group">
                        <label>Nom du produit</label>
                          <div class="input-groupicon">
                            <input
                              type="text"
                              placeholder="Scannez/recherchez le produit par code et sélectionnez..."
                              v-model='searchText'
                              @keyup='loadSuggestions()'
                              disabled
                            />
                            <div class="addonset">
                              <img src="../../../assets/img/icons/scanners.svg" alt="img" />
                            </div>
                          </div>
                          <div class='customer-search-suggestion' v-if="suggestiondata.length" >
                              <div class="customer-search-suggestion-inner">
                                <ul> 
                                    <li v-for= '(item,i) in suggestiondata' @click='addCart(item)' :key="item.id"> 
                                        {{ item.designation }} 
                                    </li>  
                                </ul>
                              </div>
                          </div>  
                      </div>
                  </div>
                </div>-->
              </div>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead style="background-color: #9f9f9f;">
                      <tr>
                        <th>Nom du produit</th>
                        <th>Qté</th>
<!--                        <th>Qté livrée</th>-->
                        <th>Prix d'achat</th>
                        <th>Remise %</th>
                        <th>TVA %</th>
                        <th>Montant TTC</th>
                        <th class="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(itemcart, i) in order.order_items" :key="itemcart.id">
                        <td>
                          <span v-if="itemcart.product">
                            <a href="javascript:void(0);">{{ itemcart.product.designation }}</a>
                          </span>
                          <span v-else>
                            <a href="javascript:void(0);">{{ itemcart.designation }}</a>
                          </span>
                        </td>
                        <td>
                          <input
                            type="number"
                            class="form-control"
                            v-model="itemcart.quantity"
                            name="number"
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            v-model="itemcart.unit_price"
                            name="number"
                            readonly
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            class="form-control"
                            v-model="itemcart.product_discount"
                            name="number"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            class="form-control"
                            v-model="itemcart.product_tax"
                            name="number"
                          />
                        </td>
                        <td class="text-end">
                          <span v-if="itemcart.quantity">
                            {{ (itemcart.quantity * itemcart.unit_price) - (itemcart.quantity * itemcart.unit_price * itemcart.product_discount / 100) +  (itemcart.quantity * itemcart.unit_price * itemcart.product_tax / 100)}}
                          </span>
                          <span v-else></span>
                        </td>
                        <td>
                          <a class="delete-set" @click="deleteInvoiceItems(itemcart.id, i)"
                            ><img
                              src="../../../assets/img/icons/delete.svg"
                              alt="svg"
                          /></a>
                        </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 float-md-right">
                  <div class="total-order">
                    <ul>
                      <li>
                        <h4>TOTAL HT</h4>
                        <h5>{{ SubTotal() }}</h5>
                      </li>
                      <li>
                        <h4>TOTAL REMISE (-)</h4>
                          <h5>{{ TotalDiscount() }}</h5>
                      </li>
                      <li>
                        <h4>TOTAL TVA (+)</h4>
                        <h5>{{ TotalTax() }}</h5>
                      </li>

                      <li>
                        <h4>FRAIS LIVRAISON (+)</h4>
                        <h5>{{ order.shipping_amount }}</h5>
                      </li>
                      
                      <li class="total">
                        <h4>TOTAL TTC</h4>
                        <h5>{{ Total() }}</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
              
                <div class="col-lg-12">
                  <button class="btn btn-primary btn-md" type="submit" :disabled="loading" @click="handlerSubmit">
                      <div v-if="loading" class="btn-content">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="ms-2">Veuillez patienter...</span>
                      </div>
  
                      <div v-else class="btn-content">Enregistrer</div>
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script setup>
  import axios from "axios"
  import { ref, onMounted } from "vue";
  import useWarehouses from "@/composables/warehouses";
  import useUsers from '@/composables/users'
  import useDeliveries from '@/composables/deliveries'
  import { fr } from 'date-fns/locale';


  const { warehouses, selectWarehouses } = useWarehouses()

  const { users, getUtilisateursParRole } = useUsers()

  const { loading, errors, storeOrderDelivery } = useDeliveries()

  const order = ref([])
  let searchText = ref('')
  let suggestiondata = ref([])
  let formData = ref([])

  let form = ref({
    id:'',
    status: 0,
    delivery_person_id:'',
    delivery_address: ''
  })

  const props = defineProps({
      id: {
          required: true,
          type: String
      }
  })

  const addCart = (item) => {
    searchText.value = ''
    suggestiondata.value =''

    let product_check = order.value.order_items.find((product) => product.product_id === item.id);

    if (product_check) {
      product_check.quantity++;
    } else {
      const itemcart = {
        product_id: item.id,
        designation: item.designation,
        unit_price: item.cost,
        ordered_quantity: item.quantity,
        quantity: 1,
      }
      order.value.order_items.push(itemcart)
    }

  }

  const SubTotal = () => {
    let total = 0

    if (order.value.order_items) {

      order.value.order_items.map((data) =>{
        total = total + (data.quantity*data.unit_price)
      })
    }
    return total
  }

  const TotalDiscount = () => {
    let total = 0;
    if (order.value.order_items) {
      order.value.order_items.map((data) => {
        total = total + (data.quantity * data.unit_price * data.product_discount / 100);
      });
    }
    return total;
  };

  const TotalTax = () => {
    let total = 0;

    if (order.value.order_items) {
      order.value.order_items.map((data) => {
        total = total +  (data.quantity * data.unit_price * data.product_tax / 100);
      });
    }
    return total;
  };

  const Total = () => {
    if (order.value.order_items && order.value.order_items.length > 0) {
      return SubTotal() - TotalDiscount() + TotalTax() + order.value.shipping_amount
    }else {
      return 0
    }
  }

  const loadSuggestions = async () =>{
    let response = await axios.get('/product-search?s='+searchText.value)
    suggestiondata.value = response.data
  }


  onMounted( async () => {
    getOrder()
     selectWarehouses()
     getUtilisateursParRole('commercial')
  })

  const date = ref(new Date());
  const format = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  const getOrder = async () => {
    let response = await axios.get('/order-detail/'+props.id)
    order.value = response.data.data
  }

  const deleteInvoiceItems = (id,i) => {

    order.value.order_items.splice(i,1);
  }


  const handlerSubmit = async () => {
    if (order.value.order_items.length >= 1){


        let subtotal =0
        subtotal = SubTotal()

        let total = 0
        total = Total()

        let totaldiscount = 0;
        totaldiscount = TotalDiscount();


        let totaltax = 0;
        totaltax = TotalTax();

        const formData = new FormData()

        formData.append('order_items', JSON.stringify(order.value.order_items))
        formData.append('expected_delivery_date', order.value.expected_delivery_date)
        formData.append('subtotal', subtotal)
        formData.append('shipping_amount', order.value.shipping_amount)
        formData.append('total', total)
        formData.append("totaldiscount", totaldiscount);
        formData.append("totaltax", totaltax);
        formData.append('order_id', props.id)
        formData.append('status', form.value.status)
        formData.append('delivery_person_id', form.value.delivery_person_id)

       await storeOrderDelivery(formData)
    }
    else{
      alert("Impossible d'enregistrer la livraison")
    }
  }

  </script>
  <script>
  export default {
    data() {
      return {
        title: "Commande boutiques",
        title1: "Ajouter une nouvelle livraison",
        status: [{id: "0", text: "En cours de traitement"}, {id: "1", text: "Livraison en cours"}],
        Delivery_method: [
          { id: "0", text: "Livraison en point relais" },
          { id: "1", text: "Livraison à domicile" },
        ],
        Deliver_with_invoice: [
          { id: "0", text: "NON" },
          { id: "1", text: "OUI" },
        ],
      };
    },
    name: "adddelivery",
  };
  </script>
  