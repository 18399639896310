<!-- <template>
  <div class="main-wrapper">
    <div class="account-content">
      <div class="login-wrapper">
        <div class="login-content">
          <div class="login-userset">
            <div class="login-logo logo-normal">
              <img src="../../../assets/img/logo.png" alt="img" />
            </div>
            <router-link to="/index" class="login-logo logo-white">
              <img src="../../../assets/img/logo-white.png" alt="" />
            </router-link>
            <div class="login-userheading">
              <h3>Reset Password</h3>
            </div>
            <div class="form-login">
              <label>Password</label>
              <div class="pass-group">
                <input
                  v-if="showPassword"
                  type="text"
                  class="pass-input"
                  placeholder="Enter your password"
                />
                <input
                  v-else
                  type="password"
                  class="pass-input"
                  placeholder="Enter your password"
                />
                <span
                  @click="toggleShow"
                  class="fas toggle-password"
                  :class="{
                    'fa-eye': showPassword,
                    'fa-eye-slash': !showPassword,
                  }"
                ></span>
              </div>
            </div>
            <div class="form-login">
              <label>Confirm Password</label>
              <div class="pass-group">
                <input
                  type="password"
                  class="pass-inputs"
                  placeholder="Enter your password"
                />
                <span class="fas toggle-passwords fa-eye-slash"></span>
              </div>
            </div>
            <div class="form-login">
              <a class="btn btn-login">Reset Password</a>
            </div>
          </div>
        </div>
        <div class="login-img">
          <img src="../../../assets/img/login.jpg" alt="img" />
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  data() {
    return {
      showPassword: false,
      password: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  name: "resetpassword",
};
</script> -->


<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper cardhead">
      <div class="content container-fluid">
        <pageheader :title="title" :title1="title1" />

        <div class="row">
          <div class="col-lg-12">
            <form @submit.prevent="handleSubmit">
              <div class="card">
                <div class="card-header">
                  <h5 class="card-title">Modifier votre mot de passe</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                          <label>Mot de passe actuel</label>
                          <div class="pass-group">
                            <input
                              v-if="showPassword2"
                              type="text"
                              class="pass-input"
                              name="current_password"
                              v-model="form.current_password"
                            />
                            <input
                              v-else
                              type="password"
                              class="pass-input"
                              name="current_password"
                              v-model="form.current_password"
                            />
                            <span
                              class="fas toggle-password"
                              @click="toggleShow2"
                              :class="{
                                'fa-eye': showPassword2,
                                'fa-eye-slash': !showPassword2,
                              }"
                            ></span>
                          </div>
                          <span class="text-danger" v-if="errors?.current_password">{{ errors.current_password[0] }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Nouveau mot de passe</label>
                          <div class="pass-group">
                            <input
                              v-if="showPassword"
                              type="text"
                              class="pass-input"
                              name="new_password"
                              v-model="form.new_password"
                            />
                            <input
                              v-else
                              type="password"
                              class="pass-input"
                              name="new_password"
                              v-model="form.new_password"
                            />
                            <span
                              class="fas toggle-password"
                              @click="toggleShow"
                              :class="{
                                'fa-eye': showPassword,
                                'fa-eye-slash': !showPassword,
                              }"
                            ></span>
                          </div>
                          <span class="text-danger" v-if="errors?.new_password">{{ errors.new_password[0] }}</span>
                        </div>
                      </div>
                    </div>
                    <div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Confirmez le nouveau mot de passe</label>
                        <div class="pass-group">
                          <input
                            v-if="showPassword1"
                            type="text"
                            class="pass-input"
                            v-model="form.new_confirm_password"
                            name="new_confirm_password"
                          />
                          <input
                            v-else
                            type="password"
                            class="pass-input"
                            v-model="form.new_confirm_password"
                            name="new_confirm_password"
                          />
                          <span
                            class="fas toggle-password"
                            @click="toggleShow1"
                            :class="{
                              'fa-eye': showPassword1,
                              'fa-eye-slash': !showPassword1,
                            }"
                          ></span>
                        </div>
                        <span class="text-danger" v-if="errors?.new_confirm_password">{{ errors.new_confirm_password[0] }}</span>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <button
                        class="btn btn-primary btn-md"
                        type="submit"
                        :disabled="loading"
                      >
                        <div v-if="loading" class="btn-content">
                          <b-spinner small type="grow"></b-spinner>
                          <span class="ms-2">Veuillez patienter...</span>
                        </div>

                        <div v-else class="btn-content">Mettre à jour</div>
                      </button>
                    </div>
                    </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import useAuth from '@/composables/auth'
import { ref, reactive } from 'vue'

const { loading, storePasswordChange, errors } = useAuth()

const form = reactive({
  'new_confirm_password':'',
  'new_password':'',
  'current_password':'',
})

const handleSubmit = async () => {
  await storePasswordChange(form)
}
</script>
<script>
export default {
  data() {
    return {
      showPassword: false,
      showPassword1: false,
      showPassword2: false,
      title: "Gestion mot de passe",
      title1: "Mettre à jour votre mot de passe",
    };
  },

  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    },
  },

  name: "resetpassword",
};
</script>
