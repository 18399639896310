<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar></sidebar>
      <div class="page-wrapper">
        <div class="content">
          <pageheader :title="title" :title1="title1" />

          <div class="row" v-if="displayIframe">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-body" style="text-align: center">
                  <iframe ref="iframe" :src="iframeSrc" width="600" height="600"></iframe>
<!--                  <div class="row">
                    <div class="col-lg-12">
                      <button class="btn btn-primary btn-md" type="submit" @click="handlerCancelOperation()">
                        Annuler l'opération
                      </button>
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="row"> -->
            <!-- <div class="col-md-12"> -->
            <div class="card" v-if="displayPaymentInfo">
              <div class="card-body">
                <div class="col-lg-6 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Reference facture</label>
                    <input type="text" readonly v-model="form.delivery_reference" />
                  </div>
                </div>
                <!-- <div class="col-lg-6 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Received Amount</label>
                    <input type="text" value="0.00" />
                  </div>
                </div> -->
                <div class="col-lg-6 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Montant du paiement</label>
                    <input type="text" readonly v-model="form.amount" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <button class="btn btn-primary btn-md" type="submit" :disabled="loading" @click="handlerSubmit">
                      <div v-if="loading" class="btn-content">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="ms-2">Veuillez patienter...</span>
                      </div>
  
                      <div v-else class="btn-content">Suivant</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          <!-- </div> -->
          <!-- </div> -->

        </div>
      </div>
    </div>
  </template>
  <script setup>
    import axios from "axios";
    import { onMounted, ref } from "vue";
    import { useRouter } from 'vue-router';
    
    let form = ref([]);
    const router = useRouter()
    const loading = ref(false);
    const iframeSrc = ref('');
    const displayIframe = ref(false);
    const displayPaymentInfo = ref(true);
    const stopped = ref(false);
    let intervalId = null;

    const props = defineProps({
        uuid: {
            required: true,
            type: String
        }
    })

    const indexForm = async () => {
        let response = await axios.get("/create-transaction/"+props.uuid);
        form.value = response.data;
    }

    onMounted(() => indexForm())

    const handlerSubmit = async () => {
      const formData = new FormData();
      formData.append("transaction_id", form.value.transaction_id);
      formData.append("customer_name", form.value.customer_name);
      formData.append("customer_id", form.value.customer_id);
      formData.append("distri_seller_name", form.value.distri_seller_name);
      formData.append("distri_seller_id", form.value.distri_seller_id);
      formData.append("plateforme_name", form.value.plateforme_name);
      formData.append("description", form.value.description);
      formData.append("amount", form.value.amount);
      formData.append("apiKey", '262425053964adkcz02q1x.7323710');
      formData.append("site_id", '6076583');

      loading.value = true;
      const requestOptions = {
        method: "post",
        body: formData
      };
      const response = await fetch("https://pay.distriforce.shop/api/distriforce-check/payment", requestOptions);
      const resp = await response.json();
      if (resp.code == 201) {
        loading.value = false;
        displayIframe.value = true;
        displayPaymentInfo.value = false;
        let payment_url = ''+resp.data.payment_url
        iframeSrc.value = payment_url;

        // let data = {
        //   site_id: '6076583',
        //   transaction_id: form.value.transaction_id,
        //   apiKey: '262425053964adkcz02q1x.7323710'
        // }
        // intervalId = setInterval(() => ckeckPaymentStatus(data), 1 * 60 * 1000);

        // setTimeout(() => {
        //   clearInterval(intervalId);
        //   stopped.value = true;
        //   console.log('expirer')
        // }, 2 * 60 * 1000);
      }
      else{
        loading.value = false;
      }
    }


    const ckeckPaymentStatus = async (data) =>{
      const requestOptions = {
        method: "post",
        body: data
      };
      const response = await fetch("https://pay.distriforce.shop/api/check/verify", requestOptions);

      const resp = await response.json();

      console.log(resp);

    }

    const  handlerCancelOperation = async () => {
        router.push({name: 'consultdelivery'})
    }

  </script>
  <script>
  export default {
    data() {
      return {
        title: "Paiement",
        title1: "Ajouter un nouveau paiement",
      };
    },
    name: "addpayment",
  };
  </script>
  