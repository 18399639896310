
import axios from 'axios';

export default {
  async fetchUserPermissions() {
    try {
      let response = await axios.get('auth-permissions');
        return response.data.data;
    } catch (error) {
      return [];
    }
  },
};