<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <form @submit.prevent="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 col-sm-8 col-12">
                  <div class="form-group">
                    <label>Nom fournisseur</label>
                    <input type="text" name="full_name" v-model="form.full_name" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-8 col-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" name="email" v-model="form.email" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-8 col-12">
                  <div class="form-group">
                    <label>Téléphone</label>
                    <input type="text" name="phone" v-model="form.phone" />
                  </div>
                </div>
                <div class="col-lg-12 col-12">
                  <div class="form-group">
                    <label>Adresse</label>
                    <textarea class="form-control" name="address" v-model="form.address"></textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <textarea class="form-control" name="commentaire" v-model="form.commentaire"></textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button class="btn btn-primary btn-md" :disabled="loading">
                        <div v-if="loading" class="btn-content">
                          <b-spinner small type="grow"></b-spinner>
                          <span class="ms-2">Veuillez patienter...</span>
                        </div>
    
                        <div v-else class="btn-content">Enregistrer</div>
                      </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<script setup>
import useSuppliers from '@/composables/suppliers';
import { reactive } from 'vue';
const { errors, loading, storeSupplier } = useSuppliers()

const form = reactive({
  'full_name':'',
  'email': '',
  'phone': '',
  'address': '',
  'commentaire': '',
})

const handleSubmit = async () =>{
  await storeSupplier({...form})
}

</script>
<script>
export default {
  data() {
    return {
      title: "Nos fournisseurs",
      title1: "Ajouter/mettre à jour un client",
    };
  },
  name: "addsupplier",
};
</script>
