import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useWarehouses() {
    const warehouses = ref([])

    const getWarehouses = async () => {
        let response = await axios.get('/warehouses')
        warehouses.value = response.data.data;
    }

    const selectWarehouses = async () => {
        let response = await axios.get('/warehouse-select')
        warehouses.value = response.data.data;
    }


    const selectShop = async () => {
        let response = await axios.get('/shop-select')
        warehouses.value = response.data.data;
    }


    return {
        warehouses,
        getWarehouses,
        selectWarehouses,
        selectShop
    }
}