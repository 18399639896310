<template>
  <div class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">
          <div class="login-content">
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <img src="../../../assets/img/logo.png" alt="img" />
              </div>
              <router-link to="/index" class="login-logo logo-white">
                <img src="../../../assets/img/logo-white.png" alt="" />
              </router-link>
              <div class="login-userheading">
                <h3>Se connecter</h3>
                <h4>Veuillez vous connecter à votre compte</h4>
              </div>
              <form
                class="login"
                @submit.prevent="handlerSubmit"
              >
                <div class="form-login">
                  <label>Email</label>
                  <div class="form-addons">
                    <input
                      name="email"
                      type="text"
                      class="form-control"
                      v-model="form.email"
                    />
                    <span class="text-danger" v-if="errors?.email">{{ errors.email[0] }}</span>
                    <img src="../../../assets/img/icons/mail.svg" alt="img" />
                  </div>
                </div>
                <div class="form-login">
                  <label>Mot de passe</label>
                  <div class="pass-group">
                    <input
                      v-if="showPassword"
                      name="password"
                      type="text"
                      class="form-control pass-input"
                      v-model="form.password"
                    />
                    <input
                      v-else
                      name="password"
                      type="password"
                      class="form-control pass-input"
                      v-model="form.password"
                    />
                    <span
                      @click="toggleShow"
                      class="fas toggle-password"
                      :class="{
                        'fa-eye': showPassword,
                        'fa-eye-slash': !showPassword,
                      }"
                    ></span>
                    <span class="text-danger" v-if="errors?.password">{{ errors.password[0] }}</span>
                  </div>
                </div>
                <div class="form-login">
                  <button class="btn btn-login" :disabled="loading">
                    <div v-if="loading">
                      <b-spinner small type="grow"></b-spinner>
                      <span class="ms-5">Chargement...</span>
                    </div>
                    <span v-else>Se connecter</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="login-img">
            <img src="../../../assets/img/login.jpg" alt="img" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script setup>
import useAuth from '@/composables/auth'
import { ref, onMounted} from 'vue'

const { loading, login, errors, checkTokenValidity } = useAuth()


onMounted(() => {
  checkTokenValidity()
})

const form = ref({
  'email':'',
  'password':'',
})

const handlerSubmit = async () => {
     let data = {
        email: form.value.email,
        password: form.value.password,
      };

      await login(data)
}
</script>
<script>
export default {
  data() {
    return {
      showPassword: false,
      password: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
