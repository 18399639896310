<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <div class="search-path">
                  <a
                    class="btn btn-filter"
                    id="filter_search"
                    v-on:click="filter = !filter"
                    :class="{ setclose: filter }"
                  >
                    <img src="../../../assets/img/icons/filter.svg" alt="img" />
                    <span
                      ><img src="../../../assets/img/icons/closes.svg" alt="img"
                    /></span>
                  </a>
                </div>
                <div class="search-input">
                  <a class="btn btn-searchset"
                    ><img src="../../../assets/img/icons/search-white.svg" alt="img"
                  /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search..."
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                      ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                      ><img src="../../../assets/img/icons/excel.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                      ><img src="../../../assets/img/icons/printer.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->
            <div
              class="card"
              id="filter_inputs"
              :style="{
                display: filter ? 'block' : 'none',
              }"
            >
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <datepicker
                        v-model="startdate"
                        placeholder="Choose Date"
                        class="picker cal-icon"
                        :editable="true"
                        :clearable="false"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Enter Reference " />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <vue-select :options="Choose" placeholder="Choose Customer" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <vue-select :options="Choose" placeholder="Choose Status" />
                    </div>
                  </div>
                  <div class="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div class="form-group">
                      <a class="btn btn-filters ms-auto"
                        ><img src="../../../assets/img/icons/search-whites.svg" alt="img"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Filter -->
            <!-- <div class="table-responsive"> -->
              <a-table
                :columns="columns"
                :data-source="quotations"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
                <template #bodyCell="{ column, record }">

                  <template v-if="column.key === 'total_amount'">
                    <span>{{ $globalFunction.formatCurrency(record.total_amount) }}</span>
                  </template>
                  
                  <template v-if="column.key === 'id'">
                    <span class="badges bg-lightgrey" v-if="record.status === 0">En cours</span>
                    <span
                      class="badges bg-lightgreen"
                      v-else-if="record.status === 1"
                      >Accepté</span
                    >
                    <span
                      class="badges bg-lightred"
                      v-else="record.status === 2"
                      >Refusé</span
                    ></template
                  >

                  <template v-else-if="column.key === 'action'">
                    <div class="text-center">
                      <a
                        class="action-set"
                        href="javascript:void(0);"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <router-link :to="{name:'quotations-details', params: {id:record.id}}" class="dropdown-item"
                            ><img
                              src="../../../assets/img/icons/eye1.svg"
                              class="me-2"
                              alt="img"
                            />Détails</router-link
                          >
                        </li>
                        <li>
                          <router-link v-if="hasPermission('modifier-devis')" :to="{ name:'editquotation' , params:{id:record.id}}" class="dropdown-item"
                            ><img
                              src="../../../assets/img/icons/edit.svg"
                              class="me-2"
                              alt="img"
                            />Modifier</router-link
                          >
                        </li>
                        <li>
                          <a v-if="hasPermission('supprimer-devis')" href="javascript:void(0);" class="dropdown-item confirm-text"
                            ><img
                              src="../../../assets/img/icons/delete1.svg"
                              class="me-2"
                              alt="img"
                            />Supprimer</a
                          >
                        </li>
                      </ul>
                    </div>
                  </template>
                </template>
              </a-table>
            <!-- </div> -->
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue';
import useQuotations from '@/composables/quotations'
import usePermissions from "@/composables/usePermissions"

const {quotations, getQuotations } = useQuotations()
const { loadUserPermissions, hasPermission } = usePermissions()

onMounted(() => {
  getQuotations()
  loadUserPermissions()
})
</script>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },

  {
    title: "REFERENCE",
    dataIndex: "reference",
    sorter: {
      compare: (a, b) => {
        a = a.reference.toLowerCase();
        b = b.reference.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "BOUTIQUE",
    dataIndex: "warehouse_name",
    sorter: (a, b) => a.warehouse_name.localeCompare(b.warehouse_name),
  },
  {
    title: "STATUT",
    dataIndex: "status",
    key: "id",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "MONTANT TTC",
    dataIndex: "total_amount",
    key: 'total_amount',
    sorter: {
      compare: (a, b) => {
        a = a.total_amount.toLowerCase();
        b = b.total_amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "ACTION",
    key: "action",
    sorter: true,
  },
];
export default {
  data() {
    return {
      filter: false,
      title: "Devis clients",
      title1: "Liste devis",
      items: [
        {
          text: "Ajouter un nouveau devis",
          addname: "/addquotation",
        },
      ],
      columns,
      startdate: currentDate,
      Choose: ["Choose Customer", "Customer1"],
      Status: ["Choose Status", "Inprogress", "Complete"],
    };
  },
  name: "quotationlist",
};
</script>
