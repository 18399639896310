import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useSales() {
    
    const sales = ref([])
    const sale = ref([])

    const getSales = async () => {
        let response = await axios.get('/sales')
        sales.value = response.data.data
    }

    const getSale = async(id) => {

        let response = await axios.get('/view-sale/'+id)
        sale.value = response.data.data
    }


    const destroySale = async(id) => {
        let response = await axios.delete('/delete-sale/'+ id)
    }

    const getSaleReport = async() => {
        let response = await axios.get('/salesreport')
        sales.value = response.data.data
    }

    return {
        sales,
        getSales,
        getSale,
        sale,
        destroySale,
        getSaleReport
    }


}