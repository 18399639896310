<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <div class="search-input">
                  <!-- <a class="btn btn-searchset"
                    ><img src="../../../../assets/img/icons/search-white.svg" alt="img"
                  /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search..."
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div> -->
                </div>
              </div>
              <!-- <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                      ><img src="../../../../assets/img/icons/pdf.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                      ><img src="../../../../assets/img/icons/excel.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                      ><img src="../../../../assets/img/icons/printer.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div> -->
            </div>
            <div class="table-responsive">
              <a-table
                :columns="columns"
                :data-source="currencies"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id'">
                    <div
                      class="status-toggle d-flex justify-content-between align-items-center"
                    >
                      <input
                        type="checkbox"
                        :id="record.slug"
                        class="check"
                        @change="handlerChange(record.id)"
                        :checked="record.checked"
                      />
                      <label :for="record.slug" class="checktoggle">checkbox</label>
                    </div></template
                  >
                  <template v-else-if="column.key === 'action'">
                    <router-link v-if="hasPermission('modifier-devise')" class="me-3" :to="{ name: 'editcurrency', params: {id: record.id}}">
                      <img src="../../../../assets/img/icons/edit.svg" alt="img" />
                    </router-link>
                    <a
                      v-if="hasPermission('supprimer-devise')"
                      class="me-3 confirm-text"
                      href="javascript:void(0);"
                      @click="deleteRecord(record.id)"
                    >
                      <img src="../../../../assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>
</template>

<script setup>

import  useCurrencies from '@/composables/currencies'
import usePermissions from "@/composables/usePermissions"
import { onMounted } from 'vue'

const { getCurrencies, currencies, destroyCurrency , updateStatusCurrency} = useCurrencies()
const { loadUserPermissions, hasPermission } = usePermissions()

onMounted(() => {
  getCurrencies()
  loadUserPermissions()
})

const deleteRecord = (id)  =>{
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Voulez‑vous vraiment supprimer cet enregistrement?",
    showCancelButton: true,
    confirmButtonColor: "#FF9F43",
    cancelButtonColor: "#d33",
    confirmButtonText: "Oui, supprimer!",
    cancelButtonText: "Non, Annuler!",
  }).then(async(result) => {
    if (result.isConfirmed) {
      await destroyCurrency(id)
      await getCurrencies()
      Swal.fire("Supprimer!", "Vous avez supprimé !.");
    }
  });
}


const handlerChange = async (id) => {
  await updateStatusCurrency(id)
  await getCurrencies()
}

</script>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "LIBELLÉ",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "CODE",
    dataIndex: "code",
    sorter: (a, b) => a.code.localeCompare(b.code),
  },
  {
    title: "SYMBOLE",
    dataIndex: "symbol",
    sorter: {
      compare: (a, b) => {
        a = a.symbol.toLowerCase();
        b = b.symbol.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "STATUT",
    key: "id",
    sorter: true,
  },
  {
    title: "ACTION",
    key: "action",
    sorter: true,
  },
];

export default {
  data() {
    return {
      filter: false,
      title: "Paramètres de devise",
      title1: "Gérer les paramètres de devise",
      items: [
        {
          text: "Ajouter une nouvelle devise",
          addname: "/addcurrency",
        },
      ],
      columns,
    };
  },
  name: "currencylist",
};
</script>
