import { ref } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";

export default function useSuppliers() {

    const suppliers = ref([])
    const supplier = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false)


    const getSuppliers = async () => {
        let response = await axios.get('/suppliers')
        suppliers.value = response.data.data
    }

    const getSupplier = async (id) => {
        let response = await axios.get('/suppliers/'+id)
        supplier.value = response.data.data;
    }

    const viewSupplier = async (id) => {
        let response = await axios.get('/supplier/view/'+id)
        supplier.value = response.data.data;
    }


    const storeSupplier = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            let response = await axios.post('/suppliers/', data)
            await router.push({name: 'supplierlist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const updateSupplier = async (id) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.put('/suppliers/' +id, supplier.value)
            await router.push({name: 'supplierlist'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false;
            }
        }
    }

    const destroySupplier = async (id) => {
        await axios.delete('/suppliers/' + id)
    }


    const selectSuppliers = async () => {

        let response = await axios.get('/supplier-select')
        suppliers.value = response.data.data;
    }

    return {
        supplier,
        suppliers,
        destroySupplier,
        getSupplier,
        getSuppliers,
        loading,
        errors,
        viewSupplier,
        updateSupplier,
        storeSupplier,
        selectSuppliers
    }
}