<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card">
          <div class="card-body">
            <div class="card-sales-split">
              <!-- <h2>N° : {{ purchase.reference }}</h2> -->
              <ul>
                <!-- <li>
                  <a href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/edit.svg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/excel.svg" alt="img"
                  /></a>
                </li> -->
                <li>
                  <a href="javascript:void(0);"
                  v-print="printObj"
                    ><img src="../../../assets/img/icons/printer.svg" alt="img"
                  /></a>
                </li>
              </ul>
            </div>
            <div id="printMe">
              <div
                class="invoice-box table-height"
                style="
                  max-width: 1600px;
                  width: 100%;
                  overflow: auto;
                  margin: 15px auto;
                  padding: 0;
                  font-size: 14px;
                  line-height: 24px;
                  color: #555;
                ">
                <table
                  cellpadding="0"
                  cellspacing="0"
                  style="width: 100%; line-height: inherit; text-align: left"
                >
                  <tbody>
                    <tr class="top">
                      <td colspan="6" style="padding: 5px; vertical-align: top">
                        <table style="width: 100%; line-height: inherit; text-align: left">
                          <tbody>
                            <tr>
                              <td
                                style="
                                  padding: 5px;
                                  vertical-align: top;
                                  text-align: left;
                                  padding-bottom: 20px;
                                "
                              >
                                <font style="vertical-align: inherit; margin-bottom: 25px"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #7367f0;
                                      font-weight: 600;
                                      line-height: 35px;
                                    "
                                    >Client</font
                                  ></font
                                ><br />

                                <template v-if="delivery.auteur">
                                  <font style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                  >
                                    {{ delivery.auteur.name }}</font></font><br />
                                </template>
                                
                                <br/>
                              </td>
                              <td
                                style="
                                  padding: 5px;
                                  vertical-align: top;
                                  text-align: left;
                                  padding-bottom: 20px;
                                "
                              >
                                <font style="vertical-align: inherit; margin-bottom: 25px"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #7367f0;
                                      font-weight: 600;
                                      line-height: 35px;
                                    "
                                    >Boutique</font
                                  ></font
                                ><br />
                                <template v-if="delivery.warehouse">
                                  <font style="vertical-align: inherit"
                                    ><font
                                      style="
                                        vertical-align: inherit;
                                        font-size: 14px;
                                        color: #000;
                                        font-weight: 400;
                                      "
                                    >
                                      {{ delivery.warehouse.warehouse_name }}
                                    </font></font
                                  ><br />
                                  <font style="vertical-align: inherit"
                                    ><font
                                      style="
                                        vertical-align: inherit;
                                        font-size: 14px;
                                        color: #000;
                                        font-weight: 400;
                                      "
                                    >
                                      {{  delivery.warehouse.warehouse_phone }}</font
                                    ></font
                                  ><br />
                                  <font style="vertical-align: inherit"
                                    ><font
                                      style="
                                        vertical-align: inherit;
                                        font-size: 14px;
                                        color: #000;
                                        font-weight: 400;
                                      "
                                    >
                                    {{  delivery.warehouse.warehouse_address }}</font
                                    ></font
                                  ><br />
                                </template>
                              </td>
                              <td
                                style="
                                  padding: 5px;
                                  vertical-align: top;
                                  text-align: left;
                                  padding-bottom: 20px;
                                "
                              >
                                <font style="vertical-align: inherit; margin-bottom: 25px"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #7367f0;
                                      font-weight: 600;
                                      line-height: 35px;
                                    "
                                    >Facture</font
                                  ></font
                                ><br />
                                <font style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                  >
                                  Référence
                                  </font></font
                                ><br />
                                <!-- <font style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                  >
                                  Statut de paiement</font
                                  ></font
                                ><br /> -->
                                <font style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                  >
                                  Statut</font
                                  ></font
                                ><br />
                              </td>
                              <td
                                style="
                                  padding: 5px;
                                  vertical-align: top;
                                  text-align: right;
                                  padding-bottom: 20px;
                                "
                              >
                                <font style="vertical-align: inherit; margin-bottom: 25px"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #7367f0;
                                      font-weight: 600;
                                      line-height: 35px;
                                    "
                                    >&nbsp;</font
                                  ></font
                                ><br />
                                <font style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                    > {{ delivery.reference }}
                                  </font></font
                                ><br />
                              
                                <font style="vertical-align: inherit"
                                  v-if="delivery.status == 0 "
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #f90;
                                      font-weight: 400;
                                    "
                                  >
                                  En cours de traitement</font
                                  ></font
                                >
                                <font style="vertical-align: inherit"
                                  v-if="delivery.status == 2"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #28c76f;
                                      font-weight: 400;
                                    "
                                  >
                                    Livré</font
                                  ></font
                                >
                                <font style="vertical-align: inherit"
                                  v-if="delivery.status == 1"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #0b7fe6;
                                      font-weight: 400;
                                    "
                                  >
                                  Livraison en cours</font
                                  ></font
                                >
                                <font style="vertical-align: inherit"
                                  v-if="delivery.status == 3"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #e60b0b;
                                      font-weight: 400;
                                    "
                                  >
                                  Annulé</font
                                  ></font
                                >
                                <br/>
                                <!-- <font style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #2e7d32;
                                      font-weight: 400;
                                    "
                                  >
                                    Completed</font
                                  ></font
                                ><br /> -->
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr class="heading" style="background: #f3f2f7">
                      <td
                        style="
                          padding: 5px;
                          vertical-align: middle;
                          font-weight: 600;
                          color: #5e5873;
                          font-size: 14px;
                          padding: 10px;
                        "
                      >
                        #
                      </td>
                      <td
                        style="
                          padding: 5px;
                          vertical-align: middle;
                          font-weight: 600;
                          color: #5e5873;
                          font-size: 14px;
                          padding: 10px;
                        "
                      >
                        Nom produit
                      </td>
                      <td
                        style="
                          padding: 5px;
                          vertical-align: middle;
                          font-weight: 600;
                          color: #5e5873;
                          font-size: 14px;
                          padding: 10px;
                        "
                      >
                        Quantité
                      </td>
                      <td
                        style="
                          padding: 5px;
                          vertical-align: middle;
                          font-weight: 600;
                          color: #5e5873;
                          font-size: 14px;
                          padding: 10px;
                        "
                      >
                        Prix
                      </td>
                      <td
                        style="
                          padding: 5px;
                          vertical-align: middle;
                          font-weight: 600;
                          color: #5e5873;
                          font-size: 14px;
                          padding: 10px;
                        "
                      >
                        Remise %
                      </td>
                      <td
                        style="
                          padding: 5px;
                          vertical-align: middle;
                          font-weight: 600;
                          color: #5e5873;
                          font-size: 14px;
                          padding: 10px;
                        "
                      >
                        TVA %
                      </td>
                      <td
                        style="
                          padding: 5px;
                          vertical-align: middle;
                          font-weight: 600;
                          color: #5e5873;
                          font-size: 14px;
                          padding: 10px;
                        "
                      >
                        Montant TTC
                      </td>
                    </tr>

                    <tr class="details" v-for="(item,i) in delivery.deliveries_items" :key="item.id" style="border-bottom: 1px solid #e9ecef">
                      <td
                        style="
                          padding: 10px;
                          vertical-align: top;
                          display: flex;
                          align-items: center;
                        "
                      >
                      {{ i+1 }}
                      </td>
                      <td style="padding: 10px; vertical-align: top">  {{ item.product.designation }}</td>
                      <td style="padding: 10px; vertical-align: top">{{ item.quantity }}</td>
                      <td style="padding: 10px; vertical-align: top">{{ $globalFunction.formatCurrency(item.unit_price) }}</td>
                      <td style="padding: 10px; vertical-align: top">{{ item.product_discount }}</td>
                      <td style="padding: 10px; vertical-align: top">{{ item.product_tax }}</td>
                      <td style="padding: 10px; vertical-align: top">{{  $globalFunction.formatCurrency((item.quantity * item.unit_price) - ((item.quantity * item.unit_price) * item.product_discount / 100) + ((item.quantity * item.unit_price) * item.product_tax / 100))  }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="row">
                  <div class="col-lg-6">
                  </div>
                  <div class="col-lg-6">
                    <div class="total-order w-100 max-widthauto m-auto mb-4">
                      <ul>
                        <li>
                          <h4>TOTAL HT</h4>
                          <h5>{{ $globalFunction.formatCurrency(delivery.subtotal_amount) }}</h5>
                        </li>
                        <li>
                          <h4>TOTAL REMISE (-)</h4>
                          <h5>{{ $globalFunction.formatCurrency(delivery.discount_amount) }}</h5>
                        </li>
                        <li>
                          <h4>TOTAL TVA (+)</h4>
                          <h5>{{ $globalFunction.formatCurrency(delivery.tax_amount) }}</h5>
                        </li>
                        <li class="total">
                          <h4>TOTAL TTC</h4>
                          <h5>{{ $globalFunction.formatCurrency(delivery.total_amount) }}</h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import useDeliveries from '@/composables/deliveries'
import { onMounted } from "vue";

const { getDelivery, delivery } = useDeliveries()

const props = defineProps({
  uuid: {
        required: true,
        type: String
    }
})

onMounted(() => getDelivery(props.uuid))

</script>
<script>
export default {
  data() {
    return {
      title: "Livraison clients",
      title1: "Voir détails livraison",
      printLoading: true,
      printObj: {
        id: "printMe",
        popTitle: 'Reçu de la livraison',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        beforeOpenCallback (vue) {
          vue.printLoading = true
          console.log('asadzaman')
        },
        openCallback (vue) {
          vue.printLoading = false
          console.log('asadzaman')
        },
        closeCallback (vue) {
          console.log('asadzaman')
        }
      },
    };
  },
  name: "sales-details",
};
</script>
