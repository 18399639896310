<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <!-- <div class="page-header">
          <div class="page-title">
            <h4>Paramètres de paiement</h4>
            <h6>Gérer les paramètres de paiement</h6>
          </div>
          <div class="page-btn">
            <a class="btn btn-added" @click.prevent="addNew()"
              ><img src="../../../assets/img/icons/plus.svg" alt="img" class="me-2" />Nouveau paiement</a
            >
          </div>
        </div> -->
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <div class="search-input">
                  <!-- <a class="btn btn-searchset"
                    ><img src="../../../assets/img/icons/search-white.svg" alt="img"
                  /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search..."
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div> -->
                </div>
              </div>
              <!-- <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                      ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                      ><img src="../../../assets/img/icons/excel.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                      ><img src="../../../assets/img/icons/printer.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div> -->
            </div>
            <div class="table-responsive">
              <a-table
                :columns="columns"
                :data-source="payments"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id'">
                    <div
                      class="status-toggle d-flex justify-content-between align-items-center"
                    >
                      <input
                        type="checkbox"
                        :id="record.id"
                        class="check"
                        :checked="record.checked"
                        @change="handlerChange(record.id)"
                      />
                      <label :for="record.id" class="checktoggle">checkbox</label>
                    </div></template
                  >
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end">
                      <router-link v-if="hasPermission('modifier-mode-paiement')" class="me-3" :to="{ name: 'editpaymentsettings', params: {id:record.id}}">
                        <img src="../../../assets/img/icons/edit.svg" alt="img" />
                      </router-link>
                      <a
                      v-if="hasPermission('supprimer-mode-paiement')"
                        class="me-3 confirm-text"
                        href="javascript:void(0);"
                        @click="deleteRecord(record.id)"
                      >
                        <img src="../../../assets/img/icons/delete.svg" alt="img" />
                      </a>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>

</template>

<script setup>
import { onMounted } from 'vue'
import usePermissions from "@/composables/usePermissions"
import usePayments from '@/composables/payments'

const  { getPayments, payments, deletePayment, updateStatusPayment } = usePayments()
const { loadUserPermissions, hasPermission } = usePermissions()

onMounted(() => {
  getPayments()
  loadUserPermissions()
})

const handlerChange = async (id) => {
  await updateStatusPayment (id)
  await getPayments()
}

const deleteRecord = (id)  =>{

  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Voulez‑vous vraiment supprimer cet enregistrement?",
    showCancelButton: true,
    confirmButtonColor: "#FF9F43",
    cancelButtonColor: "#d33",
    confirmButtonText: "Oui, supprimer!",
    cancelButtonText: "Non, Annuler!",
  }).then(async(result) => {
    if (result.isConfirmed) {
      await deletePayment(id)
      await getPayments()
      Swal.fire("Supprimer!", "Vous avez supprimé !.");
    }
  });
}

</script>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "Nom paiement",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Statut",
    key: "id",
    sorter: false,
  },
  {
    title: "Action",
    key: "action",
    class: "text-end",
    sorter: false,
  },
];
export default {
  data() {
    return {
      filter: false,
      title: "Modes de paiement",
      title1: "Liste des modes de paiements",
      items: [
        {
          text: "Ajouter un mode de paiement",
          addname: "/addpaymentsettings",
        },
      ],
      columns,
    };
  },
  name: "paymentsettings",
};
</script>
