<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <div class="search-path">
                  <a
                    class="btn btn-filter"
                    id="filter_search"
                    v-on:click="filter = !filter"
                    :class="{ setclose: filter }"
                  >
                    <img src="../../../assets/img/icons/filter.svg" alt="img" />
                    <span
                      ><img src="../../../assets/img/icons/closes.svg" alt="img"
                    /></span>
                  </a>
                </div>
                <div class="search-input">
                  <a class="btn btn-searchset"
                    ><img src="../../../assets/img/icons/search-white.svg" alt="img"
                  /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search..."
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                      ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                      ><img src="../../../assets/img/icons/excel.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                      ><img src="../../../assets/img/icons/printer.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->
            <div
              class="card"
              id="filter_inputs"
              :style="{
                display: filter ? 'block' : 'none',
              }"
            >
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-lg col-sm-6 col-12">
                    <div class="form-group">
                      <datepicker
                        v-model="startdate"
                        class="picker cal-icon"
                        :editable="true"
                        :clearable="false"
                      />
                    </div>
                  </div>
                  <div class="col-lg col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Enter Reference" />
                    </div>
                  </div>
                  <div class="col-lg col-sm-6 col-12">
                    <div class="form-group">
                      <vue-select :options="Choose" placeholder="Choose Supplier" />
                    </div>
                  </div>
                  <div class="col-lg col-sm-6 col-12">
                    <div class="form-group">
                      <vue-select :options="Status" placeholder="Choose Status" />
                    </div>
                  </div>
                  <div class="col-lg col-sm-6 col-12">
                    <div class="form-group">
                      <vue-select
                        :options="Payment"
                        placeholder="Choose Payment Status"
                      />
                    </div>
                  </div>
                  <div class="col-lg-1 col-sm-6 col-12">
                    <div class="form-group">
                      <a class="btn btn-filters ms-auto"
                        ><img src="../../../assets/img/icons/search-whites.svg" alt="img"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Filter -->
             <div class="table-responsive">
              <a-table
                :columns="columns"
                :data-source="orders"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
                <template #bodyCell="{ column, record }">
                  <!-- <template v-if="column.key === 'id'">
                    <span
                      class="badges bg-lightgreen"
                      v-if="record.payment_status === 'paid'"
                      >{{ record.payment_status }}</span
                    >
                    <span
                      class="badges bg-lightred"
                      v-else-if="record.payment_status === 'unpaid'"
                      >{{ record.payment_status }}</span
                    >
                    <span
                      class="badges bg-lightyellow"
                      v-else="record.payment_status === 'partial'"
                      >{{ record.payment_status }}</span
                    ></template
                  > -->
                  <template v-if="column.key === 'total_amount'">
                    <span>{{ $globalFunction.formatCurrency(record.total_amount) }}</span>
                  </template>
                  
                  <template v-if="column.key === 'status'">
                    <span
                      class="badges bg-lightgreen"
                      v-if="record.status === 3"
                      >Livrée</span
                    >
                    <span
                      class="badges bg-lightred"
                      v-if="record.status === 0"
                      >Annulée</span
                    >
                    <span
                        class="badges bg-lightblue"
                        v-if="record.status === 4"
                    >Expédiée</span
                    >
                    <span
                      class="badges bg-lightpurple"
                      v-if="record.status === 2"
                      >En attente de livraison</span
                    >
                    <span
                      class="badges bg-lightyellow"
                      v-if="record.status === 1"
                      >En attente de validation</span
                    >
                    </template
                  >
<!--                  <template v-if="column.key === 'process_status'">
                    <span
                        class="badges bg-lightgreen"
                        v-if="record.process_status === 2"
                    >Traitement terminer</span
                    >
                    <span
                        class="badges bg-lightpurple"
                        v-if="record.process_status === 1"
                    >Traitement en cours</span
                    >
                    <span
                        class="badges bg-lightred"
                        v-if="record.process_status === 3"
                    >Traitement annuler</span
                    >
                    <span
                        class="badges bg-lightyellow"
                        v-if="record.process_status === 0"
                    >Traitement en attente</span
                    >
                  </template
                  >-->
                  <template v-else-if="column.key === 'action'">
                    <div class="text-center">
                      <a
                        class="action-set"
                        href="javascript:void(0);"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <router-link :to="{name:'orders-details', params:{id:record.id}}" class="dropdown-item"
                            ><img
                              src="../../../assets/img/icons/eye1.svg"
                              class="me-2"
                              alt="img"
                            />Aperçu</router-link
                          >
                        </li>
                        <li v-if="record.type_user === 'web'">
                          <router-link v-if="hasPermission('modifier-commande')" :to="{ name:'editorder', params : {id: record.id}}" class="dropdown-item"
                            ><img
                              src="../../../assets/img/icons/edit.svg"
                              class="me-2"
                              alt="img"
                            />Modifier</router-link
                          >
                         
                        </li>
                        <!-- <li>
                          <a
                            href="javascript:void(0);"
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#showpayment"
                            ><img
                              src="../../../assets/img/icons/dollar-square.svg"
                              class="me-2"
                              alt="img"
                            />Paiements</a
                          >
                        </li> -->

                        <li v-if="record.status === 2">
                          <router-link v-if="hasPermission('ajouter-livraison')" :to="{ name:'adddelivery', params : {id: record.id}}" class="dropdown-item"
                            ><img
                            src="../../../assets/img/icons/plus-circle.svg"
                              class="me-2"
                              alt="img"
                            />Nouvelle livraison</router-link
                          >
                        </li>
                       
                         <li>
                          <router-link :to="{name:'orders-invoice', params : {id: record.id}}" class="dropdown-item"
                            ><img
                              src="../../../assets/img/icons/download.svg"
                              class="me-2"
                              alt="img"
                            />Download pdf</router-link
                          >
                        </li>
                        <!-- <li>
                          <a href="javascript:void(0);" @click="deleteSale(record.id)" class="dropdown-item confirm-text"
                            ><img
                              src="../../../assets/img/icons/delete1.svg"
                              class="me-2"
                              alt="img"
                            />Supprimer</a
                          >
                        </li> -->
                      </ul>
                    </div>
                  </template>
                  <!-- <template v-else-if="column.key === 'action'">
                    <router-link class="me-3" :to="{ name:'editpurchase', params : {id: record.id}}">
                      <img src="../../../assets/img/icons/edit.svg" alt="img" />
                    </router-link>
                    <a
                      class="me-3 confirm-text"
                      href="javascript:void(0);"
                      @click="showAlert"
                    >
                      <img src="../../../assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </template> -->
                </template>
              </a-table>
             </div>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>
</template>
<script setup>
import useOrders from "@/composables/orders";
import usePermissions from "@/composables/usePermissions"
import { onMounted } from "vue";

const {  orders, getOrders } = useOrders()
const { loadUserPermissions, hasPermission } = usePermissions()

onMounted(() => {
  getOrders()
  loadUserPermissions()
})

</script>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },
  {
    title: "BOUTIQUE",
    dataIndex: "warehouse_name",
    sorter: {
      compare: (a, b) => {
        a = a.warehouse_name.toLowerCase();
        b = b.warehouse_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "RÉFÉRENCE",
    dataIndex: "reference",
    sorter: (a, b) => a.reference.localeCompare(b.reference),
  },
  {
    title: "DATE COMMANDE",
    dataIndex: "order_date",
    sorter: {
      compare: (a, b) => {
        a = a.purchase_date.toLowerCase();
        b = b.purchase_date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Total sans livraison",
  //   dataIndex: "subtotal_amount",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.subtotal_amount.toLowerCase();
  //       b = b.subtotal_amount.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Montant livraison",
  //   dataIndex: "shipping_amount",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.shipping_amount.toLowerCase();
  //       b = b.shipping_amount.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "MONTANT TTC",
    dataIndex: "total_amount",
    key: "total_amount",
    sorter: {
      compare: (a, b) => {
        a = a.total_amount.toLowerCase();
        b = b.total_amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "ETAT COMANDE",
    dataIndex: "status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

/*  {
    title: "ÉTAT DU PROCESSUS",
    dataIndex: "process_status",
    key: "process_status",
    sorter: {
      compare: (a, b) => {
        a = a.process_status.toLowerCase();
        b = b.process_status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },*/

  // {
  //   title: "Payé",
  //   dataIndex: "paid_amount",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.paid_amount.toLowerCase();
  //       b = b.paid_amount.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Dû",
  //   dataIndex: "due_amount",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.due_amount.toLowerCase();
  //       b = b.due_amount.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Statut de paiement",
  //   dataIndex: "payment_status",
  //   key: "id",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.payment_status.toLowerCase();
  //       b = b.payment_status.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "ACTION",
    key: "action",
    sorter: true,
  },
];

export default {
  data() {
    return {
      filter: false,
      title: "Commandes clients",
      title1: "Liste commandes",
      items: [
        {
          text: "Nouvelle commande client",
          addname: "/add-orders",
        },
      ],
      columns,
      startdate: currentDate,
      Choose: ["Choose Supplier", "Supplier"],
      Status: ["Choose Status", "Inprogress"],
      Payment: ["Choose Payment Status", "Payment Status"],
    };
  },
  name: "orderslist",
};
</script>
