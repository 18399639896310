import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useUnits() {
    const units = ref([])
    const unit = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);
    // const validationErrors = ref({});

    const getUnits = async () => {
        let response = await axios.get('/units')
        units.value = response.data.data;
    }

    const selectUnits = async () => {
        let response = await axios.get('/unit-select')
        units.value = response.data.data;
    }

    const getUnit = async (id) => {
        let response = await axios.get('/units/' + id)
        unit.value = response.data.data;
    }

    const storeUnit = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            await axios.post('/units/', data)
            await router.push({name: 'unitlist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const updateUnit = async (id) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.put('/units/' +id, unit.value)
            await router.push({name: 'unitlist'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false;
            }
        }
    }

    const destroyUnit = async (id) => {
        await axios.delete('/units/' + id)
    }

    return {
        units,
        unit,
        errors,
        getUnits,
        getUnit,
        storeUnit,
        updateUnit,
        destroyUnit,
        loading,
        selectUnits,
    }
}