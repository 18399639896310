import PermissionService  from '@/services/permissionService';
import { ref, readonly } from 'vue';

export default function usePermissions() {
  const userPermissions = ref([]);

  // Fonction pour charger les autorisations de l'utilisateur
  const loadUserPermissions = async () => {
    userPermissions.value = await PermissionService.fetchUserPermissions()
  };

  // Vérifier si l'utilisateur a une autorisation spécifique
  const hasPermission = (permission) => {
    return userPermissions.value.includes(permission);
  };

  return {
    userPermissions: readonly(userPermissions),
    loadUserPermissions,
    hasPermission,
  };
}