<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <!-- /add -->
        <form   @submit.prevent="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row"></div>
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Désignation</label>
                    <b-form-input type="text" name="designation" v-model="form.designation" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Catégorie</label>
                    <vue-select :options="categories" name="category_id" v-model="form.category_id" @select="changeCategory($event)" placeholder="Choisir une catégorie" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Sous catégorie</label>
                    <vue-select :options="subcategories" name="subcategory_id" v-model="form.subcategory_id" placeholder="Choisir une sous catégorie" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Fournisseur</label>
                    <vue-select :options="suppliers" name="supplier_id" v-model="form.supplier_id"  placeholder="Choisir un fournisseur" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Unité</label>
                    <vue-select :options="units" name="unit_id" v-model="form.unit_id" placeholder="Choisir une unité" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>SKU</label>
                    <b-form-input type="text" name="sku" v-model="form.sku" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Stock d'alerte</label>
                    <b-form-input type="number" name="stock_alert" v-model="form.stock_alert"/>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Quantité initiale</label>
                    <b-form-input type="number" name="quantity" v-model="form.quantity"  />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <b-form-textarea
                      name="note"
                      v-model="form.note"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tax</label>
                    <b-form-input v-model="form.order_tax" name="order_tax" type="number" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Type de taxe</label>
                    <vue-select :options="TaxType" v-model="form.tax_type" name="tax_type"/>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Prix achat</label>
                    <b-form-input type="number" name="cost" v-model="form.cost"/>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Prix de vente</label>
                    <b-form-input type="number" name="price" v-model="form.price"/>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label> Cliquez ici pour télécharger une image</label>
                    <div class="image-upload">
                      <input type="file" style="height: 200px;" accept="image/*" @change="handleImageSelected"/>
                      <div class="image-uploads" >
                       

                        <img style="height: 150px; width: 150px"  v-show="imageUrl" :src="imageUrl" alt="img" />
                      </div>
                    </div>
                  </div>
                  <input type="hidden" name="image_remove" v-model="form.image_remove">
                </div>
                <div class="col-lg-12">
                    <button class="btn btn-primary btn-md" :disabled="loading">
                      <div v-if="loading" class="btn-content">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="ms-2">Veuillez patienter...</span>
                      </div>
  
                      <div v-else class="btn-content">Enregistrer</div>
                    </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<script setup>
import useImageUpload from "@/composables/imageUpload";
import useCategories from '@/composables/categories';
import useSubCategories from '@/composables/subcategories';
import useProducts from '@/composables/products';
import useUnits from '@/composables/units';
import useSuppliers from '@/composables/suppliers';
import { onMounted, ref, reactive } from "vue";

const { imageFile, imageUrl, handleImageSelected } = useImageUpload();

const { categories, selectCategories } = useCategories()

const { subcategories, selectSubCategoryByCategory } = useSubCategories()

const { units, selectUnits } = useUnits()

const { suppliers, selectSuppliers } = useSuppliers()

const { errors, loading, storeProduct } = useProducts()


const form = reactive({
    'designation': '',
    'category_id': '',
    'subcategory_id': '',
    'unit_id': '',
    'sku': '',
    'stock_alert': '0',
    'quantity': '0',
    'note': '',
    'order_tax': '0',
    'tax_type': '1',
    'price': '0',
    'image_remove': '',
    'cost':'0',
    'supplier_id':'',
})

onMounted(selectCategories)

onMounted(selectUnits)

onMounted(selectSuppliers)

const  changeCategory = async (val) =>{
    await selectSubCategoryByCategory(val.id)
}

const handleSubmit = async () => {
  const data = new FormData()
  data.append("designation", form.designation)
  data.append("category_id", form.category_id)
  data.append("subcategory_id", form.subcategory_id)
  data.append("unit_id", form.unit_id)
  data.append('supplier_id', form.supplier_id)
  data.append("sku", form.sku)
  data.append("stock_alert", form.stock_alert)
  data.append("quantity", form.quantity)
  data.append("note", form.note)
  data.append("order_tax", form.order_tax)
  data.append("tax_type", form.tax_type)
  data.append("price", form.price)
  data.append("cost", form.cost)
  data.append("image_remove", form.image_remove)
  data.append("image", imageFile.value)
 
  
  await storeProduct(data)

}



</script>
<script>


export default {
  data() {
    return {
      title: "Ajouter un produit",
      title1: "Créer un nouveau produit",
      SubCategory: [{id: '', text: ''}],
      TaxSelected: '1',
      StatusSelect: '2',
      TaxType: [{id: "1", text: "Exclusif"}, {id: "2", text: "Inclusif"}],
      Status: [{id: "1", text: "Fermé"}, {id: "2", text: "Ouvrir"}],
    };
  },
  name: "addproduct",
};
</script>
