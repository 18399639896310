<template>
  <!-- Mobile Menu -->
  <div class="dropdown mobile-user-menu">
    <a
      href="javascript:void(0);"
      class="nav-link dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      ><i class="fa fa-ellipsis-v"></i
    ></a>
    <div class="dropdown-menu dropdown-menu-right">
      <!-- <router-link class="dropdown-item" to="profile">My Profile</router-link> -->
      <router-link class="dropdown-item" :to="{ name: 'resetpassword'}">Mot de passe</router-link>
      <a class="dropdown-item logout pb-0" @click="logout()">
        <img src="../assets/img/icons/log-out.svg" class="me-2" alt="img" />Se déconnecter
      </a>
    </div>
  </div>
  <!-- /Mobile Menu -->
</template>
<script setup>
import useAuth from '@/composables/auth'
const { logout } = useAuth()
</script>