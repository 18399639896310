import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';

import Activities from '../views/pages/activite/activities'
// import Addbrand from '../views/pages/product/addbrand'
import Addcategory from '../views/pages/product/addcategory'
import Addproduct from '../views/pages/product/addproduct'
// import Brandlist from '../views/pages/product/brandlist'
import Categorylist from '../views/pages/product/categorylist'
// import Editbrand from '../views/pages/product/editbrand'
import Editcategory from '../views/pages/product/editcategory'
import Editproduct from '../views/pages/product/editproduct'
import Editsubcategory from '../views/pages/product/editsubcategory'
// import Importproduct from '../views/pages/product/importproduct'
import ProductDetails from '../views/pages/product/product-details'
import Productlist from '../views/pages/product/productlist'
import Subaddcategory from '../views/pages/product/subaddcategory'
import Subcategorylist from '../views/pages/product/subcategorylist'
import Addsale from '../views/pages/sales/add-sales'
// import createsalesreturns from '../views/pages/sales/createsalesreturns'
import Editsales from '../views/pages/sales/edit-sales'
// import Editsalesreturns from '../views/pages/sales/editsalesreturns'
import Salesdetails from '../views/pages/sales/sales-details'
import Saleslist from '../views/pages/sales/saleslist'
import Addpurchase from '../views/pages/purchase/addpurchase'
import Editpurchase from '../views/pages/purchase/editpurchase'
import AddReceivePurchase from '../views/pages/purchase/add-receive-purchase'
// import Importpurchase from '../views/pages/purchase/importpurchase'
import Purchaselist from '../views/pages/purchase/purchaselist'
import Purchasesdetails from '../views/pages/purchase/purchases-details'
import Index from '../views/pages/dashboard/index'
// import IndexOne from '../views/pages/dashboard/index-one'
// import IndexTwo from '../views/pages/dashboard/index-two'
// import IndexThree from '../views/pages/dashboard/index-three'
// import IndexFour from '../views/pages/dashboard/index-four'
// import createexpense from '../views/pages/expense/createexpense'
// import Editexpense from '../views/pages/expense/editexpense'
// import Expensecategory from '../views/pages/expense/expensecategory'
// import Expenselist from '../views/pages/expense/expenselist'
import Addquotation from '../views/pages/quotation/addquotation'
import Editquotation from '../views/pages/quotation/editquotation'
import Quotationlist from '../views/pages/quotation/quotationlist'
import Quotationsdetails from '../views/pages/quotation/quotations-details'
// import Addtransfer from '../views/pages/transfer/addtransfer'
// import Edittransfer from '../views/pages/transfer/edittransfer'
// import Importtransfer from '../views/pages/transfer/importtransfer'
// import transferlist from '../views/pages/transfer/transferlist'
// import createpurchasereturn from '../views/pages/return/createpurchasereturn'
// import createsalesreturn from '../views/pages/return/createsalesreturn'
// import Editpurchasereturn from '../views/pages/return/editpurchasereturn'
// import Editsalesreturn from '../views/pages/return/editsalesreturn'
// import Purchasereturnlist from '../views/pages/return/purchasereturnlist'
// import Salesreturnlist from '../views/pages/return/salesreturnlist'
// import Salesreturnlists from '../views/pages/return/salesreturnlists'
// import Addcustomer from '../views/pages/people/addcustomer'
import Addsupplier from '../views/pages/people/addsupplier'
// import Adduser from '../views/pages/people/adduser'
import Editcustomer from '../views/pages/people/editcustomer'
import Editsupplier from '../views/pages/people/editsupplier'
// import Edituser from '../views/pages/people/edituser'
import Supplierlist from '../views/pages/people/supplierlist'
// import userlist from '../views/pages/people/userlist'
import customerlist from '../views/pages/people/customer/customerlist'
// import Editcountry from '../views/pages/places/editcountry'
// import Editstate from '../views/pages/places/editstate'
// import Newcountry from '../views/pages/places/newcountry'
// import Newstate from '../views/pages/places/newstate'
// import Statelist from '../views/pages/places/statelist'
// import Storelist from '../views/pages/settings/store/storelist'
// import Addstore from '../views/pages/settings/store/addstore'
// import Editstore from '../views/pages/settings/store/editstore'
// import Countrieslist from '../views/pages/places/countrylist/countrieslist'
// import Blankpage from '../views/pages/blankpage'
import Profile from '../views/pages/profile'
// import Component from '../views/pages/components/components'
import Error404 from '../views/pages/error/error-404'
import Error500 from '../views/pages/error/error-500'
import Error403 from '../views/pages/error/error-403'
// import clipboard from '../views/pages/elements/clipboard'
// import counter from '../views/pages/elements/counter'
// import Dragdrop from '../views/pages/elements/drag-drop'
// import Lightbox from '../views/pages/elements/lightbox'
// import notification from '../views/pages/elements/notification'
// import Barcode from '../views/pages/product/barcodeproduct/barcode'
// import Popover from '../views/pages/elements/popover'
// import Rangeslider from '../views/pages/elements/rangeslider'
// import Rating from '../views/pages/elements/rating'
// import Ribbon from '../views/pages/elements/ribbon'
// import Scrollbar from '../views/pages/elements/scrollbar'
// import Spinner from '../views/pages/elements/spinner'
// import Stickynote from '../views/pages/elements/stickynote'
// import Sweetalerts from '../views/pages/elements/sweetalerts'
// import Texteditor from '../views/pages/elements/text-editor'
// import timeline from '../views/pages/elements/timeline'
// import toastr from '../views/pages/elements/toastr'
// import tooltip from '../views/pages/elements/tooltip'
// import Chartapex from '../views/pages/charts/apex/chart-apex'  
// import Chartc3 from '../views/pages/charts/c3/chart-c3'
// import chartflot from '../views/pages/charts/flot/chart-flot'
// import chartmorris from '../views/pages/charts/morris/chart-morris'
// import Iconfeather from '../views/pages/icons/icon-feather'
// import Iconflag from '../views/pages/icons/icon-flag'
// import Iconfontawesome from '../views/pages/icons/icon-fontawesome'
// import Iconionic from '../views/pages/icons/icon-ionic'
// import Iconpe7 from '../views/pages/icons/icon-pe7'
// import Iconsimpleline from '../views/pages/icons/icon-simpleline'
// import Iconthemify from '../views/pages/icons/icon-themify'
// import Icontypicon from '../views/pages/icons/icon-typicon'
// import Iconweather from '../views/pages/icons/icon-weather'
// import iconmaterial from '../views/pages/icons/icon-material'
// import Formbasicinputs from '../views/pages/forms/form-basic-inputs'
// import Formfileupload from '../views/pages/forms/form-fileupload'
// import Formhorizontal from '../views/pages/forms/horizontal/form-horizontal'
// import Forminputgroups from '../views/pages/forms/form-input-groups'
// import Formmask from '../views/pages/forms/form-mask'
// import Formselect2 from '../views/pages/forms/form-select2'
// import Formvalidation from '../views/pages/forms/form-validation'
// import Formvertical from '../views/pages/forms/form-vertical'
// import FormWizard from '../views/pages/forms/form-wizard'
// import chartJS from '../views/pages/charts/js/chart-js' 
// import Datatable from '../views/pages/table/data-tables'
// import Tablesbasic from '../views/pages/table/tables-basic'
// import calendar from '../views/pages/application/calendar/calendar'
// import chat from '../views/pages/application/chat'
// import Email from '../views/pages/application/email'
// import customerreport from '../views/pages/report/customerreport'
// import Inventoryreport from '../views/pages/report/inventoryreport'
// import Invoicereport from '../views/pages/report/invoicereport'
// import Purchaseorderreport from '../views/pages/report/purchaseorderreport'
import Purchasereport from '../views/pages/report/purchasereport'
import Salesreport from '../views/pages/report/salesreport'
// import Supplierreport from '../views/pages/report/supplierreport/supplierreport'
import Newuser from '../views/pages/users/newuser'
import Newuseredit from '../views/pages/users/newuseredit'
import userlists from '../views/pages/users/userlists'
import currencylist from '../views/pages/settings/currencysetting/currencylist'
import createpermission from '../views/pages/settings/createpermission'
import Editpermission from '../views/pages/settings/editpermission'
import Emailsettings from '../views/pages/settings/emailsettings'
import Generalsettings from '../views/pages/settings/generalsettings'
import Grouppermissions from '../views/pages/settings/grouppermissions'
import Paymentsettings from '../views/pages/settings/paymentsettings'
// import Taxrates from '../views/pages/settings/taxrates'
import Signin from '../views/pages/auth/signin'
import Signup from '../views/pages/auth/signup'
// import Forgetpassword from '../views/pages/auth/forgetpassword'
import Resetpassword from '../views/pages/auth/resetpassword'
// import Pos from '../views/pages/pos/pos'

//add by me
import Addunit from '../views/pages/product/addunit'
import Editunit from '../views/pages/product/editunit'
import Unitlist from '../views/pages/product/unitlist'
import Addcurrency from '../views/pages/settings/currencysetting/addcurrency'
import Editcurrency from '../views/pages/settings/currencysetting/editcurrency'
import Editpaymenttype from '../views/pages/settings/editpaymenttype'
import Addpaymenttype from '../views/pages/settings/addpaymenttype'
import Activitylist from '../views/pages/settings/activity/activitylist'
import Addactivity from '../views/pages/settings/activity/addactivity'
import Editactivity from '../views/pages/settings/activity/editactivity'
import Adddelevery from '../views/pages/deliveries/adddelivery'
import Deliverylist from '../views/pages/deliveries/deliverieslist'
import Deliveriesdetails from '../views/pages/deliveries/deliveries-details'
import ConsultDelivery from '../views/pages/deliveries/consultdelivery.vue'
import OrderList from '../views/pages/orders/order-list'
import OrderDetail from '../views/pages/orders/orders-details'
import Addorder from '../views/pages/orders/add-order'
import Editorder from '../views/pages/orders/edit-order'
import Orderinvoice from '../views/pages/orders/orders-invoice'
import ZoneList from '../views/pages/settings/zone/zone-list'
import EditZone from '../views/pages/settings/zone/edit-zone'
import AddZone from '../views/pages/settings/zone/add-zone'
import Rolelist from '../views/pages/settings/rolelist'
import Addrole from '../views/pages/settings/createrole'
import Editrole from '../views/pages/settings/editrole'
import Addpayment from '../views/pages/payment/addpayment'


// import useAuthStore from '../store/auth';

import Guard from '../services/middleware'

const routes=  [
  {
    path: '/signin',
    name: 'signin',
    component: Signin
  },   
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
  },
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: {
      headerClass: 'header',
      sidebarClass: '',
      permissions: ["view-dashboard"]
    },
    beforeEnter: [Guard.auth],
  },
  // {
  //   path: '/activities',
  //   name: 'activities',
  //   component: Activities,
  //   meta: {
  //     headerClass: 'header',
  //     sidebarClass: ''
  //   },
  //   beforeEnter: Guard.auth,
  // },

  {
    path: '/add-sales',
    name: 'add-sales',
    component: Addsale,
    meta: {
      headerClass: 'header',
      sidebarClass: '',
    },
    beforeEnter: Guard.auth,
  },

 

  {
    path: '/edit-sales/:id/edit',
    name: 'edit-sales',
    component: Editsales,
    props: true,
    meta: {
      headerClass: 'header',
      sidebarClass: '',
    },
    beforeEnter: Guard.auth,
  },
  {
    path: '/sales-details/:id/view',
    name: 'sales-details',
    component: Salesdetails,
    props: true,
    meta: {
      headerClass: 'header',
      sidebarClass: '',
      
    },
    beforeEnter: Guard.auth,
  },
  {
    path: '/saleslist',
    name: 'saleslist',
    component: Saleslist,
    meta: {
      headerClass: 'header',
      sidebarClass: '',
    },
    beforeEnter: Guard.auth,
  },
  

  
  // {
  //   path: '/addcustomer',
  //   name: 'addcustomer',
  //   component: Addcustomer,
  //   meta: {
  //     headerClass: 'header',
  //     sidebarClass: ''
  //   },
  //   beforeEnter: Guard.auth,
  // },
  
  {
    path: '/editcustomer/:id/edit',
    name: 'editcustomer',
    component: Editcustomer,
    meta: {
      headerClass: 'header',
      sidebarClass: ''
    },
    props: true,
    beforeEnter: Guard.auth,
  },
 
  // {
  //   path: '/adduser',
  //   name: 'adduser',
  //   component: Adduser,
  //   meta: {
  //     headerClass: 'header',
  //     sidebarClass: '',
  //   },
  //   beforeEnter: Guard.auth,
  // },
  // {
  //   path: '/edituser',
  //   name: 'edituser',
  //   component: Edituser,
  //   meta: {
  //     headerClass: 'header',
  //     sidebarClass: ''
  //   },
  //   beforeEnter: Guard.auth,
  // },
  
  // {
  //   path: '/userlist',
  //   name: 'userlist',
  //   component: userlist,
  //   meta: {
  //     headerClass: 'header',
  //     sidebarClass: ''
  //   },
  //   beforeEnter: Guard.auth,
  // },
  // {
  //   path: '/customerlist',
  //   name: 'customerlist',
  //   component: customerlist,
  //   meta: {
  //     headerClass: 'header',
  //     sidebarClass: ''
  //   },
  //   beforeEnter: Guard.auth,
  // },
//   {
//     path: '/editcountry',
//     name: 'editcountry',
//     component: Editcountry,
//     meta: {
//       headerClass: 'header',
//       sidebarClass: ''
//  }
//   },
//   {
//     path: '/editstate',
//     name: 'editstate',
//     component: Editstate,
//     meta: {
//       headerClass: 'header',
//       sidebarClass: ''
//  }
//   },
//   {
//     path: '/newcountry',
//     name: 'newcountry',
//     component: Newcountry,
//     meta: {
//       headerClass: 'header',
//       sidebarClass: ''
//  }
//   },
//   {
//     path: '/newstate',
//     name: 'newstate',
//     component: Newstate,
//     meta: {
//       headerClass: 'header',
//       sidebarClass: ''
//  }
//   },
//   {
//     path: '/statelist',
//     name: 'statelist',
//     component: Statelist,
//     meta: {
//       headerClass: 'header',
//       sidebarClass: ''
//  }
//   },

// {
//   path: '/profile',
//   name: 'profile',
//   component: Profile,
//   meta: {
//     headerClass: 'header',
//     sidebarClass: ''
//   }
// },
{
  path: '/:pathMatch(.*)*',
  // name: 'error-404',
  component: Error404
},
{
  path: '/error-500',
  name: 'error-500',
  component: Error500
},
{
  path: '/unauthorized',
  name: 'unauthorized',
  component: Error403
},
// {
//   path: '/email',
//   name: 'email',
//   component: Email,
//   meta: {
//     headerClass: 'header',
//     sidebarClass: ''
// }
// },
// {
//   path: '/customerreport',
//   name: 'customerreport',
//   component: customerreport,
//   meta: {
//     headerClass: 'header',
//     sidebarClass: ''
// }
// },
// {
//   path: '/inventoryreport',
//   name: 'inventoryreport',
//   component: Inventoryreport,
//   meta: {
//     headerClass: 'header',
//     sidebarClass: ''
// }
// },
// {
//   path: '/invoicereport',
//   name: 'invoicereport',
//   component: Invoicereport,
//   meta: {
//     headerClass: 'header',
//     sidebarClass: ''
// }
// },
// {
//   path: '/purchaseorderreport',
//   name: 'purchaseorderreport',
//   component: Purchaseorderreport,
//   meta: {
//     headerClass: 'header',
//     sidebarClass: ''
// }
// },
{
  path: '/purchasereport',
  name: 'purchasereport',
  component: Purchasereport,
  meta: {
    headerClass: 'header',
    sidebarClass: ''
  },
  beforeEnter: Guard.auth,
},
{
  path: '/salesreport',
  name: 'salesreport',
  component: Salesreport,
  meta: {
    headerClass: 'header',
    sidebarClass: ''
  },
  beforeEnter: Guard.auth,
},
// {
//   path: '/supplierreport',
//   name: 'supplierreport',
//   component: Supplierreport,
//   meta: {
//     headerClass: 'header',
//     sidebarClass: ''
// }
// },




// {
//   path: '/emailsettings',
//   name: 'emailsettings',
//   component: Emailsettings,
//   meta: {
//     headerClass: 'header',
//     sidebarClass: ''
// }
// },
// {
//   path: '/generalsettings',
//   name: 'generalsettings',
//   component: Generalsettings,
//   meta: {
//     headerClass: 'header',
//     sidebarClass: ''
//   }
// },

//section devise
{
  path: '/currencylist',
  name: 'currencylist',
  component: currencylist,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-devise"],
  },
  beforeEnter: Guard.auth,
},

{
  path: '/addcurrency',
  name: 'addcurrency',
  component: Addcurrency,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-devise"],
  },
  beforeEnter: Guard.auth,
},
{
  path: '/editcurrency/:id/edit',
  name: 'editcurrency',
  component: Editcurrency,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-devise"],
  },
  beforeEnter: Guard.auth,
},
//fin section devise

//section utilisateur
{
  path: '/newuser',
  name: 'newuser',
  component: Newuser,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-utilisateur"],
  },
  beforeEnter: Guard.auth,
},
{
  path: '/newuseredit/:id/edit',
  name: 'newuseredit',
  component: Newuseredit,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-utilisateur"],
  },
  beforeEnter: Guard.auth,
},
{
  path: '/userlists',
  name: 'userlists',
  component: userlists,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-utilisateur"],
  },
  beforeEnter: Guard.auth,
},
//fin section utilisateur

//section fournisseur
{
  path: '/supplierlist',
  name: 'supplierlist',
  component: Supplierlist,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-fournisseur"],
  },
  beforeEnter: Guard.auth,
},
{
  path: '/editsupplier/:id/edit',
  name: 'editsupplier',
  component: Editsupplier,
  props: true,
  meta: {
        headerClass: 'header',
        sidebarClass: '',
        permissions: ["modifier-fournisseur"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/addsupplier',
  name: 'addsupplier',
  component: Addsupplier,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-fournisseur"]
  },
  beforeEnter: Guard.auth,
},
//fin section fournisseur

//section livraison 
{
  path: '/orders/:id/adddelivery',
  name: 'adddelivery',
  component: Adddelevery,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-livraison"]
    
  },
  beforeEnter: Guard.auth,
},
{
  path: '/deliverylist',
  name: 'deliverylist',
  component: Deliverylist,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-livraison"]
    
  },
  beforeEnter: Guard.auth,
},

{
  path: '/deliveries-details/view/:uuid',
  name: 'deliveries-details',
  component: Deliveriesdetails,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    // permissions: ["liste-livraison"]
    
  },
  beforeEnter: Guard.auth,
},

{
  path: '/consultdelivery',
  name: 'consultdelivery',
  component: ConsultDelivery,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["consulter-livraison"]
    
  },
  beforeEnter: Guard.auth,
},
//fin section livraison


//section commande
{
  path: '/orderslist',
  name: 'orderslist',
  component: OrderList,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-commande"]
  },
  beforeEnter: Guard.auth,
},

{
  path: '/add-orders',
  name: 'add-orders',
  component: Addorder,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-commande"]
  },
  beforeEnter: Guard.auth,
},

{
  path: '/orders-details/:id/view',
  name: 'orders-details',
  component: OrderDetail,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-commande"]
  },
  beforeEnter: Guard.auth,
},

{
  path: '/editorder/:id/view',
  name: 'editorder',
  component: Editorder,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-commande"]
  },
  beforeEnter: Guard.auth,
},

{
  path: '/orders-invoice/:id/print',
  name: 'orders-invoice',
  component: Orderinvoice,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-commande"]
  },
  beforeEnter: Guard.auth,
},
//fin section commande

//section achat
{
  path: '/purchaselist',
  name: 'purchaselist',
  component: Purchaselist,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-achat"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/addpurchase',
  name: 'addpurchase',
  component: Addpurchase,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-achat"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/editpurchase/:id/edit',
  name: 'editpurchase',
  component: Editpurchase,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-achat"]
  },
  beforeEnter: Guard.auth,
},

{
  path: '/purchases-details/:id/view',
  name: 'purchases-details',
  component: Purchasesdetails,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-achat"]
    
  },
  beforeEnter: Guard.auth,
},

// {
//   path: '/add-receive-purchase/:id/add',
//   name: 'add-receive-purchase',
//   component: AddReceivePurchase,
//   props: true,
//   meta: {
//     headerClass: 'header',
//     sidebarClass: '',
//     permissions: ["liste-achat"]
    
//   },
//   beforeEnter: Guard.auth,
// },

//fin section achat

//section unité
{
  path: '/unitlist',
  name: 'unitlist',
  component: Unitlist,
    meta: {
      headerClass: 'header',
      sidebarClass: '',
      permissions: ["liste-unite"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/addunit',
  name: 'addunit',
  component: Addunit,
    meta: {
      headerClass: 'header',
      sidebarClass: '',
      permissions: ["ajouter-unite"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/editunit/:id/edit',
  name: 'editunit',
  props: true,
  component: Editunit,
    meta: {
      headerClass: 'header',
      sidebarClass: '',
      permissions: ["modifier-unite"]
  },
  beforeEnter: Guard.auth,
},
//fin section unité

//section sous categorie
{
  path: '/editsubcategory/:id/edit',
  name: 'editsubcategory',
  component: Editsubcategory,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-sous-categorie"]
  },
  props: true,
  beforeEnter: Guard.auth,
},
{
  path: '/subaddcategory',
  name: 'subaddcategory',
  component: Subaddcategory,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-sous-categorie"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/subcategorylist',
  name: 'subcategorylist',
  component: Subcategorylist,
  meta: {
        headerClass: 'header',
        sidebarClass: '',
        permissions: ["liste-sous-categorie"]
  },
  beforeEnter: Guard.auth,
},
//fin section sous categorie


//section categorie
{
  path: '/addcategory',
  name: 'addcategory',
  component: Addcategory,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-categorie"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/categorylist',
  name: 'categorylist',
  component: Categorylist,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-categorie"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/editcategory/:id/edit',
  name: 'editcategory',
  component: Editcategory,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-categorie"]
  },
  beforeEnter: Guard.auth,
},
//fin section categorie

//section produit
{
  path: '/addproduct',
  name: 'addproduct',
  component: Addproduct,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-produit"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/editproduct/:id/edit',
  name: 'editproduct',
  component: Editproduct,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-produit"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/product-details',
  name: 'product-details',
  component: ProductDetails,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-produit"]
  }
},
{
  path: '/productlist',
  name: 'productlist',
  component: Productlist,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-produit"]
  },
  beforeEnter: Guard.auth,
},
//fin section produit

//section permission

{
  path: '/createpermission',
  name: 'createpermission',
  component: createpermission,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-permission"]
  },
  beforeEnter: Guard.auth,
},

{
  path: '/editpermission/:id/edit',
  name: 'editpermission',
  component: Editpermission,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-permission"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/grouppermissions',
  name: 'grouppermissions',
  component: Grouppermissions,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-permission"]
  },
  beforeEnter: Guard.auth,
},
//fin section permission

// section role
{
  path: '/rolelist',
  name: 'rolelist',
  component: Rolelist,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-role"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/createrole',
  name: 'createrole',
  component: Addrole,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-role"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/editrole/:id/edit',
  name: 'editrole',
  component: Editrole,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-role"]
  },
  beforeEnter: Guard.auth,
},
// fin section role 

//section devis
/*{
  path: '/addquotation',
  name: 'addquotation',
  component: Addquotation,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-devis"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/editquotation/:id/edit',
  name: 'editquotation',
  props: true,
  component: Editquotation,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-devis"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/quotationlist',
  name: 'quotationlist',
  component: Quotationlist,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-devis"]
  },
  beforeEnter: Guard.auth,
},

{
  path: '/quotations-details/:id/view',
  name: 'quotations-details',
  component: Quotationsdetails,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-devis"]
  },
  beforeEnter: Guard.auth,
},*/
//fin section devis

//section secteur d'activité
{
  path: '/activitylist',
  name: 'activitylist',
  component: Activitylist,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-secteur-activite"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/addactivity',
  name: 'addactivity',
  component: Addactivity,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-secteur-activite"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/editactivity/:id/edit',
  name: 'editactivity',
  component: Editactivity,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-secteur-activite"]
  },
  beforeEnter: Guard.auth,
},
//fin section d'activité

//section zone livraison
{
  path: '/zone-list',
  name: 'zone-list',
  component: ZoneList,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-zone"]
  },
  beforeEnter: Guard.auth,
},

{
  path: '/zone/:id/edit',
  name: 'edit-zone',
  component: EditZone,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-zone"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/add-zone',
  name: 'add-zone',
  component: AddZone,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-zone"]
  },
  beforeEnter: Guard.auth,
},
//fin section zone de livraison

{
  path: '/paymentsettings',
  name: 'paymentsettings',
  component: Paymentsettings,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["liste-mode-paiement"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/editpaymentsettings/:id/edit',
  name: 'editpaymentsettings',
  component: Editpaymenttype,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["modifier-mode-paiement"]
  },
  beforeEnter: Guard.auth,
},
{
  path: '/addpaymentsettings',
  name: 'addpaymentsettings',
  component: Addpaymenttype,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
    permissions: ["ajouter-mode-paiement"]
  },
  beforeEnter: Guard.auth,
},

{
  path: '/addpayment/:uuid/view',
  name: 'addpayment',
  component: Addpayment,
  props: true,
  meta: {
    headerClass: 'header',
    sidebarClass: '',
  },
  beforeEnter: Guard.auth,
},



// {
//   path: '/taxrates',
//   name: 'taxrates',
//   component: Taxrates,
//   meta: {
//     headerClass: 'header',
//     sidebarClass: ''
// }
// },
// {
//   path: '/forgetpassword',
//   name: 'forgetpassword',
//   component: Forgetpassword
// },
{
  path: '/resetpassword',
  name: 'resetpassword',
  component: Resetpassword,
  meta: {
    headerClass: 'header',
    sidebarClass: ''
}
},
// {
//   path: '/pos',
//   name: 'pos',
//   component: Pos,
//   meta: {
//     headerClass: 'header',
// }
// },
  ];


  // router.beforeEach((to, from, next) => {
  //   const authStore = useAuthStore();
  //   // current page view title
   
  //   // verify auth token before each page change
  //   // authStore.verifyAuth();
  //   // before page access check if page requires authentication
  //   if (to.meta.middleware == "auth") {
  //     if (authStore.isAuthenticated) {
  //       next();
  //     } else {
  //       next({ name: "signin" });
  //     }
  //   } else {
  //     next();
  //   }
  
  // });
export const router = createRouter({
    // history: createWebHistory('/vuejs/template/'),
    history: createWebHistory(),
    linkActiveClass: 'active',
    routes,
}); 

