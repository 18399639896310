<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card">
          <div class="card-body">
            <div class="card-sales-split">
              <h2>Détail de la vente : {{ sale.reference }}</h2>
              <ul>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/edit.svg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/excel.svg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/printer.svg" alt="img"
                  /></a>
                </li>
              </ul>
            </div>
            <div
              class="invoice-box table-height"
              style="
                max-width: 1600px;
                width: 100%;
                overflow: auto;
                margin: 15px auto;
                padding: 0;
                font-size: 14px;
                line-height: 24px;
                color: #555;
              "
            >
              <table
                cellpadding="0"
                cellspacing="0"
                style="width: 100%; line-height: inherit; text-align: left"
              >
                <tbody>
                  <tr class="top">
                    <td colspan="6" style="padding: 5px; vertical-align: top">
                      <table style="width: 100%; line-height: inherit; text-align: left">
                        <tbody>
                          <tr>
                            <td
                              style="
                                padding: 5px;
                                vertical-align: top;
                                text-align: left;
                                padding-bottom: 20px;
                              "
                            >
                              <font style="vertical-align: inherit; margin-bottom: 25px"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #7367f0;
                                    font-weight: 600;
                                    line-height: 35px;
                                  "
                                  >Client</font
                                ></font
                              ><br />

                              <template v-if="sale.customer">
                                <font style="vertical-align: inherit"
                                 ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                  {{ sale.customer.full_name }}</font
                                ></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                  {{ sale.customer.email }}</font
                                ></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                  {{ sale.customer.phone }}</font
                                ></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                  {{ sale.customer.address }}</font
                                ></font
                              >
                              </template>
                              
                              <br/>
                            </td>
                            <!-- <td
                              style="
                                padding: 5px;
                                vertical-align: top;
                                text-align: left;
                                padding-bottom: 20px;
                              "
                            >
                              <font style="vertical-align: inherit; margin-bottom: 25px"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #7367f0;
                                    font-weight: 600;
                                    line-height: 35px;
                                  "
                                  >Company Info</font
                                ></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                  DGT
                                </font></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                  admin@example.com</font
                                ></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                  >6315996770</font
                                ></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                  3618 Abia Martin Drive</font
                                ></font
                              ><br />
                            </td> -->
                            <td
                              style="
                                padding: 5px;
                                vertical-align: top;
                                text-align: left;
                                padding-bottom: 20px;
                              "
                            >
                              <font style="vertical-align: inherit; margin-bottom: 25px"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #7367f0;
                                    font-weight: 600;
                                    line-height: 35px;
                                  "
                                  >Facture</font
                                ></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                Référence
                                </font></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                Statut de paiement</font
                                ></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                Statut</font
                                ></font
                              ><br />
                            </td>
                            <td
                              style="
                                padding: 5px;
                                vertical-align: top;
                                text-align: right;
                                padding-bottom: 20px;
                              "
                            >
                              <font style="vertical-align: inherit; margin-bottom: 25px"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #7367f0;
                                    font-weight: 600;
                                    line-height: 35px;
                                  "
                                  >&nbsp;</font
                                ></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                  > {{ sale.reference }}
                                </font></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #2e7d32;
                                    font-weight: 400;
                                  "
                                >
                                  Paid</font
                                ></font
                              ><br />
                              <font style="vertical-align: inherit"
                                ><font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #2e7d32;
                                    font-weight: 400;
                                  "
                                >
                                  Completed</font
                                ></font
                              ><br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr class="heading" style="background: #f3f2f7">
                    <td
                      style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      "
                    >
                      #
                    </td>
                    <td
                      style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      "
                    >
                      Nom produit
                    </td>
                    <td
                      style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      "
                    >
                      Quantité
                    </td>
                    <td
                      style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      "
                    >
                      Prix
                    </td>
                    <td
                      style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      "
                    >
                      Sous total
                    </td>
                  </tr>

                  <tr class="details" v-for="(item,i) in sale.sale_items" :key="item.id" style="border-bottom: 1px solid #e9ecef">
                    <td
                      style="
                        padding: 10px;
                        vertical-align: top;
                        display: flex;
                        align-items: center;
                      "
                    >
                    {{ i+1 }}
                    </td>
                    <td style="padding: 10px; vertical-align: top">  {{ item.product.designation }}</td>
                    <td style="padding: 10px; vertical-align: top">{{ item.unit_price }}</td>
                    <td style="padding: 10px; vertical-align: top">{{ item.quantity }}</td>
                    <td style="padding: 10px; vertical-align: top">{{  item.quantity * item.unit_price  }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="row">
                <div class="col-lg-6">
                </div>
                <div class="col-lg-6">
                  <div class="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Total ht</h4>
                        <h5>{{ sale.subtotal_amount }}</h5>
                      </li>
                      <li>
                        <h4>Rémise</h4>
                        <h5>{{ sale.discount_amount }}</h5>
                      </li>
                      <li class="total">
                        <h4>Total TTC</h4>
                        <h5>{{ sale.total_amount }}</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import  useSales from '@/composables/sales'

const { sale, getSale  } = useSales()

const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

onMounted(() => getSale(props.id))

</script>
<script>
export default {
  data() {
    return {
      title: "Commandes clients",
      title1: "Voir détails commande",
    };
  },
  name: "sales-details",
};
</script>
