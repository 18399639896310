<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <form @submit.prevent="handlerSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="form-group">
                    <label>Libellé</label>
                    <input type="text" v-model="form.name" />
                    <span class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</span>
                  </div>
                </div>
                
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="productdetails product-respon" style="margin-bottom: 10px;">
                    <ul>
                      <li v-for="(itemPerm, item) in permissions" :key="index">
                        <div class="input-checkset">
                          <ul>
                            <li>
                              <label class="inputcheck"
                                >{{ itemPerm.name }}
                                <input type="checkbox" v-model="form.permission" :value="itemPerm.id"/>
                                <span class="checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                    <div v-if="loading" class="btn-content">
                      <b-spinner small type="grow"></b-spinner>
                      <span class="ms-2">Veuillez patienter...</span>
                    </div>
                    <div v-else class="btn-content">Enregistrer</div>
                  </button>
                </div>
              </div>
            
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, reactive } from 'vue'
import usePermissions from '@/composables/permissions';
import useRoles from '@/composables/roles';

const { permissions , getPermissions } = usePermissions()

const { errors, loading, storeRole } = useRoles()

const form = reactive({
  name:'',
  permission: []
})

onMounted(() => {
  getPermissions()
})

const handlerSubmit = async () => {
  await storeRole({...form})
}
</script>
<script>
export default {
  data() {
    return {
      title: "Rôles",
      title1: "Créer un rôle",
    };
  },
  name: "createrole",
};
</script>
