import { ref } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router';

export default function usePurchases() {
    const purchases = ref([])
    const purchase = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);

    const getPurchases = async () =>{
        let response = await axios.get('/purchases')
        purchases.value = response.data.data
    }

    const getPurchaseReport = async () =>{
        let response = await axios.get('/purchasereport')
        purchases.value = response.data.data
        console.log( purchases.value);
    }

    const getPurchase = async (id) =>{
        let response = await axios.get('/view-purchase/'+id)
        purchase.value = response.data.data

    }

    const storePurchase = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            let response = await axios.post('/purchases/', data)
            await router.push({name: 'purchaselist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }


    const updatePurchase = async (id, data) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.post('/update-purchase/' +id, data)
            await router.push({name: 'purchaselist'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false;
            }
        }
    }


    const destroyPurchase = async (id) => {
        await axios.delete('/purchases/' + id)
    }


    return {
        purchases,
        getPurchases,
        getPurchaseReport,
        getPurchase,
        purchase,
        storePurchase,
        errors,
        loading,
        updatePurchase,
        destroyPurchase
    }
}