import { ref } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useUsers() {

    const users = ref([])
    const user = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false)
    const loadingPassword = ref(false)


    const getUsers = async () => {
        let response = await axios.get('/users')
        users.value = response.data.data
    }

    const getUser = async (id) => {
        let response = await axios.get('/users/'+id)
        user.value = response.data.data;
    }

    const storeUser = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            let response = await axios.post('/users/', data)
            await router.push({name: 'userlists'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const updateUser = async (id, data) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.put('/users/profile-update/' +id, data)
            await router.push({name: 'userlists'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false;
            }
        }
    }

    const updatePassword = async (id, data) => {
        errors.value = ''
        loadingPassword.value = true;
          try {
              let response = await axios.put('/users/password-update/' +id, data)
              await router.push({name: 'userlists'})
              
          } catch (e) {
              if (e.response.status === 422) {
                  errors.value = e.response.data.errors
                  loadingPassword.value = false;
              }
          }
      }
      

    const destroyUser = async (id) => {
        await axios.delete('/users/' + id)
    }

    const updateStatusUser = async (id) => {
        let response = await axios.put('/update-status-user/' + id)
    }

    const getUtilisateursParRole = async (role) => {
        let response = await axios.get(`/utilisateurs/${role}`)
        users.value = response.data.data;
    }

    return {
        user,
        users,
        destroyUser,
        getUser,
        getUsers,
        loading,
        errors,
        storeUser,
        updateUser,
        loadingPassword,
        updatePassword,
        updateStatusUser,
        getUtilisateursParRole
    }
}