
import axios from 'axios';

export default {
  async  validateToken(token) {
    try {
      const response = await axios.post('/verify-token', { token });
      return response.data.valid === true;
    } catch (error) {
      console.error('Token validation failed:', error);
      return false;
    }
  }
}