<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card">
          <div class="card-body">
            <div class="card-sales-split">
              <!-- <h2>N° : {{ purchase.reference }}</h2> -->
              <ul>
                <!-- <li>
                  <a href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/edit.svg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/excel.svg" alt="img"
                  /></a>
                </li> -->
<!--                <li>
                  <a href="javascript:void(0);"
                  v-print="printObj"
                    ><img src="../../../assets/img/icons/printer.svg"  alt="img"
                  /></a>
                </li>-->
              </ul>
            </div>
            <div id="printMe">
              <div
                class="invoice-box table-height"
                style="
                  max-width: 1600px;
                  width: 100%;
                  overflow: auto;
                  margin: 15px auto;
                  padding: 0;
                  font-size: 14px;
                  line-height: 24px;
                  color: #555;
                "
              >
                <table
                  cellpadding="0"
                  cellspacing="0"
                  style="width: 100%; line-height: inherit; text-align: left"
                >
                  <tbody>
                    <tr class="top">
                      <td colspan="6" style="padding: 5px; vertical-align: top">
                        <table style="width: 100%; line-height: inherit; text-align: left">
                          <tbody>
                            <tr>
                              <td
                                style="
                                  padding: 5px;
                                  vertical-align: top;
                                  text-align: left;
                                  padding-bottom: 20px;
                                "
                              >
                                 <font style="vertical-align: inherit; margin-bottom: 25px"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #7367f0;
                                      font-weight: 600;
                                      line-height: 35px;
                                    "
                                    >Information du client</font
                                  ></font
                                ><br />

                                <template v-if="order.warehouse">
                                  <font style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                  >
                                    {{ order.warehouse.assigned_user.name }}</font
                                  ></font
                                >
                                <br />
                                </template>
                                
                                <br/>
                              </td>
                              <td
                                style="
                                  padding: 5px;
                                  vertical-align: top;
                                  text-align: left;
                                  padding-bottom: 20px;
                                "
                              >
                                <font style="vertical-align: inherit; margin-bottom: 25px"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #7367f0;
                                      font-weight: 600;
                                      line-height: 35px;
                                    "
                                    >Information  boutique</font
                                  ></font
                                ><br />
                                <template v-if="order.warehouse">
                                  <font style="vertical-align: inherit"
                                    ><font
                                      style="
                                        vertical-align: inherit;
                                        font-size: 14px;
                                        color: #000;
                                        font-weight: 400;
                                      "
                                    >
                                      {{ order.warehouse.warehouse_name }}
                                    </font></font
                                  ><br />
                                  <font style="vertical-align: inherit"
                                    ><font
                                      style="
                                        vertical-align: inherit;
                                        font-size: 14px;
                                        color: #000;
                                        font-weight: 400;
                                      "
                                    >
                                      {{  order.warehouse.warehouse_phone }}</font
                                    ></font
                                  ><br />
                                  <font style="vertical-align: inherit"
                                    ><font
                                      style="
                                        vertical-align: inherit;
                                        font-size: 14px;
                                        color: #000;
                                        font-weight: 400;
                                      "
                                    >
                                    {{  order.warehouse.warehouse_address }}</font
                                    ></font
                                  ><br />
                                </template>
                              </td>
                              <td
                                style="
                                  padding: 5px;
                                  vertical-align: top;
                                  text-align: left;
                                  padding-bottom: 20px;
                                "
                              >
                                <font style="vertical-align: inherit; margin-bottom: 25px"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #7367f0;
                                      font-weight: 600;
                                      line-height: 35px;
                                    "
                                    >Facture</font
                                  ></font
                                ><br />
                                <font style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                  >
                                  Référence
                                  </font></font
                                ><br />
                                <font style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                  >
                                  Statut</font
                                  ></font
                                ><br />
                                <font style="vertical-align: inherit"><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                >
                                  Livraison avec facture</font
                                ></font
                                ><br />
                                <font style="vertical-align: inherit"><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                >
                                  Modes de livraison</font
                                ></font
                                ><br />
                              </td>
                              <td
                                style="
                                  padding: 5px;
                                  vertical-align: top;
                                  text-align: right;
                                  padding-bottom: 20px;
                                "
                              >
                                <font style="vertical-align: inherit; margin-bottom: 25px"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #7367f0;
                                      font-weight: 600;
                                      line-height: 35px;
                                    "
                                    >&nbsp;</font
                                  ></font
                                ><br />
                                <font style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                    > {{ order.reference }}
                                  </font></font
                                ><br />
                                <font v-if="order.status == 1" style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #f1b40c;
                                      font-weight: 400;
                                    ">
                                  En attente de confirmation</font
                                  ></font>

                                  <font v-if="order.status == 0" style="vertical-align: inherit"
                                  ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #cc0404;
                                      font-weight: 400;
                                    ">
                                    Commande annulée</font>
                                    </font>
                                    <font v-if="order.status == 2" style="vertical-align: inherit"
                                    ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #0439cc;
                                      font-weight: 400;
                                    ">
                                      Confirmer en attente de livraison</font>
                                    </font>
                                    <font v-if="order.status == 3" style="vertical-align: inherit"
                                    ><font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #3beb06;
                                      font-weight: 400;
                                    ">
                                      Commande livrée</font>
                                    </font>
                                <br/>
                                <font style="vertical-align: inherit" v-if="order.delivery_with_invoice === 0">
                                  <font
                                    style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                  > Non
                                  </font>
                                </font>
                                <font style="vertical-align: inherit" v-if="order.delivery_with_invoice === 1">
                                  <font
                                      style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                  > Oui
                                  </font>
                                </font>
                                <br/>
                                <font style="vertical-align: inherit" v-if="order.be_delivered === 0">
                                  <font
                                      style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                  > Livraison en point relais
                                  </font>
                                </font>
                                <font style="vertical-align: inherit" v-if="order.be_delivered === 1">
                                  <font
                                      style="
                                      vertical-align: inherit;
                                      font-size: 14px;
                                      color: #000;
                                      font-weight: 400;
                                    "
                                  > Livraison à domicile
                                  </font>
                                </font>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr class="heading" style="background: #f3f2f7">
                      <td
                        style="
                          padding: 5px;
                          vertical-align: middle;
                          font-weight: 600;
                          color: #5e5873;
                          font-size: 14px;
                          padding: 10px;
                        "
                      >
                        #
                      </td>
                      <td
                        style="
                          padding: 5px;
                          vertical-align: middle;
                          font-weight: 600;
                          color: #5e5873;
                          font-size: 14px;
                          padding: 10px;
                        "
                      >
                        Nom produit
                      </td>
                      <td
                        style="
                          padding: 5px;
                          vertical-align: middle;
                          font-weight: 600;
                          color: #5e5873;
                          font-size: 14px;
                          padding: 10px;
                        "
                      >
                        Quantité
                      </td>
                      <td
                        style="
                          padding: 5px;
                          vertical-align: middle;
                          font-weight: 600;
                          color: #5e5873;
                          font-size: 14px;
                          padding: 10px;
                        "
                      >
                        Prix
                      </td>
                      <td
                      style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      "
                    >
                      Remise %
                    </td>
                    <td
                      style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      "
                    >
                      TVA %
                    </td>
                    <td
                      style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      "
                    >
                      Montant TTC
                    </td>
                    </tr>

                    <tr class="details" v-for="(item,i) in order.order_items" :key="item.id" style="border-bottom: 1px solid #e9ecef">
                      <td
                        style="
                          padding: 10px;
                          vertical-align: top;
                          display: flex;
                          align-items: center;
                        "
                      >
                      {{ i+1 }}
                      </td>
                      <td style="padding: 10px; vertical-align: top">  {{ item.product.designation }}</td>
                      <td style="padding: 10px; vertical-align: top">{{ item.quantity }}</td>
                      <td style="padding: 10px; vertical-align: top">{{ $globalFunction.formatCurrency(item.unit_price) }}</td>
                      <td style="padding: 10px; vertical-align: top">{{ item.product_discount }}</td>
                      <td style="padding: 10px; vertical-align: top">{{ item.product_tax }}</td>
                      <td style="padding: 10px; vertical-align: top">{{  $globalFunction.formatCurrency((item.quantity * item.unit_price) - ((item.quantity * item.unit_price) * item.product_discount / 100) + ((item.quantity * item.unit_price) * item.product_tax / 100))  }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="row">
                  <div class="col-lg-6">
                  </div>
                  <div class="col-lg-6">
                    <div class="total-order w-100 max-widthauto m-auto mb-4">
                      <ul>
                      <li>
                        <h4>TOTAL HT</h4>
                        <h5>{{ $globalFunction.formatCurrency(order.subtotal_amount) }}</h5>
                      </li>
                      <li>
                        <h4>TOTAL REMISE (-)</h4>
                        <h5>{{ $globalFunction.formatCurrency(order.discount_amount) }}</h5>
                      </li>
                      <li>
                        <h4>TOTAL TVA (+)</h4>
                        <h5>{{ $globalFunction.formatCurrency(order.tax_amount) }}</h5>
                      </li>
                      <li>
                        <h4>FRAIS DE LIVRAISON (+)</h4>
                        <h5>{{ $globalFunction.formatCurrency(order.shipping_amount) }}</h5>
                      </li>
                      <li class="total">
                        <h4>TOTAL TTC</h4>
                        <h5>{{ $globalFunction.formatCurrency(order.total_amount) }}</h5>
                      </li>
                    </ul>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
            <template v-if="order.status == 1">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Commentaire</label>
                    <textarea
                        class="form-control"
                        name="note"
                        v-model="form.comment"
                    ></textarea>
                    <span class="text-danger" v-if="errors?.comment">{{ errors.comment[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-lg-12">
                    <a href="javascript:void(0);" class="btn btn-submit me-2" @click="handlerOrderAcceptSubmit()">Accepté</a>
                    <a href="javascript:void(0);" class="btn btn-cancel" @click="handlerOrderCancelSubmit()">Refusé</a>
                  </div>
              </div>
            </template>
            <template v-if="order.status == 2">
              <div class="row">
                <div class="col-lg-12">
                  <a href="javascript:void(0);" class="btn btn-submit me-2" @click="handlerOrderShippedSubmit(order.id)">Clôturer</a>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted , ref, reactive} from 'vue'
import useOrders from '@/composables/orders'

const { order, getOrder, handlerCancelOrder, handlerAcceptOrder, errors, handlerOrderShipped  } = useOrders()
let form = ref({
  id: order.id,
  comment:''
})
const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

onMounted(() => getOrder(props.id))

const handlerOrderAcceptSubmit = async() =>{
  let formData = {
    comment: form.value.comment
  }
  await handlerAcceptOrder(props.id ,formData)
}


const handlerOrderCancelSubmit = async() =>{
  let formData = {
    comment: form.value.comment
  }
  await handlerCancelOrder(props.id ,formData)
}

const handlerOrderShippedSubmit = async (id) => {
  await handlerOrderShipped(id)
}

</script>
<script>
export default {
  data() {
    return {
      title: "Commandes clients",
      title1: "Aperçu commande",
      printLoading: true,
      printObj: {
        id: "printMe",
        popTitle: 'Aperçu commande',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        beforeOpenCallback (vue) {
          vue.printLoading = true
          console.log('asadzaman')
        },
        openCallback (vue) {
          vue.printLoading = false
          console.log('asadzaman')
        },
        closeCallback (vue) {
          console.log('asadzaman')
        }
      },
    };
  },
  name: "orders-details",
};
</script>

