<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Boutiques {{ form.warehouse_id }}</label>
                  <v-select
                    v-model="form.warehouse_id"
                    :reduce="(option) => option.id"
                    :options="warehouses"
                    placeholder="Rechercher une boutique"
                    :dropdown-should-open="dropdownShouldOpen"
                    @update:modelValue="setSelected"
                    :value="form.warehouse_id"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Date commande</label>
                  <VueDatePicker
                    v-model="form.order_date"
                    name="order_date"
                    :format="format"
                    :format-locale="fr"
                  ></VueDatePicker>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Date prévue de la livraison</label>
                  <VueDatePicker
                    v-model="form.expected_delivery_date"
                    name="expected_delivery_date"
                    :format="format"
                    :format-locale="fr"
                  ></VueDatePicker>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>N° facture</label>
                  <input
                    type="text"
                    v-model="form.number"
                    name="number"
                  />
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Modes de livraison</label>
                  <vue-select :options="Delivery_method" name="subcategory_id" v-model="form.be_delivered" @select="deliveryMethodChangeEvent($event)" placeholder="Choisir un mode de livraison" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Livraison avec facture ?</label>
                  <vue-select :options="Deliver_with_invoice" name="subcategory_id" v-model="form.delivery_with_invoice" placeholder="Choisir" />
                </div>
              </div>

              <div class="col-md-12" v-if="form.be_delivered == 1">
                <div class="form-group">
                  <label class="col-form-label col-md-6">Ajouter une adresse de livraison pour le client</label>
                    <textarea
                        class="form-control"
                        name="note"
                        v-model="form.delivery_address"
                        rows="2"
                        cols="2"
                        placeholder="Ex: ville, quartier, commune , numéro de téléphone"
                    ></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-sm-6 col-12">
                <div class="auto-complete-product-container">
                  <div class="form-group">
                    <label>Nom du produit</label>
                    <div class="input-groupicon">
                      <input
                        type="text"
                        placeholder="Scannez/recherchez le produit par code et sélectionnez..."
                        v-model="searchText"
                        @keyup="loadSuggestions()"
                      />
                      <div class="addonset">
                        <img
                          src="../../../assets/img/icons/scanners.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div
                      class="customer-search-suggestion"
                      v-if="suggestiondata.length"
                    >
                      <div class="customer-search-suggestion-inner">
                        <ul>
                          <li
                            v-for="(item, i) in suggestiondata"
                            @click="addCart(item)"
                            :key="item.id"
                          >
                            {{ item.designation }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="table-responsive">
                <table class="table">
                  <thead style="background-color: #9f9f9f;">
                      <th>Nom du produit</th>
                      <th>Qté</th>
                      <th>Prix ​​d'achat</th>
                      <th>Remise %</th>
                      <th>TVA %</th>
                      <th>Montant TTC</th>
                      <th class="text-end">Action</th>
                  </thead>
                  <tbody>
                    <tr v-for="(itemcart, i) in listCart" :key="itemcart.id">
                      <td>
                        <!-- <a class="product-img">
                          <img :src="itemcart.image" alt="product" />
                        </a> -->
                        <a href="javascript:void(0);">{{
                          itemcart.designation
                        }}</a>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.quantity"
                          name="number"
                          required
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="itemcart.unit_price"
                          name="number"
                          readonly
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.product_discount"
                          name="number"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.product_tax"
                          name="number"
                        />
                      </td>
                      <td class="text-end">
                        <span v-if="itemcart.quantity">
                          {{ (itemcart.quantity * itemcart.unit_price) - (itemcart.quantity * itemcart.unit_price * itemcart.product_discount / 100) +  (itemcart.quantity * itemcart.unit_price * itemcart.product_tax / 100)}}
                        </span>
                        <span v-else></span>
                      </td>
                      <td>
                        <a class="delete-set" @click="removeItem(i)"
                          ><img
                            src="../../../assets/img/icons/delete.svg"
                            alt="svg"
                        /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 float-md-right">
                <div class="total-order">
                  <ul>
                    <li>
                      <h4>TOTAL HT</h4>
                      <h5>{{ SubTotal() }}</h5>
                    </li>
                    <li>
                      <h4>TOTAL REMISE (-)</h4>
                        <h5>{{ TotalDiscount() }}</h5>
                    </li>
                    <li>
                      <h4>TOTAL TVA (+)</h4>
                      <h5>{{ TotalTax() }}</h5>
                    </li>

                    <li v-if="form.be_delivered == 1">
                      <h4>TOTAL LIVRAISON (+)</h4>
                      <h5>{{ form.shipping_amount }}</h5>
                    </li>
                    
                    <li class="total">
                      <h4>TOTAL TTC</h4>
                      <h5>{{ Total() }}</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <button
                  class="btn btn-primary btn-md"
                  type="submit"
                  :disabled="loading"
                  @click="handlerSubmit"
                >
                  <div v-if="loading" class="btn-content">
                    <b-spinner small type="grow"></b-spinner>
                    <span class="ms-2">Veuillez patienter...</span>
                  </div>
                  <div v-else class="btn-content">Enregistrer</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";
import useWarehouses from '@/composables/warehouses' ;
import { fr } from "date-fns/locale";
import { useRouter } from "vue-router";
const { warehouses, selectShop } = useWarehouses();
const loading = ref(false);
const router = useRouter();
let searchText = ref("");
let suggestiondata = ref([]);
let form = ref({
  shipping_amount:0
});
let item = ref([]);
let listCart = ref([]);
const errors = ref("");

const loadSuggestions = async () => {
    let response = await axios.get("/product-search?s=" + searchText.value);
    suggestiondata.value = response.data;
};


const dropdownShouldOpen = (VueSelect) => {
  if (form.value.warehouse_id !== '') {
    return VueSelect.open
  }
  return VueSelect.search.length !== 0 && VueSelect.open
}

const addCart = (item) => {
  searchText.value = "";
  suggestiondata.value = "";

  let product_check = listCart.value.find((product) => product.id === item.id);

  if (product_check) {
    product_check.quantity++;
  } else {
    const itemcart = {
      id: item.id,
      designation: item.designation,
      unit_price: item.cost,
      quantity: 1,
      image: item.image,
      product_tax: 0,
      product_discount: 0
    };
    listCart.value.push(itemcart);
  }
};

const removeItem = (i) => {
  listCart.value.splice(i, 1);
};

const SubTotal = () => {
  let total = 0;
  listCart.value.map((data) => {
    total = total + data.quantity * data.unit_price;
  });
  return total;
};

const TotalDiscount = () => {
  let total = 0;
  listCart.value.map((data) => {
    total = total + (data.quantity * data.unit_price * data.product_discount / 100);
  });
  return total;
};

const TotalTax = () => {
  let total = 0;
  listCart.value.map((data) => {
    total = total +  (data.quantity * data.unit_price * data.product_tax / 100);
  });
  return total;
};

const Total = () => {

  let shipping_amount = 0;
  if(form.value.be_delivered == 1 && SubTotal() > 0){
    shipping_amount = form.value.shipping_amount;
  }
  return SubTotal() - TotalDiscount() + TotalTax() + shipping_amount;
};

const indexForm = async () => {
  let response = await axios.get("/create-invoice");
  form.value = response.data;
};


onMounted(() => {
  indexForm()
  selectShop()
})


const date = ref(new Date());

const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

const handlerSubmit = async () => {
  if (listCart.value.length >= 1) {
    let subtotal = 0;
    subtotal = SubTotal();

    let total = 0;
    total = Total();

    let totaldiscount = 0;
    totaldiscount = TotalDiscount();


    let totaltax = 0;
    totaltax = TotalTax();

    const formData = new FormData();

    formData.append("order_items", JSON.stringify(listCart.value));

    formData.append("shipping_amount", form.value.shipping_amount);
    formData.append("warehouse_id", form.value.warehouse_id);
    formData.append("be_delivered", form.value.be_delivered);
    formData.append("delivery_with_invoice", form.value.delivery_with_invoice);
    formData.append("delivery_address", form.value.delivery_address);
    formData.append("order_date", form.value.order_date);
    formData.append(
      "expected_delivery_date",
      form.value.expected_delivery_date
    );
    formData.append("number", form.value.number);
    formData.append("subtotal", subtotal);
    formData.append("total", total);
    formData.append("totaldiscount", totaldiscount);
    formData.append("totaltax", totaltax);

    console.log(total)
    errors.value = "";
    loading.value = true;
    try {
      let response = await axios.post("/store-invoice/", formData);
      await router.push({ name: "orderslist" });
      console.log(response);
    } catch (e) {
      if (e.response.status === 422) {
        errors.value = e.response.data.errors;
        loading.value = false;
      }
    }
  }
};

const setSelected = async (value) => {
  let response = await axios.get("/warehouse-zone/"+value);
    form.value.shipping_amount = response.data.data.amount;
}

const deliveryMethodChangeEvent = async ({id, text}) =>{
  if(id == '0'){
    form.value.shipping_amount = 0;
  }
}
</script>
<script>
export default {
  data() {
    return {
      title: "Commande clients",
      title1: "Ajouter/mettre à jour une nouvelle commande client",
      Deliver_with_invoice: [
        { id: "0", text: "NON" },
        { id: "1", text: "OUI" },
      ],
      Delivery_method: [
        { id: "0", text: "Livraison en point relais" },
        { id: "1", text: "Livraison à domicile" },
      ],
    };
  },
  name: "add-order",
};
</script>