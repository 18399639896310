<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar></sidebar>
        <div class="page-wrapper">
            <div class="content">
               
                <indexpage></indexpage>
                
            </div>
        </div>
    </div>
</template>

