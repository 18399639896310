<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <div class="search-path">
                  <a
                    class="btn btn-filter"
                    id="filter_search"
                    v-on:click="filter = !filter"
                    :class="{ setclose: filter }"
                  >
                    <img src="../../../assets/img/icons/filter.svg" alt="img" />
                    <span
                      ><img src="../../../assets/img/icons/closes.svg" alt="img"
                    /></span>
                  </a>
                </div>
                <div class="search-input">
                  <a class="btn btn-searchset"
                    ><img src="../../../assets/img/icons/search-white.svg" alt="img"
                  /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search..."
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                      ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                      ><img src="../../../assets/img/icons/excel.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                      ><img src="../../../assets/img/icons/printer.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->
            <div
              class="card"
              id="filter_inputs"
              :style="{
                display: filter ? 'block' : 'none',
              }"
            >
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Enter Name" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Enter Reference No" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <vue-select :options="Completed" placeholder="Completed" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <a class="btn btn-filters ms-auto"
                        ><img src="../../../assets/img/icons/search-whites.svg" alt="img"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Filter -->
            <!-- <div class="table-responsive"> -->
              <a-table
                :columns="columns"
                :data-source="sales"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id'">
                    <span
                      class="badges bg-lightgreen"
                      v-if="record.status === 1"
                      >Livré</span
                    >
                    <span
                      class="badges bg-lightred"
                      v-else="record.status === 1"
                      >En attente</span
                    ></template
                  >
                  <!-- <template v-else-if="column.key === 'pay'">
                    <span class="badges bg-lightgreen" v-if="record.payment_status === 'paid'">{{
                      record.payment_status
                    }}</span>
                    <span class="badges bg-lightred" v-else="record.payment_status === 'due'">{{
                      record.payment_status
                    }}</span></template
                  > -->
                  <template v-else-if="column.key === 'action'">
                    <div class="text-center">
                      <a
                        class="action-set"
                        href="javascript:void(0);"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <router-link :to="{name:'sales-details', params:{id:record.id}}" class="dropdown-item"
                            ><img
                              src="../../../assets/img/icons/eye1.svg"
                              class="me-2"
                              alt="img"
                            />Detail</router-link
                          >
                        </li>
                        <li>
                          <!-- <template v-if="record.status == 0">
                            <router-link :to="{name:'edit-sales', params:{id:record.id}}" class="dropdown-item"
                            ><img
                              src="../../../assets/img/icons/edit.svg"
                              class="me-2"
                              alt="img"
                            />Modifier</router-link
                          >
                          </template>
                          -->
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#showpayment"
                            ><img
                              src="../../../assets/img/icons/dollar-square.svg"
                              class="me-2"
                              alt="img"
                            />Paiements</a
                          >
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            class="dropdown-item"
                            @click.prevent="addPayment(record)"
                            ><img
                              src="../../../assets/img/icons/plus-circle.svg"
                              class="me-2"
                              alt="img"
                            />Nouveau paiement</a
                          >
                        </li>
                        <!-- <li>
                          <a href="ja  vascript:void(0);" class="dropdown-item"
                            ><img
                              src="../../../assets/img/icons/download.svg"
                              class="me-2"
                              alt="img"
                            />Download pdf</a
                          >
                        </li> -->
                        <li>
                          <a href="javascript:void(0);" @click="deleteSale(record.id)" class="dropdown-item confirm-text"
                            ><img
                              src="../../../assets/img/icons/delete1.svg"
                              class="me-2"
                              alt="img"
                            />Supprimer</a
                          >
                        </li>
                      </ul>
                    </div>
                  </template>
                </template>
              </a-table>
            <!-- </div> -->
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>
  <!-- <div
    class="modal fade"
    id="createpayment"
    tabindex="-1"
    aria-labelledby="createpayment"
    aria-hidden="true"
    data-backdrop="static" 
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Nouveau paiement</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <form @submit="handleSubmit">
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Date de versement</label>
                  <VueDatePicker name="expected_delivery_date" :format="format" v-model="form.transaction_date" :format-locale="fr"></VueDatePicker>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Reference</label>
                  <input type="text" name="reference" readonly v-model="form.reference" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Montant reçu</label>
                  <input type="text" name="amount" v-model="form.amount" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Montant du paiement</label>
                  <input type="text" name="total_amount" readonly v-model="form.total_amount" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Type de paiement</label>
                  <vue-select :options="Cash" placeholder="Cash" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Payeur</label>
                  <input type="text" name="payer" readonly v-model="form.payer" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group mb-0">
                  <label>Note</label>
                  <textarea class="form-control" name="note" v-model="form.note"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-submit">Submit</button>
            <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  </div> -->
  <!-- <saleslistmodal></saleslistmodal> -->
</template>
<script setup>
import useSales  from "@/composables/sales";
import { onMounted, ref , reactive } from "vue";
import { Form, Field, useResetForm } from 'vee-validate';
import * as yup from 'yup';
import { fr } from "date-fns/locale";

const { sales, getSales, destroySale } = useSales()

const editing = ref(false)

const form = ref({
  id:'',
  transaction_date:'',
  amount:'',
  total_amount:'',
  payer:'',
  note:''
})


const date = ref(new Date());
const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

onMounted(getSales)

const deleteSale = async (id) => {
  if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet enregistrement?')) {
        return
    }
    await destroySale(id)
    
    await getSales()
}

const addPayment = (record) =>{

  $('#createpayment').modal('show')

  form.value = {
        id: record.id,
        transaction_date: date.value,
        amount: 0,
        total_amount:record.total_amount,
        reference:record.reference,
        note:''
        
    };

    console.log(form.value.id);
}


</script>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "Nom du client",
    dataIndex: "customer",
    sorter: (a, b) => a.customer.localeCompare(b.customer),
  },
  {
    title: "Référence",
    dataIndex: "reference",
    sorter: {
      compare: (a, b) => {
        a = a.reference.toLowerCase();
        b = b.reference.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Statut",
    dataIndex: "status",
    key: "id",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  
  {
    title: "Montant total",
    dataIndex: "total_amount",
    sorter: {
      compare: (a, b) => {
        a = a.total_amount.toLowerCase();
        b = b.total_amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Montant payé",
    dataIndex: "paid_amount",
    sorter: {
      compare: (a, b) => {
        a = a.paid_amount.toLowerCase();
        b = b.paid_amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Montant dû",
    dataIndex: "due_amount",
    sorter: {
      compare: (a, b) => {
        a = a.due_amount.toLowerCase();
        b = b.due_amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Auteur",
    dataIndex: "auteur",
    sorter: {
      compare: (a, b) => {
        a = a.auteur.toLowerCase();
        b = b.auteur.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date de creation",
    dataIndex: "add_date",
    sorter: {
      compare: (a, b) => {
        a = a.add_date.toLowerCase();
        b = b.add_date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
export default {
  data() {
    return {
      filter: false,
      title: "Commandes clients",
      title1: "Liste commandes clients",
      items: [
        {
          text: "Ajouter une nouvelle commande",
          addname: "/add-sales",
        },
      ],
      columns,
      Completed: ["Completed", "Paid"],
    };
  },
  name: "saleslist",
};
</script>
