<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar></sidebar>
      <div class="page-wrapper">
        <div class="content">
          <pageheader :title="title" :title1="title1" />
  
          <!-- /add -->
          <form @submit.prevent="handleSubmit">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label>Nom devise</label>
                      <input type="text"  name="name" v-model="form.name" />
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label>Code devise</label>
                      <input type="text" name="code" v-model="form.code" />
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label>symbole devise</label>
                      <input type="text" name="code" v-model="form.symbol" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                      <div v-if="loading" class="btn-content">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="ms-2">Veuillez patienter...</span>
                      </div>
                      <div v-else class="btn-content">Enregistrer</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </form>
          
          <!-- /add -->
        </div>
      </div>
    </div>
  </template>
<script setup>

import  useCurrencies from '@/composables/currencies'
import { reactive } from 'vue'

const { loading, storeCurrency, errors } = useCurrencies()

const form = reactive({
    'name':'',
    'code':'',
    'symbol':'',
    'status':''
})

const handleSubmit = async () =>{
  await storeCurrency(form)
}

</script>
  
  <script>
  export default {
    data() {
      return {
        title: "Gestion devises",
        title1: "Créer une nouvelle devise",
      };
    },
    name: "addcurrency",
  };
  </script>
  