import axios from 'axios';
import store from '../store'
import { router } from "../router";
// const store = useStore();

axios.defaults.withCredentials = true
axios.defaults.baseURL= 'https://consumer-api.distriforce.shop/api'
axios.defaults.headers.common["Accept"] = "application/json";
const token = localStorage.getItem('token')
if(token){
  axios.defaults.headers.common['Authorization'] = token
}

// manage error and expire token

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        store.dispatch('logout')
        return router.push('/signin')
    }
    else{
      // store.commit('handle_error',JSON.parse(error.response.data.error));

      return Promise.reject(error);
     
    }
  }
})
