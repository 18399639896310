<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar></sidebar>
      <div class="page-wrapper">
        <div class="content">
          <pageheader :title="title" :title1="title1" />
  
          <!-- /add -->
          <form @submit.prevent="handleSubmit">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label>Nom devise</label>
                      <input type="text"  name="name" v-model="currency.name" />
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label>Code devise</label>
                      <input type="text" name="code" v-model="currency.code" />
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label>symbole devise</label>
                      <input type="text" name="code" v-model="currency.symbol" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                      <div v-if="loading" class="btn-content">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="ms-2">Veuillez patienter...</span>
                      </div>
                      <div v-else class="btn-content">Enregistrer</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </form>
          
          <!-- /add -->
        </div>
      </div>
    </div>
  </template>
<script setup>

import  useCurrencies from '@/composables/currencies'
import { onMounted } from 'vue'

const { loading, updateCuurency, currency, getCurrency, errors } = useCurrencies()

const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

onMounted(() => getCurrency(props.id))

const handleSubmit = async () => {
    await updateCuurency(props.id)
}


</script>
  
  <script>
  export default {
    data() {
      return {
        title: "Gestion devises",
        title1: "Modifier une nouvelle devise",
      };
    },
    name: "editcurrency",
  };
  </script>
  