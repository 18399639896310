import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useCurrencies() {
    const currencies= ref([])
    const currency = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);

    const getCurrencies = async () => {
        let response = await axios.get('/currencies')
        currencies.value = response.data.data;
    }


    const getCurrency = async (id) => {
        let response = await axios.get('/currencies/'+id)
        currency.value = response.data.data;
    }

    const storeCurrency = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            await axios.post('/currencies/', data)
            await router.push({name: 'currencylist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const updateCuurency = async (id) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.put('/currencies/' +id, currency.value)
            await router.push({name: 'currencylist'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false;
            }
        }
    }

    const destroyCurrency = async (id) => {
        await axios.delete('/currencies/' + id)
    }

    const updateStatusCurrency = async (id) => {
        let response = await axios.put('/update-status-currency/' + id)
    }

    return {
        currencies,
        currency,
        errors,
        getCurrencies,
        getCurrency,
        storeCurrency,
        updateCuurency,
        destroyCurrency,
        updateStatusCurrency,
        loading,
    }
}