<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <form
        @submit.prevent="handlerSubmit"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Catégorie parent </label>
                    <vue-select
                    name="category_id"
                    v-model="subcategory.category_id"
                    :options="categories"
                     />
                     <span class="text-danger" v-if="errors?.category_id">{{ errors.category_id[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Libellé sous catégorie</label>
                    <input type="text" name="name" v-model="subcategory.name"/>
                    <span class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Commentaire</label>
                    <textarea name="comment" v-model="subcategory.comment" class="form-control"></textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                      <div v-if="loading" class="btn-content">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="ms-2">Veuillez patienter...</span>
                      </div>

                      <div v-else class="btn-content">Enregistrer</div>
                    </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import useSubCategories from '@/composables/subcategories';
import useCategories from '@/composables/categories';

const { categories, selectCategories } = useCategories()

const { errors, updateSubCategory, loading, getSubCategory, subcategory } = useSubCategories()


const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

onMounted(() => {
  selectCategories()
  getSubCategory(props.id)
})


const handlerSubmit = async () => {

  let data = {
    category_id: subcategory.value.category_id,
    comment: subcategory.value.comment,
    name: subcategory.value.name
  }
  await updateSubCategory(props.id, data)
}

</script>
<script>
export default {
  data() {
    return {
      title: "Sous catégorie",
      title1: "Ajouter / Modifier sous catégorie",
    };
  },
  name: "editsubcategory",
};
</script>
