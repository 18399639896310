<template>
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Boutiques</label>
                  <v-select
                    v-model="form.warehouse_id"
                    :reduce="(option) => option.id"
                    :options="warehouses"
                    placeholder="Rechercher une boutique"
                    :dropdown-should-open="dropdownShouldOpen"
                  />
                  <!-- <vue-select
                    :options="warehouses"
                    placeholder="Choisir"
                    name="supplier_id"
                    v-model="form.warehouse_id"
                  /> -->
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Date devis</label>
                  <VueDatePicker
                    v-model="form.quotation_date"
                    name="quotation_date"
                    :format="format"
                    :format-locale="fr"
                  ></VueDatePicker>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Validité devis</label>
                  <VueDatePicker
                    v-model="form.validate_date"
                    name="validate_date"
                    :format="format"
                    :format-locale="fr"
                  ></VueDatePicker>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>N° facture</label>
                  <input
                    type="text"
                    v-model="form.number"
                    name="number"
                  />
                </div>
              </div>
              <div class="col-lg-12 col-sm-6 col-12">
                <div class="auto-complete-product-container">
                  <div class="form-group">
                    <label>Nom du produit</label>
                    <div class="input-groupicon">
                      <input
                        type="text"
                        placeholder="Scannez/recherchez le produit par code et sélectionnez..."
                        v-model="searchText"
                        @keyup="loadSuggestions()"
                      />
                      <div class="addonset">
                        <img
                          src="../../../assets/img/icons/scanners.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div
                      class="customer-search-suggestion"
                      v-if="suggestiondata.length"
                    >
                      <div class="customer-search-suggestion-inner">
                        <ul>
                          <li
                            v-for="(item, i) in suggestiondata"
                            @click="addCart(item)"
                            :key="item.id"
                          >
                            {{ item.designation }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Nom du produit</th>
                      <th>Qté</th>
                      <th>Prix ​​d'achat</th>
                      <th>Remise %</th>
                      <th>TVA %</th>
                      <th>Montant TTC</th>
                      <th class="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(itemcart, i) in form.quotation_items" :key="itemcart.id">
                      <span>

                      </span>
                      <td class="productimgname">
                        <span v-if="itemcart.product">
                          <a href="javascript:void(0);">{{ itemcart.product.designation }}</a>
                        </span>
                        <span v-else>
                          <a href="javascript:void(0);">{{ itemcart.designation }}</a>
                        </span>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.quantity"
                          name="number"
                          required
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="itemcart.unit_price"
                          name="number"
                          readonly
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.product_discount"
                          name="number"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.product_tax"
                          name="number"
                        />
                      </td>
                      <td class="text-end">
                          {{ (itemcart.quantity * itemcart.unit_price) - (itemcart.quantity * itemcart.unit_price * itemcart.product_discount / 100) +  (itemcart.quantity * itemcart.unit_price * itemcart.product_tax / 100)}}
                      </td>
                      <td>
                        <a class="delete-set" @click="deleteQuotationItems(itemcart.id, i)"
                          ><img src="../../../assets/img/icons/delete.svg" alt="svg"
                        /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
                <div class="col-lg-12 float-md-right">
                  <div class="total-order">
                    <ul>
                      <li>
                        <h4>TOTAL HT</h4>
                        <h5>{{ SubTotal() }}</h5>
                      </li>
                      <li>
                        <h4>TOTAL REMISE (-)</h4>
                          <h5>{{ TotalDiscount() }}</h5>
                      </li>
                      <li>
                        <h4>TOTAL TVA (+)</h4>
                        <h5>{{ TotalTax() }}</h5>
                      </li>
                      
                      <li class="total">
                        <h4>TOTAL TTC</h4>
                        <h5>{{ Total() }}</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            <div class="row">
              <div class="col-lg-12">
                <button
                  class="btn btn-primary btn-md"
                  type="submit"
                  :disabled="loading"
                  @click="handlerSubmit"
                >
                  <div v-if="loading" class="btn-content">
                    <b-spinner small type="grow"></b-spinner>
                    <span class="ms-2">Veuillez patienter...</span>
                  </div>

                  <div v-else class="btn-content">Enregistrer</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";
import useWarehouses from '@/composables/warehouses' ;
import { fr } from "date-fns/locale";
import { useRouter } from "vue-router";

const { warehouses, selectWarehouses, selectShop } = useWarehouses();
const loading = ref(false);
const router = useRouter();
let searchText = ref('')
let suggestiondata = ref([])
const errors = ref("");

let form = ref({
  id:''
})

const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

const addCart = (item) => {
  searchText.value = ''
  suggestiondata.value =''

  let product_check = form.value.quotation_items.find((product) => product.product_id === item.id);

  if (product_check) {
    product_check.quantity++;
  } else {
    const itemcart = {
      product_id: item.id,
      designation: item.designation,
      unit_price: item.cost,
      quantity: 1,
      product_tax: 0,
      product_discount: 0
    }
    form.value.quotation_items.push(itemcart)
  }
 
}

const dropdownShouldOpen = (VueSelect) => {
  if (form.value.warehouse_id !== '') {
    return VueSelect.open
  }
  return VueSelect.search.length !== 0 && VueSelect.open
}

const SubTotal = () => {
  let total = 0

  if (form.value.quotation_items) {

    form.value.quotation_items.map((data) =>{
      total = total + (data.quantity*data.unit_price)
    })
  }

  return total
}

const TotalDiscount = () => {
  let total = 0;
  if (form.value.quotation_items) {
    form.value.quotation_items.map((data) => {
      total = total + (data.quantity * data.unit_price * data.product_discount / 100);
    });
  }
  return total;
};

const TotalTax = () => {
  let total = 0;

  if (form.value.quotation_items) {
    form.value.quotation_items.map((data) => {
      total = total +  (data.quantity * data.unit_price * data.product_tax / 100);
    });
  }
  return total;
};

const Total = () => {

  if (form.value.quotation_items) {

    return SubTotal() - TotalDiscount() + TotalTax()

  }
}

const loadSuggestions = async () =>{
  let response = await axios.get('/product-search?s='+searchText.value)
  suggestiondata.value = response.data
}

const getQuotation = async () => {
  let response = await axios.get('/quotations/'+props.id)
  form.value = response.data.data
}


onMounted( async () => {
  getQuotation()
  selectShop()
})

const date = ref(new Date());
const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}


const deleteQuotationItems = (id,i) => {

  form.value.quotation_items.splice(i,1);

  if (id != undefined) {

    let response = axios.delete('/delete-quotation-items/' + id)

  }
}

const handlerSubmit = async () => {
  if (form.value.quotation_items.length >= 1){
      let subtotal =0
      subtotal = SubTotal()

      let total = 0
      total = Total()

      let totaldiscount = 0;
      totaldiscount = TotalDiscount();


      let totaltax = 0;
      totaltax = TotalTax();

      const formData = new FormData()

      formData.append('quotation_items', JSON.stringify(form.value.quotation_items))
      formData.append('warehouse_id', form.value.warehouse_id)
      formData.append('quotation_date', form.value.quotation_date)
      formData.append('validate_date', form.value.validate_date)
      formData.append('number', form.value.number)
      formData.append("totaldiscount", totaldiscount);
      formData.append("totaltax", totaltax);
      formData.append('subtotal', subtotal)
      formData.append('total', total)
      formData.append('_method', 'PUT');

      errors.value = ''
      loading.value = true
      try {
          let response = await axios.post("/quotations/"+form.value.id, formData)
          await router.push({name: 'quotationlist'})
      } catch (e) {

          if (e.response.status === 422) {
              errors.value = e.response.data.errors
              loading.value = false
          }
      }
  }
}
</script>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      title: "Devis client",
      title1: "Ajouter/modifier un devis",
      startdate: currentDate,
    };
  },
  name: "editquotation",
};
</script>
