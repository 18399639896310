<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <div class="search-path">
                  <!-- <a
                    class="btn btn-filter"
                    id="filter_search"
                    v-on:click="filter = !filter"
                    :class="{ setclose: filter }"
                  >
                    <img src="../../../../assets/img/icons/filter.svg" alt="img" />
                    <span
                      ><img src="../../../../assets/img/icons/closes.svg" alt="img"
                    /></span>
                  </a> -->
                </div>
                <div class="search-input">
                  <a class="btn btn-searchset"
                    ><img src="../../../../assets/img/icons/search-white.svg" alt="img"
                  /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search..."
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <!-- <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                      ><img src="../../../../assets/img/icons/pdf.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                      ><img src="../../../../assets/img/icons/excel.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                      ><img src="../../../../assets/img/icons/printer.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div> -->
            </div>
            <!-- /Filter -->
            <!-- <div
              class="card"
              id="filter_inputs"
              :style="{
                display: filter ? 'block' : 'none',
              }"
            >
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Store Name" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Enter Phone" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Enter Email" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <vue-select :options="Disable" placeholder="Disable" />
                    </div>
                  </div>
                  <div class="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div class="form-group">
                      <a class="btn btn-filters ms-auto"
                        ><img src="../../../../assets/img/icons/search-whites.svg" alt="img"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- /Filter -->
            <div class="table-responsive">
              <a-table
                :columns="columns"
                :data-source="zones"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id'">
                    <div
                      class="status-toggle d-flex justify-content-between align-items-center"
                    >
                      <input
                        type="checkbox"
                        :id="record.slug"
                        class="check"
                        :checked="record.checked"
                        @change="handlerChange(record.id)"
                      />
                      <label :for="record.slug" class="checktoggle">{{
                        record.Status
                      }}</label>
                    </div></template
                  >

                  <template v-else-if="column.key === 'action'">
                    <router-link v-if="hasPermission('modifier-zone')" class="me-3" :to="{ name:'edit-zone', params:{id: record.id}}">
                      <img src="../../../../assets/img/icons/edit.svg" alt="img" />
                    </router-link>
                    <a
                      v-if="hasPermission('supprimer-zone')"
                      class="me-3 confirm-text"
                      href="javascript:void(0);"
                      @click="deleteRecord(record.id)"
                    >
                      <img src="../../../../assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>
</template>
<script setup>
import useZones from "@/composables/useZones"
import usePermissions from "@/composables/usePermissions"
import {  onMounted} from 'vue'

const { zones, getZones, destroyZone, updateStatusZone } = useZones()
const { loadUserPermissions, hasPermission } = usePermissions()

onMounted(() => {
  getZones()
  loadUserPermissions()
})


const deleteRecord = (id)  =>{
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Voulez‑vous vraiment supprimer cet enregistrement?",
    showCancelButton: true,
    confirmButtonColor: "#FF9F43",
    cancelButtonColor: "#d33",
    confirmButtonText: "Oui, supprimer!",
    cancelButtonText: "Non, Annuler!",
  }).then(async(result) => {
    if (result.isConfirmed) {
      await destroyZone(id)
      await getZones()
      Swal.fire("Supprimer!", "Vous avez supprimé !.");
    }
  });
}

const handlerChange = async (id) => {

  await updateStatusZone(id)
  await getZones()
}

</script>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "Nom zone",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Montant de livraison",
    dataIndex: "amount",
    sorter: {
      compare: (a, b) => {
        a = a.amount.toLowerCase();
        b = b.amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "STATUT",
    dataIndex: "status",
    key: "id",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const data = [

];
export default {
  data() {
    return {
      filter: false,
      title: "Zones",
      title1: "Liste des zones",
      items: [
        {
          text: "Ajouter une nouvelle zone",
          addname: "/add-zone",
        },
      ],
      columns,
    };
  },
  methods: {

  },
  name: "zone-list",
};
</script>
