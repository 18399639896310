import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useActivities() {
    const activities = ref([])
    const activity = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);

    const getActivities = async () => {
        let response = await axios.get('/activities')
        activities.value = response.data.data;
    }

    
    const getActivity = async (id) => {
        let response = await axios.get('/activities/'+id)
        activity.value = response.data.data;
    }

    const storeActivity = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            await axios.post('/activities/', data)
            await router.push({name: 'activitylist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const updateActivity = async (id) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.put('/activities/' +id, activity.value)
            await router.push({name: 'activitylist'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false;
            }
        }
    }

    const destroyActivity = async (id) => {
        await axios.delete('/activities/' + id)
    }

    const updateStatusActivity = async (id) => {
        let response = await axios.put('/update-status-activity/' + id)
    }

    return {
        activities,
        activity,
        errors,
        getActivities,
        getActivity,
        storeActivity,
        updateActivity,
        destroyActivity,
        loading,
        updateStatusActivity,
    }
}