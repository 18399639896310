<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <!-- <div class="search-path">
                  <a
                    class="btn btn-filter"
                    id="filter_search"
                    v-on:click="filter = !filter"
                    :class="{ setclose: filter }"
                  >
                    <img src="../../../assets/img/icons/filter.svg" alt="img" />
                    <span
                      ><img src="../../../assets/img/icons/closes.svg" alt="img"
                    /></span>
                  </a>
                </div>
                <div class="search-input">
                  <a class="btn btn-searchset"
                    ><img src="../../../assets/img/icons/search-white.svg" alt="img"
                  /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search..."
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div>
                </div> -->
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                      ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                      ><img src="../../../assets/img/icons/excel.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                      ><img src="../../../assets/img/icons/printer.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->
            <!-- <div
              class="card mb-0"
              id="filter_inputs"
              :style="{
                display: filter ? 'block' : 'none',
              }"
            >
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-lg-12 col-sm-12">
                    <div class="row">
                      <div class="col-lg col-sm-6 col-12">
                        <div class="form-group">
                          <vue-select :options="Product" placeholder="Choose Product" />
                        </div>
                      </div>
                      <div class="col-lg col-sm-6 col-12">
                        <div class="form-group">
                          <vue-select :options="Choose" placeholder="Choose Category" />
                        </div>
                      </div>
                      <div class="col-lg col-sm-6 col-12">
                        <div class="form-group">
                          <vue-select
                            :options="Category"
                            placeholder="Choose Sub Category"
                          />
                        </div>
                      </div>
                      <div class="col-lg col-sm-6 col-12">
                        <div class="form-group">
                          <vue-select :options="Brand" placeholder="Brand" />
                        </div>
                      </div>
                      <div class="col-lg col-sm-6 col-12">
                        <div class="form-group">
                          <vue-select :options="Price" placeholder="Price" />
                        </div>
                      </div>
                      <div class="col-lg-1 col-sm-6 col-12">
                        <div class="form-group">
                          <a class="btn btn-filters ms-auto"
                            ><img
                              src="../../../assets/img/icons/search-whites.svg"
                              alt="img"
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- /Filter -->
            <div class="table-responsive">
              <a-table
                :columns="columns"
                :data-source="products"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id'">
                    <div class="productimgname">
                      <a v-if="record.image_url" href="javascript:void(0);" class="product-img">
                        <img
                          :src="record.image_url"
                          alt="product"
                        />
                      </a>
                      <a href="javascript:void(0);">{{ record.designation }}</a>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <!-- <router-link class="me-3" to="product-details">
                      <img src="../../../assets/img/icons/eye.svg" alt="img" />
                    </router-link> -->
                    <router-link v-if="hasPermission('modifier-produit')" class="me-3" :to="{ name: 'editproduct', params: {id: record.id}}">
                      <img src="../../../assets/img/icons/edit.svg" alt="img" />
                    </router-link>
                    <a v-if="hasPermission('supprimer-produit')"  class="confirm-text" href="javascript:void(0);" @click="deleteRecord(record.id)">
                      <img src="../../../assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>
</template>

<script setup>
import useProducts  from "@/composables/products";
import usePermissions from "@/composables/usePermissions"
import { onMounted } from "vue";
const { products, destroyProduct, getProducts } = useProducts()
const { loadUserPermissions, hasPermission } = usePermissions()

onMounted(() => {
  getProducts()
  loadUserPermissions()
})


const deleteRecord = (id)  =>{
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Voulez‑vous vraiment supprimer cet enregistrement?",
    showCancelButton: true,
    confirmButtonColor: "#FF9F43",
    cancelButtonColor: "#d33",
    confirmButtonText: "Oui, supprimer!",
    cancelButtonText: "Non, Annuler!",
  }).then(async(result) => {
    if (result.isConfirmed) {
      await destroyProduct(id)
      await getProducts()
      Swal.fire("Supprimer!", "Vous avez supprimé !.");
    }
  });
}


</script>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "DÉSIGNATION",
    dataIndex: "designation",
    key: "id",
    sorter: {
      compare: (a, b) => {
        a = a.designation.toLowerCase();
        b = b.designation.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "SKU",
    dataIndex: "sku",
    sorter: (a, b) => a.sku.localeCompare(b.sku),
  },
  {
    title: "CATÉGORIE",
    dataIndex: "category",
    sorter: {
      compare: (a, b) => {
        a = a.category.toLowerCase();
        b = b.category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "FOURNISSEUR",
    dataIndex: "supplier",
    sorter: true,
  },
  {
    title: "PRIX",
    dataIndex: "price",
    sorter: {
      compare: (a, b) => {
        a = a.price.toLowerCase();
        b = b.price.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "UNITÉ",
    dataIndex: "unit",
    sorter: {
      compare: (a, b) => {
        a = a.unit.toLowerCase();
        b = b.unit.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Qty",
  //   dataIndex: "Qty",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Qty.toLowerCase();
  //       b = b.Qty.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "AUTEUR",
    dataIndex: "auteur",
    sorter: {
      compare: (a, b) => {
        a = a.auteur.toLowerCase();
        b = b.auteur.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "DATE DE CRÉATION",
    dataIndex: "created_at",
    sorter: {
      compare: (a, b) => {
        a = a.created_at.toLowerCase();
        b = b.created_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "ACTION",
    key: "action",
    sorter: false,
  },
];

export default {
  data() {
    return {
      filter: false,
      title: "Liste de produits",
      title1: "Gestion des produits",
      items: [
        {
          text: "Ajouter un nouveau produit",
          addname: "/addproduct",
        },
      ],
      columns,
    };
  },
  name: "productlist",
};
</script>
<style>
.ant-tooltip {
  display: none !important;
}
</style>
