<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar></sidebar>
      <div class="page-wrapper">
        <div class="content">
          <pageheader :title="title" :title1="title1" />
            <form @submit.prevent="handleSubmit">
                <div class="card">
                    <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label>Nom type paiement</label>
                            <input type="text"  name="name" v-model="payment.name" />
                            <span class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</span>
                        </div>
                        </div>
                        <div class="col-lg-12">
                        <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                            <div v-if="loading" class="btn-content">
                            <b-spinner small type="grow"></b-spinner>
                            <span class="ms-2">Veuillez patienter...</span>
                            </div>
                            <div v-else class="btn-content">Enregistrer</div>
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </div>
  </template>
<script setup>

import usePayments from '@/composables/payments'
import { onMounted } from 'vue'

const { loading, getPayment, payment ,errors, updatePayment } = usePayments()

const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

onMounted(() => getPayment(props.id))

const handleSubmit = async () => {
    await updatePayment(props.id)
}
</script>
  
  <script>
  export default {
    data() {
      return {
        title: "Modes de paiement",
        title1: "Modifier mode paiement",
      };
    },
    name: "editpaymenttype",
  };
  </script>
  