import { ref } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";

export default function useAuth() {

    const router = useRouter()
    const errors = ref('')
    const loading = ref(false)
    const store = useStore();

    const login = async (data) => {

        errors.value = ''
        loading.value = true
        setTimeout(async() => {
            store
            .dispatch("login", data)
            .then(() => router.push({name:'index'}))
            .catch((e) => {
                if (e.response.status === 422) {
                    errors.value = e.response.data.errors
                    loading.value = false
                };
            });
        }, 2000);
    }

    const logout = async () =>{
        store.dispatch("logout");
        router.push({name: 'signin'});
    }


    const storePasswordChange = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            await axios.post('/web-user-auth-change-password/', data)
            
            loading.value = false
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }


    const  checkTokenValidity = async () =>{
         await axios.get('/me')
          .then(resp => {
            router.push({name: 'index'});
          })
          .catch(e => {});
      }

    return {
        loading,
        errors,
        login,
        logout,
        storePasswordChange,
        checkTokenValidity
    }
}