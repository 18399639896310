<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <form   @submit.prevent="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Désignation</label>
                    <input type="text" name="designation" v-model="product.designation" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Catégorie</label>
                    <vue-select :options="categories" placeholder="Catégorie" @select="changeCategory($event)" name="category_id" v-model="product.category_id" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Sous catégorie</label>
                    <vue-select :options="subcategories" placeholder="Sous catégorie" name="subcategory_id" v-model="product.subcategory_id" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Fournisseur {{ product.supplier_id }}</label>
                      <vue-select :options="suppliers" name="supplier_id" v-model="product.supplier_id"  placeholder="Choisir un fournisseur" />
                    </div>
                  </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Unité</label>
                      <vue-select :options="units" name="unit_id" v-model="product.unit_id" placeholder="Choisir une unité" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>SKU</label>
                      <b-form-input type="text" name="sku" v-model="product.sku" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Stock d'alerte</label>
                      <b-form-input type="number" name="stock_alert" v-model="product.stock_alert"/>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Quantité initiale</label>
                      <b-form-input type="number" name="quantity" disabled v-model="product.quantity"  />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <textarea class="form-control" v-model="product.note"></textarea
                    >
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Tax</label>
                      <b-form-input v-model="product.order_tax" name="order_tax" type="number" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Type de taxe</label>
                      <vue-select :options="TaxType" v-model="product.tax_type" name="tax_type"/>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Prix achat</label>
                    <b-form-input type="number" name="cost" v-model="product.cost"/>
                  </div>
                </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Prix vente</label>
                      <b-form-input type="number" name="price" v-model="product.price"/>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label> Cliquez ici pour télécharger une image</label>
                      <div class="image-upload">
                        <input type="file" style="height: 200px;" accept="image/*" @change="handleImageSelected"/>
                        <div class="image-uploads" >
                          <img style="height: 150px; width: 150px"  v-show="imageUrl" :src="imageUrl" alt="img" />
                        </div>
                      </div>
                    </div>
                    <input type="hidden" name="image_remove" v-model="product.image_url">
                  </div>
                <div class="col-12">
                  <div class="product-list">
                    <ul class="row">
                      <li
                        :style="{
                          display: filter ? 'block' : 'none',
                        }"
                      >
                        <div class="productviews">
                          <div class="productviewsimg">
                            <img :src="product.image" alt="Image actuelle" />
                          </div>
                          <div class="productviewscontent">
                            <div class="productviewsname">
                              <h2>Image actuelle</h2>
                              <!-- <h3>581kb</h3> -->
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-12">
                    <button class="btn btn-primary btn-md" :disabled="loading">
                      <div v-if="loading" class="btn-content">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="ms-2">Veuillez patienter...</span>
                      </div>

                      <div v-else class="btn-content">Enregistrer</div>
                    </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script setup>
import useImageUpload from "@/composables/imageUpload";
import useCategories from '@/composables/categories';
import useSubCategories from '@/composables/subcategories';
import useProducts from '@/composables/products';
import useUnits from '@/composables/units';
import useSuppliers from '@/composables/suppliers';

import { onMounted, ref, reactive } from "vue";

const { imageFile, imageUrl, handleImageSelected } = useImageUpload();

const { categories, selectCategories } = useCategories()

const { subcategories, selectSubCategoryByCategory, subCategoryByProduitCategory } = useSubCategories()

const { units, selectUnits } = useUnits()

const { suppliers, selectSuppliers } = useSuppliers()

const { errors, loading, viewProduct, product, updateProduct } = useProducts()

const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

const  changeCategory = async (val) =>{
    await selectSubCategoryByCategory(val.id)
}

onMounted(() => {
  viewProduct(props.id)
  subCategoryByProduitCategory(props.id)
  selectCategories()
  selectUnits()
  selectSuppliers()
})

const handleSubmit = async () => {

  let formData = new FormData();
  let config = {
      header: {
        "Content-Type": "multipart/form-data",
      },
    };
  formData.append("designation", product.value.designation);
  formData.append("_method", "PUT");
  formData.append("category_id", product.value.category_id)
  formData.append("subcategory_id", product.value.subcategory_id)
  formData.append("unit_id", product.value.unit_id)
  formData.append('supplier_id', product.value.supplier_id)
  formData.append("sku", product.value.sku)
  formData.append("stock_alert", product.value.stock_alert)
  formData.append("note", product.value.note)
  formData.append("order_tax", product.value.order_tax)
  formData.append("tax_type", product.value.tax_type)
  formData.append("price", product.value.price)
  formData.append("cost", product.value.cost)
  formData.append("image_remove", product.value.image_url)
  formData.append("image", imageFile.value)

  await updateProduct(props.id, formData, config);
}
</script>
<script>
export default {
  data() {
    return {
      filter: true,
      title: "Modification produit",
      title1: "Mettez à jour votre produit",
      TaxType: [{id: "1", text: "Exclusif"}, {id: "2", text: "Inclusif"}],
    };
  },
  name: "editproduct",
};
</script>
