<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <!-- /add -->
        <form @submit.prevent="handlerSubmit" novalidate>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Nom</label>
                    <input type="text" name="name" v-model="form.name" />
                    <span class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Montant</label>
                    <input type="number" class="form-control" name="amount" v-model="form.amount" />
                    <span class="text-danger" v-if="errors?.amount">{{ errors.amount[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      id="textarea"
                      v-model="form.comment"
                      name="comment"
                      rows="3"
                      max-rows="6"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                    <div v-if="loading" class="btn-content">
                      <b-spinner small type="grow"></b-spinner>
                      <span class="ms-2">Veuillez patienter...</span>
                    </div>
                    <div v-else class="btn-content">Enregistrer</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script setup>
import useZones from "@/composables/useZones"
import { reactive } from 'vue';

const form = reactive({
  name: '',
  amount: '',
  comment: ''
})
const { errors, storeZone, loading } = useZones()

const handlerSubmit = async () => {

  await storeZone(form)
}

</script>
<script>
export default {
  data() {
    return {
      title: "Zones",
      title1: "Ajouter/mettre à jour une zone",
    };
  },
  name: "add-zone",
};
</script>