import PermissionService  from '@/services/permissionService';
import TokenService from '@/services/tokenService';

export default {
    async auth(to, from, next) {
        const token = localStorage.getItem('token');
        if (!token) {
            next('/signin');
        }
        else
        {
            const isValidToken = await TokenService.validateToken(token);

            if (isValidToken) {
                let userPermissions = await PermissionService.fetchUserPermissions()
        
                if(to.meta.permissions && !to.meta.permissions.some(permission => userPermissions.includes(permission))){
                    next('/unauthorized');
                    }
                else{
                    next();
                }
            } else {
            next('/signin');
            }

        }
    },
}