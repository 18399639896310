import { ref } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useOrders() {
    const orders = ref([])
    const order = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);

    const getOrders = async () =>{
        let response = await axios.get('/orders')
        orders.value = response.data.data

    }

    const getOrderReport = async () =>{
        let response = await axios.get('/orderreport')
        orders.value = response.data.data
    }

    const getOrder = async (id) =>{
        let response = await axios.get('/order-detail/'+id)
        order.value = response.data.data

        console.log(order.value)

    }

    const handlerAcceptOrder = async (id, data) => {
        try {
            let response = await axios.put('/accept-order/' + id, data)
            await router.push({name: 'orderslist'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
        }
    }

    const handlerCancelOrder = async (id, data) => {

        try {
            let response = await axios.put('/cancel-order/' + id, data)
            await router.push({name: 'orderslist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
            /*console.log(e)*/
        }

    }

    const handlerOrderShipped = async (id) => {
        try {
            let response = await axios.put('/mark-order-shipped/' + id)
            await router.push({name: 'orderslist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
            /*console.log(e)*/
        }
    }

    return {
        orders,
        getOrders,
        getOrderReport,
        getOrder,
        order,
        handlerAcceptOrder,
        handlerCancelOrder,
        handlerOrderShipped,
        errors,
        loading
    }
}