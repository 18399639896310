<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <form @submit.prevent="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 col-sm-8 col-12">
                  <div class="form-group">
                    <label>Nom client</label>
                    <input type="text" name="full_name" v-model="customer.full_name" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-8 col-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" name="email" v-model="customer.email" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-8 col-12">
                  <div class="form-group">
                    <label>Téléphone</label>
                    <input type="text" name="phone" v-model="customer.phone" />
                  </div>
                </div>
                <div class="col-lg-12 col-12">
                  <div class="form-group">
                    <label>Adresse</label>
                    <textarea class="form-control" name="address" v-model="customer.address"></textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <textarea class="form-control" name="commentaire" v-model="customer.commentaire"></textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button class="btn btn-primary btn-md" :disabled="loading">
                        <div v-if="loading" class="btn-content">
                          <b-spinner small type="grow"></b-spinner>
                          <span class="ms-2">Veuillez patienter...</span>
                        </div>
    
                        <div v-else class="btn-content">Enregistrer</div>
                      </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue';
import useCustomers from '@/composables/customers';

const { customer, viewCustomer, errors, loading, updateCustomer} = useCustomers()

const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

onMounted(() => viewCustomer(props.id))


const handleSubmit = async() =>{
  await updateCustomer(props.id)
}

</script>
<script>
export default {
  data() {
    return {
      title: "Modifier la gestion des clients",
      title1: "Modifier/mettre à jour le client",
      // Newyork: ["Newyork", "City"],
      // States: ["United States", "India"],
    };
  },
  name: "editcustomer",
};
</script>
