<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Client</label>
                  <div class="row">
                    <vue-select :options="customers" v-model="form.customer_id" placeholder="Choisir un client" />
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Date de vente</label>
                  <VueDatePicker name="order_date"  v-model="form.order_date" :format="format" :format-locale="fr"></VueDatePicker>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Date prévue de la livraison</label>
                  <VueDatePicker name="expected_delivery_date"  v-model="form.expected_delivery_date" :format="format" :format-locale="fr"></VueDatePicker>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Numéro</label>
                  <input type="text" v-model="form.number" name="number" />
                </div>
              </div>
              <div class="col-lg-12 col-sm-6 col-12">
                <div class="auto-complete-product-container">
                  <div class="form-group">
                    <label>Nom du produit</label>
                    <div class="input-groupicon">
                      <input
                        type="text"
                        placeholder="Scannez/recherchez le produit par code et sélectionnez..."
                        v-model="searchText"
                        @keyup="loadSuggestions()"
                      />
                      <div class="addonset">
                        <img
                          src="../../../assets/img/icons/scanners.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div
                      class="customer-search-suggestion"
                      v-if="suggestiondata.length"
                    >
                      <div class="customer-search-suggestion-inner">
                        <ul>
                          <li
                            v-for="(item, i) in suggestiondata"
                            @click="addCart(item)"
                            :key="item.id"
                          >
                            {{ item.designation }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="table-responsive mb-3">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Nom du produit</th>
                      <th>Qté</th>
                      <th>Prix ​​d'achat</th>
                      <th class="text-end">Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(itemcart, i) in listCart" :key="itemcart.id">
                      <td class="productimgname">
                        <a class="product-img">
                          <img :src="itemcart.image" alt="product" />
                        </a>
                        <a href="javascript:void(0);">{{
                          itemcart.designation
                        }}</a>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.quantity"
                          name="quantity"
                          required
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="itemcart.cost"
                          name="cost"
                          readonly
                        />
                      </td>
                      <td class="text-end">
                        <span v-if="itemcart.quantity">
                          {{ itemcart.quantity * itemcart.cost }}
                        </span>
                        <span v-else></span>
                      </td>
                      <td>
                        <a class="delete-set" @click="removeItem(i)"
                          ><img
                            src="../../../assets/img/icons/delete.svg"
                            alt="svg"
                        /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 float-md-right">
                <div class="total-order">
                  <ul>
                    <li>
                      <h4>Sous total</h4>
                      <h5>{{ SubTotal() }}</h5>
                    </li>
                    <li>
                      <h4>Remise</h4>
                      <h5>
                        <input
                          type="text"
                          v-model="form.discount_amount"
                          name="discount_amount"
                          class="form-control"
                        />
                      </h5>
                    </li>
                    <li class="total">
                      <h4>Total</h4>
                      <h5>{{ Total() }}</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Statut livraison</label>
                  <vue-select
                    :options="Status"
                    name="status"
                    v-model="form.status"
                    placeholder="Statut de livraison"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Commentaire</label>
                  <textarea
                    class="form-control"
                    name="note"
                    v-model="form.note"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-12">
                <button
                  class="btn btn-primary btn-md"
                  type="submit"
                  :disabled="loading"
                  @click="handlerSubmit"
                >
                  <div v-if="loading" class="btn-content">
                    <b-spinner small type="grow"></b-spinner>
                    <span class="ms-2">Veuillez patienter...</span>
                  </div>

                  <div v-else class="btn-content">Enregistrer</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import axios from "axios";
import { ref, onMounted } from "vue";
import useCustomers from "@/composables/customers";
import { fr } from "date-fns/locale";
import { useRouter } from "vue-router";

const { customers , selectCustomers} = useCustomers();
const loading = ref(false);
const router = useRouter();

let searchText = ref("");
let suggestiondata = ref([]);
let form = ref([]);
let item = ref([]);
let listCart = ref([]);
const errors = ref("");

const loadSuggestions = async () => {
    let response = await axios.get("/product-search?s=" + searchText.value);
    suggestiondata.value = response.data;
};


const addCart = (item) => {
  searchText.value = "";
  suggestiondata.value = "";

  let product_check = listCart.value.find((product) => product.id === item.id);

  if (product_check) {
    product_check.quantity++;
  } else {
    const itemcart = {
      id: item.id,
      designation: item.designation,
      // price: item.price,
      cost: item.cost,
      quantity: 1,
      image: item.image,
    };

    listCart.value.push(itemcart);
  }
};

const removeItem = (i) => {
  listCart.value.splice(i, 1);
};

const SubTotal = () => {
  let total = 0;
  listCart.value.map((data) => {
    total = total + data.quantity * data.cost;
  });
  return total;
};

const Total = () => {
  return SubTotal() - form.value.discount_amount;
};

const indexForm = async () => {
  let response = await axios.get("/create-order");
  form.value = response.data;
};

onMounted(async () =>{
  selectCustomers()
  indexForm()
})


const date = ref(new Date());
// In case of a range picker, you'll receive [Date, Date]
const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

const handlerSubmit = async () => {
  if (listCart.value.length >= 1) {
    let subtotal = 0;
    subtotal = SubTotal();

    let total = 0;
    total = Total();

    const formData = new FormData();

    formData.append("order_items", JSON.stringify(listCart.value));

    formData.append("customer_id", form.value.customer_id);
    formData.append("order_date", form.value.order_date);
    formData.append(
      "expected_delivery_date",
      form.value.expected_delivery_date
    );
    formData.append("number", form.value.number);
    formData.append("discount_amount", form.value.discount_amount);
    formData.append("status", form.value.status);
    formData.append("note", form.value.note);
    formData.append("subtotal", subtotal);
    formData.append("total", total);

    errors.value = "";
    loading.value = true;
    try {
      let response = await axios.post("/store-order/", formData);
      await router.push({ name: "saleslist" });
    } catch (e) {
      if (e.response.status === 422) {
        errors.value = e.response.data.errors;
        loading.value = false;
      }
    }
  }
};

</script>
<script>
export default {
  data() {
    return {
      title: "Commandes clients",
      title1: "Ajouter/mettre à jour une commande",
      Status: [
        { id: "0", text: "En attente" },
        { id: "1", text: "Livré" },
      ],
    };
  },
  name: "add-sales",
};
</script>
