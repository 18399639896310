<template>
    <div></div>
</template>
<script>
// import TokenService from '@/services/tokenService';
// export default {
//   data() {
//     return {
//       token: [],
//     };
//   },
//   async created() {
//     this.token = await TokenService.validateToken();

//     console.log(this.token);
//   },
// };

// export default {
//     async mounted() {
//         const isValidToken = await TokenService.validateToken();;

//         if (!isValidToken) {
//             // Redirect or handle unauthorized access
//             this.$router.push('/signin');
//         } else {
//             // Continue with your component logic
//         }
//     }
// }
</script>