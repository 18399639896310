<template>
  <!-- <event-hub></event-hub> -->
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar" :class="$route.meta.sidebarClass">
    <div class="slimScrollDiv">
      <div class="sidebar-inner slimscroll">
        <perfect-scrollbar
          class="scroll-area"
          :settings="settings"
          @ps-scroll-y="scrollHanle"
        >
          <div id="sidebar-menu" class="sidebar-menu">
            <ul>
              <li class="submenu-open">
                <h6 class="submenu-hdr">Main</h6>
                <ul>
                  <li
                    v-if="hasPermission('view-dashboard')"
                    v-bind:class="{
                      active: currentPath == '/' || currentPath == '/',
                    }"
                  >
                    <router-link to="/"
                      ><vue-feather type="grid"></vue-feather
                      ><span> Tableau de bord</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="submenu-open" v-if="hasPermission('section-produit')">
                <h6 class="submenu-hdr">Produits</h6>
                <ul>
                  <li v-if="hasPermission('liste-produit')">
                    <router-link
                      :class="
                        currentPath == 'productlist' ||  currentPath == 'product-details'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/productlist"
                      ><vue-feather type="box"></vue-feather
                      ><span>Products</span></router-link
                    >
                  </li>
                  <li v-if="hasPermission('ajouter-produit')">
                    <router-link
                      :class="
                        currentPath == 'addproduct' || currentPath == 'editproduct'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/addproduct"
                      ><vue-feather type="plus-square"></vue-feather
                      ><span>Créer un produit</span></router-link
                    >
                  </li>
                  <li v-if="hasPermission('liste-categorie')">
                    <router-link
                      :class="
                        currentPath == 'categorylist' || currentPath == 'addcategory' || currentPath == 'editcategory' 
                        ? 'active' 
                        : 'notactive'"
                      to="/categorylist"
                      ><vue-feather type="codepen"></vue-feather
                      ><span>Catégories</span></router-link
                    >
                  </li>
                  <li v-if="hasPermission('liste-sous-categorie')">
                    <router-link
                      :class="
                        currentPath == 'subcategorylist' || currentPath == 'subaddcategory' || currentPath == 'editsubcategory'
                        ? 'active' 
                        : 'notactive'"
                      to="/subcategorylist"
                      ><vue-feather type="speaker"></vue-feather
                      ><span>Sous catégories</span></router-link
                    >
                  </li>
                  <li v-if="hasPermission('liste-unite')">
                    <router-link
                      :class="
                        currentPath == 'unitlist' || currentPath == 'addunit' || currentPath == 'editunit' 
                        ? 'active' 
                        : 'notactive'"
                      to="/unitlist"
                      ><vue-feather type="align-justify"></vue-feather
                      ><span>Unités</span></router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="submenu-open" v-if="hasPermission('section-achat')">
                <h6 class="submenu-hdr">Achats Fournisseurs</h6>
                <ul>
                  <li v-if="hasPermission('liste-achat')">
                    <router-link
                      :class="
                        currentPath == 'purchaselist' || currentPath == 'editpurchase' || currentPath == 'addpurchase' || currentpath == 'purchases-details'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/purchaselist"
                      ><vue-feather type="shopping-bag"></vue-feather
                      ><span>Liste achats</span></router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="submenu-open" v-if="hasPermission('section-commande')">
                <h6 class="submenu-hdr">Commandes clients</h6>
                <ul>
<!--                  <li v-if="hasPermission('liste-devis')">
                    <router-link
                      :class="
                        currentPath == 'quotationlist' || currentPath == 'editquotation' || currentPath == 'addquotation' || currentPath == 'quotations-details'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/quotationlist"
                      ><vue-feather type="save"></vue-feather
                      ><span>Devis</span></router-link
                    >
                  </li>-->
                  <li v-if="hasPermission('ajouter-commande')">
                    <router-link
                      :class="
                        currentPath == 'orderslist' || currentPath == 'add-orders' || currentPath == 'orders-details' || currentPath == 'orders-invoice'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/orderslist"
                      ><vue-feather type="monitor"></vue-feather
                      ><span>Commandes</span></router-link
                    >
                  </li>
                  <li v-if="hasPermission('liste-livraison')">
                    <router-link
                      :class="
                        currentPath == 'deliverylist' || currentPath == 'adddelivery' || currentPath == 'deliveries-details'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/deliverylist"
                      ><vue-feather type="archive"></vue-feather
                      ><span>Livraisons</span></router-link
                    >
                  </li>
                  <li v-if="hasPermission('consulter-livraison')">
                    <router-link
                      :class="
                        currentPath == 'consultdelivery'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/consultdelivery"
                      ><vue-feather type="truck"></vue-feather
                      ><span>Mes livraisons</span></router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="submenu-open" v-if="hasPermission('section-fournisseur')">
                <h6 class="submenu-hdr">Nos fournisseurs</h6>
                <ul>
                  <li v-if="hasPermission('liste-fournisseur')">
                    <router-link
                      :class="
                        currentPath == 'supplierlist' || currentPath == 'addsupplier' || currentPath == 'editsupplier'
                        ? 'active' 
                        : 'notactive'"
                      to="/supplierlist"
                      ><vue-feather type="users"></vue-feather
                      ><span>Fournisseurs</span></router-link
                    >
                  </li>

                </ul>
              </li>
              <li class="submenu-open" v-if="hasPermission('section-rapport')">
                <h6 class="submenu-hdr">Rapports</h6>
                <ul>
                  <li>
                    <router-link
                      :class="currentPath == 'salesreport' ? 'active' : 'notactive'"
                      to="salesreport"
                      ><vue-feather type="bar-chart-2"></vue-feather
                      ><span>Rapport des ventes</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="currentPath == 'purchasereport' ? 'active' : 'notactive'"
                      to="purchasereport"
                      ><vue-feather type="bar-chart"></vue-feather
                      ><span>Rapport d'achat</span></router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="submenu-open" v-if="hasPermission('section-utilisateur')">
                <h6 class="submenu-hdr">Gestion des utilisateurs</h6>
                <ul>
                  <li class="submenu">
                    <a
                      v-bind:class="userMenu ? 'active' : 'notactive'"
                      href="#sidebarUsers"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarUsers"
                      v-on:click="userMenu = !userMenu"
                      :class="{ subdrop: userMenu }"
                      ><vue-feather type="users"></vue-feather><span>Utilisateurs</span
                      ><span class="menu-arrow"></span
                    ></a>
                    <ul class="collapse menu-dropdown" id="sidebarUsers">
                      <li v-if="hasPermission('liste-utilisateur')">
                        <router-link
                          :class="
                            currentPath == 'newuser' || currentPath == 'newuseredit'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/newuser"
                          >Nouveau utilisateur
                        </router-link>
                      </li>
                      <li v-if="hasPermission('ajouter-utilisateur')">
                        <router-link
                          :class="currentPath == 'userlists' ? 'active' : 'notactive'"
                          to="/userlists"
                          >Liste utilisateurs</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="submenu-open" v-if="hasPermission('section-parametre')">
                <h6 class="submenu-hdr">Paramètres</h6>
                <ul>
                  <li class="submenu">
                    <a
                      v-bind:class="settingMenu ? 'active' : 'notactive'"
                      href="#sidebarSettings"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarSettings"
                      v-on:click="settingMenu = !settingMenu"
                      :class="{ subdrop: settingMenu }"
                      ><vue-feather type="settings"></vue-feather><span>Paramètres</span
                      ><span class="menu-arrow"></span
                    ></a>
                    <ul class="collapse menu-dropdown" id="sidebarSettings">
                      
                      <li v-if="hasPermission('liste-mode-paiement')">
                        <router-link
                          :class="
                            currentPath == 'paymentsettings' || currentPath == 'addpaymentsettings' || currentPath == 'editpaymentsettings'
                            ? 'active' 
                            : 'notactive'
                          "
                          to="/paymentsettings"
                          >Paiements</router-link
                        >
                      </li>
                      <li v-if="hasPermission('liste-devise')">
                        <router-link
                          :class="
                            currentPath == 'currencylist' ||  currentPath == 'addcurrency' ||  currentPath == 'editcurrency'
                            ? 'active' 
                            : 'notactive'
                          "
                          to="/currencylist"
                          >Devises</router-link
                        >
                      </li>
                      <li v-if="hasPermission('liste-permission')">
                        <router-link
                          :class="
                            currentPath == 'grouppermissions' ||
                            currentPath == 'createpermission' ||
                            currentPath == 'editpermission'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/grouppermissions"
                          >Autorisations</router-link
                        >
                      </li>
                      <li v-if="hasPermission('liste-role')">
                        <router-link
                          :class="
                            currentPath == 'rolelist' ||
                            currentPath == 'createrole' ||
                            currentPath == 'editrole'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/rolelist"
                          >Rôles</router-link
                        >
                      </li>
                      <li v-if="hasPermission('liste-secteur-activite')">
                        <router-link
                          :class="currentPath == 'activitylist' || currentPath == 'addactivity' || currentPath == 'editactivity'
                          ? 'active' 
                          : 'notactive'"
                          to="/activitylist"
                          >Secteurs d'activité</router-link
                        >
                      </li>
                      <li v-if="hasPermission('liste-zone')">
                        <router-link
                          :class="currentPath == 'zone-list' || currentPath == 'edit-zone' || currentPath == 'add-zone'
                          ? 'active' 
                          : 'notactive'"
                          to="/zone-list"
                          >Zones</router-link
                        >
                      </li>
                      <!-- <li>
                        <router-link
                          :class="currentPath == 'emailsettings' ? 'active' : 'notactive'"
                          to="emailsettings"
                          >Mail Configuration</router-link
                        >
                      </li> -->
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
  <!-- /Sidebar -->
</template>
<script setup>
import { onMounted} from 'vue';
import usePermissions from '@/composables/usePermissions';
const { loadUserPermissions, hasPermission } = usePermissions();
onMounted(() => {
  loadUserPermissions()
})
</script>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  data() {
    return {
      productMenu: false,
      salesMenu: false,
      purchaseMenu: false,
      // expenseMenu: false,
      quotationMenu: false,
      transferMenu: false,
      returnMenu: false,
      peopleMenu: false,
      placesMenu: false,
      errorpageMenu: false,
      reportMenu: false,
      userMenu: false,
      settingMenu: false,
      deliveryMenu: false,
      orderMenu: false,
      // elementMenu: false,
      // chartMenu: false,
      // iconMenu: false,
      // formMenu: false,
      // tableMenu: false,
      // chatMenu: false,
      authenticationPageMenu: false,
      settings: {
        suppressScrollX: true,
      },
      activeClass: "active",
    };
    //  isactive : true
  },

  methods: {
    scrollHanle(evt) {},
    isCustomDropdown() {
      //Search bar
      var searchOptions = document.getElementById("search-close-options");
      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");

      searchInput.addEventListener("focus", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchInput.addEventListener("keyup", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchOptions.addEventListener("click", () => {
        searchInput.value = "";
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      });

      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#sidebar")) {
          let a = document
            .querySelector("#sidebar")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
  components: {
    PerfectScrollbar,
  },

  computed: {
    currentPath() {
      return this.$route.name;
    },
    productMenu() {
      return (
        this.$route.name == "productlist" ||
        this.$route.name == "product-details" ||
        this.$route.name == "addproduct" ||
        this.$route.name == "editproduct" ||
        this.$route.name == "editcategory" ||
        this.$route.name == "categorylist" ||
        this.$route.name == "addcategory" ||
        this.$route.name == "editsubcategory" ||
        this.$route.name == "subcategorylist" ||
        this.$route.name == "subaddcategory" ||
        this.$route.name == "brandlist" ||
        this.$route.name == "editbrand" ||
        this.$route.name == "addbrand" ||
        this.$route.name == "unitlist" ||
        this.$route.name == "addunit" ||
        this.$route.name == "editunit"

      );
    },
    salesMenu() {
      return (
        this.$route.name == "saleslist" ||
        this.$route.name == "sales-details" ||
        this.$route.name == "pos" ||
        this.$route.name == "add-sales" ||
        this.$route.name == "editsalesreturns" ||
        this.$route.name == "edit-sales" ||
        this.$route.name == "salesreturnlists" ||
        this.$route.name == "createsalesreturns"
      );
    },
    purchaseMenu() {
      return (
        this.$route.name == "purchaselist" ||
        this.$route.name == "addpurchase" ||
        this.$route.name == "editpurchase" ||
        this.$route.name == "importpurchase"
      );
    },
    // expenseMenu() {
    //   return (
    //     this.$route.name == "expenselist" ||
    //     this.$route.name == "createexpense" ||
    //     this.$route.name == "editexpense" ||
    //     this.$route.name == "expensecategory"
    //   );
    // },
    quotationMenu() {
      return (
        this.$route.name == "quotationlist" ||
        this.$route.name == "addquotation" ||
        this.$route.name == "editquotation"
      );
    },
    transferMenu() {
      return (
        this.$route.name == "transferlist" ||
        this.$route.name == "addtransfer" ||
        this.$route.name == "edittransfer" ||
        this.$route.name == "importtransfer"
      );
    },
    returnMenu() {
      return (
        this.$route.name == "salesreturnlist" ||
        this.$route.name == "editsalesreturn" ||
        this.$route.name == "createsalesreturn" ||
        this.$route.name == "createpurchasereturn" ||
        this.$route.name == "editpurchasereturn"
      );
    },
    peopleMenu() {
      return (
        this.$route.name == "customerlist" ||
        this.$route.name == "addcustomer" ||
        this.$route.name == "editcustomer" ||
        this.$route.name == "supplierlist" ||
        this.$route.name == "editsupplier" ||
        this.$route.name == "addsupplier" 
      
      );
    },
    placesMenu() {
      return (
        this.$route.name == "newcountry" ||
        this.$route.name == "editcountry" ||
        this.$route.name == "countrieslist" ||
        this.$route.name == "editstate" ||
        this.$route.name == "newstate" ||
        this.$route.name == "statelist"
      );
    },
    errorpageMenu() {
      return this.$route.name == "error-404" || this.$route.name == "error-500";
    },
    reportMenu() {
      return (
        this.$route.name == "purchaseorderreport" ||
        this.$route.name == "inventoryreport" ||
        this.$route.name == "salesreport" ||
        this.$route.name == "invoicereport" ||
        this.$route.name == "purchasereport" ||
        this.$route.name == "supplierreport" ||
        this.$route.name == "customerreport"
      );
    },
    userMenu() {
      return (
        this.$route.name == "newuser" ||
        this.$route.name == "userlists" ||
        this.$route.name == "newuseredit"
      );
    },
    settingMenu() {
      return (
        this.$route.name == "generalsettings" ||
        this.$route.name == "emailsettings" ||
        this.$route.name == "editpermission" ||
        this.$route.name == "createpermission" ||
        this.$route.name == "paymentsettings" ||
        this.$route.name == "currencylist" ||
        this.$route.name == "grouppermissions" ||
        this.$route.name == "taxrates" ||
        this.$route.name == "addcurrency" ||
        this.$route.name == "editcurrency" ||
        this.$route.name == "addpaymenttype"||
        this.$route.name == "editpaymenttype"||
        this.$route.name == "activitylist"||
        this.$route.name == "edit-zone" ||
        this.$route.name == "zone-list" ||
        this.$route.name == "add-zone"  ||
        this.$route.name == "editrole" ||
        this.$route.name == "createrole" ||
        this.$route.name == "rolelist" 

      );
    },
    deliveryMenu() {
      return (
        this.$route.name == "deliverylist" ||
        this.$route.name == "adddelivery"
      );
    },

    orderMenu() {
      return (
        this.$route.name == "orderslist" ||
        this.$route.name == "add-order" ||
        this.$route.name == "edit-order" ||
        this.$route.name == "view-order"
      );
    },

    // elementMenu() {
    //   return (
    //     this.$route.name == "sweetalerts" ||
    //     this.$route.name == "tooltip" ||
    //     this.$route.name == "popover" ||
    //     this.$route.name == "ribbon" ||
    //     this.$route.name == "clipboard" ||
    //     this.$route.name == "drag-drop" ||
    //     this.$route.name == "rangeslider" ||
    //     this.$route.name == "rating" ||
    //     this.$route.name == "toastr" ||
    //     this.$route.name == "text-editor" ||
    //     this.$route.name == "counter" ||
    //     this.$route.name == "scrollbar" ||
    //     this.$route.name == "spinner" ||
    //     this.$route.name == "notification" ||
    //     this.$route.name == "lightbox" ||
    //     this.$route.name == "stickynote" ||
    //     this.$route.name == "timeline" ||
    //     this.$route.name == "form-wizard"
    //   );
    // },
    // chartMenu() {
    //   return (
    //     this.$route.name == "chart-apex" ||
    //     this.$route.name == "chart-js" ||
    //     this.$route.name == "chart-morris" ||
    //     this.$route.name == "chart-flot"
    //   );
    // },
    // iconMenu() {
    //   return (
    //     this.$route.name == "icon-fontawesome" ||
    //     this.$route.name == "icon-feather" ||
    //     this.$route.name == "icon-ionic" ||
    //     this.$route.name == "icon-material" ||
    //     this.$route.name == "icon-pe7" ||
    //     this.$route.name == "icon-simpleline" ||
    //     this.$route.name == "icon-themify" ||
    //     this.$route.name == "icon-weather" ||
    //     this.$route.name == "icon-typicon" ||
    //     this.$route.name == "icon-flag"
    //   );
    // },
    // formMenu() {
    //   return (
    //     this.$route.name == "form-basic-inputs" ||
    //     this.$route.name == "form-input-groups" ||
    //     this.$route.name == "form-horizontal" ||
    //     this.$route.name == "form-vertical" ||
    //     this.$route.name == "form-mask" ||
    //     this.$route.name == "form-validation" ||
    //     this.$route.name == "form-select2" ||
    //     this.$route.name == "form-fileupload"
    //   );
    // },
    // tableMenu() {
    //   return this.$route.name == "tables-basic" || this.$route.name == "data-tables";
    // },
    // chatMenu() {
    //   return (
    //     this.$route.name == "chat" ||
    //     this.$route.name == "calendar" ||
    //     this.$route.name == "email"
    //   );
    // },
    currentPath() {
      return this.$route.name;
    },
  },
};
</script>
