import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function usePermissions() {
    const permissions = ref([])
    const permission = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);

    const getPermissions = async () => {
        let response = await axios.get('/permissions')
        permissions.value = response.data.data;
    }

    const getPermission = async (id) => {
        let response = await axios.get('/permissions/'+id)
        permission.value = response.data.data;
    }

    const storePermission = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            await axios.post('/permissions/', data)
            await router.push({name: 'grouppermissions'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const updatePermission = async (id) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.put('/permissions/' +id, permission.value)
            await router.push({name: 'grouppermissions'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors

                console.log(e.response.data.errors);
                loading.value = false;
            }
        }
    }

    const destroyPermission = async (id) => {
        await axios.delete('/permissions/' + id)
    }

    return {
        permissions,
        permission,
        errors,
        getPermissions,
        getPermission,
        storePermission,
        updatePermission,
        destroyPermission,
        loading,
    }
}