import { ref } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useDeliveries() {
    const deliveries = ref([])
    const delivery = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);

    const getDeliveries = async () =>{
        let response = await axios.get('/deliveries')
        deliveries.value = response.data.data
    }


    const getDelivery = async (id) =>{
        let response = await axios.get('/view-delivery/'+id)
        delivery.value = response.data.data
    }


    const deleteDelivery = async (id) => {
        let response = await axios.delete('/delete-delivery/'+id)
    }

    const getDeliveriesForDeliveryPerson = async () =>{
        let response = await axios.get('/consult-delivery')
        deliveries.value = response.data.data
    }


    const storeOrderDelivery = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            let response = await axios.post('/store-delivery/', data)
            await router.push({name: 'deliverylist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }




    const markDelivered = async(id) => {
        try {
            let response = await axios.put('/mark-delivered/'+ id)
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const sendDelivered = async(id) => {
        try {
            let response = await axios.put('/send-delivered/'+ id)
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const cancelDelivered = async(id) => {
        try {
            let response = await axios.put('/cancel-delivered/'+ id)
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }


    return {
        deliveries,
        getDeliveries,
        getDelivery,
        delivery,
        deleteDelivery,
        getDeliveriesForDeliveryPerson,
        storeOrderDelivery,
        markDelivered,
        sendDelivered,
        cancelDelivered
    }
}