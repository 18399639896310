import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useCategories() {
    const categories = ref([])
    const category = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);


    const getCategories = async () => {
        let response = await axios.get('/categories')
        categories.value = response.data.data;
    }


    const selectCategories = async () => {
        let response = await axios.get('/category-select')
        categories.value = response.data.data;
    }

    const getCategory = async (id) => {
        let response = await axios.get('/categories/' + id)
        category.value = response.data.data;
    }

    const storeCategory = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            let response = await axios.post('/categories/', data)
            await router.push({name: 'categorylist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const updateCategory = async (id, data, config) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.post('/categories/' +id, data, config)
            await router.push({name: 'categorylist'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false;
            }
        }
    }

    const destroyCategory = async (id) => {
        await axios.delete('/categories/' + id)
    }

    return {
        categories,
        category,
        errors,
        getCategories,
        getCategory,
        storeCategory,
        updateCategory,
        destroyCategory,
        selectCategories,
        loading
    }
}