import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function usePayments() {

    const payments = ref([])
    const payment = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);

    const getPayments = async () => {
        let response = await axios.get('/paymenttypes')
        payments.value = response.data.data;
    }


    const getPayment = async (id) => {
        let response = await axios.get('/paymenttypes/'+id)
        payment.value = response.data.data;
    }

    const storePayment = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            await axios.post('/paymenttypes/', data)
            await router.push({name: 'paymentsettings'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const updatePayment = async (id) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.put('/paymenttypes/' +id, paymentType.value)
            await router.push({name: 'paymentsettings'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors

                console.log(e.response.data.errors);
                loading.value = false;
            }
        }
    }

    const deletePayment = async (id) => {
        await axios.delete('/paymenttypes/' + id)
    }

    const updateStatusPayment = async (id) => {
        let response = await axios.put('/update-status-payment/' + id)

        console.log(response);
    }

    return {
        getPayments,
        getPayment,
        payments,
        payment,
        errors,
        storePayment,
        updatePayment,
        deletePayment,
        loading,
        updateStatusPayment,
    }
}