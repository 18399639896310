import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useSubCategories() {
    const subcategories = ref([])
    const subcategory = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false)
    const selected = ref()

    const getSubCategories = async () => {
        let response = await axios.get('/subcategories')
        subcategories.value = response.data.data;
    }

    const getSubCategory = async (id) => {
        let response = await axios.get('/subcategories/' + id)
        subcategory.value = response.data.data;
        selected.value = subcategory.value.category_id;

        console.log(subcategory.value);
    }

    const selectSubCategoryByCategory = async (id) => {
        let response = await axios.get('/categories/' + id + '/subcategories')
        subcategories.value = response.data.data;
    }

    const subCategoryByProduitCategory = async(id) => {
        let response = await axios.get('/category/products/' + id + '/subcategories')
        subcategories.value = response.data.data;
    }

    const storeSubCategory = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            await axios.post('/subcategories/', data)
            await router.push({name: 'subcategorylist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const updateSubCategory = async (id, data) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.put('/subcategories/' +id, data)
            await router.push({name: 'subcategorylist'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false;
            }
        }
    }

    const destroySubCategory = async (id) => {
        await axios.delete('/subcategories/' + id)
    }

    return {
        subcategories,
        subcategory,
        errors,
        getSubCategories,
        getSubCategory,
        storeSubCategory,
        updateSubCategory,
        destroySubCategory,
        selectSubCategoryByCategory,
        loading,
        selected,
        subCategoryByProduitCategory
    }
}