<template>
  <div class="error-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div class="error-box">
        <h1>500</h1>
        <h3 class="h2 mb-3">
          <i class="fas fa-exclamation-circle"></i> Oops, Erreur interne du serveur
        </h3>
        <p class="h4 font-weight-normal">Essayez de rafraîchir la page ou n'hésitez pas à nous contacter si le problème persiste.</p>
        <router-link to="/" class="btn btn-primary">Retour accueil</router-link>
      </div>
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  name: "error-500",
};
</script>
