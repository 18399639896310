<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                      ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                      ><img src="../../../assets/img/icons/excel.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                      ><img src="../../../assets/img/icons/printer.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="table-responsive">
              <a-table
                :columns="columns"
                :data-source="categories"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id'">
                    <div class="productimgname">
                      <!-- <a href="javascript:void(0);" class="product-img">
                        <img
                          :src="record.image_url"
                          alt="product"
                        />
                      </a> -->
                      <a href="javascript:void(0);">{{ record.name }}</a>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <router-link v-if="hasPermission('modifier-categorie')" class="me-3" :to="{ name: 'editcategory', params: { id: record.id } }">
                      <img src="../../../assets/img/icons/edit.svg" alt="img" />
                    </router-link>
                    <a
                    v-if="hasPermission('supprimer-categorie')"
                      class="me-3 confirm-text"
                      href="javascript:void(0);"
                      @click="deleteRecord(record.id)"
                    >
                      <img src="../../../assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>
</template>

<script setup>

import useCategories from '@/composables/categories'
import usePermissions from "@/composables/usePermissions"
import { onMounted } from 'vue';

const { categories, getCategories, destroyCategory } = useCategories()
const { loadUserPermissions, hasPermission } = usePermissions()


const deleteRecord = (id)  =>{
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Voulez‑vous vraiment supprimer cet enregistrement?",
    showCancelButton: true,
    confirmButtonColor: "#FF9F43",
    cancelButtonColor: "#d33",
    confirmButtonText: "Oui, supprimer!",
    cancelButtonText: "Non, Annuler!",
  }).then(async(result) => {
    if (result.isConfirmed) {
      await destroyCategory(id)
      await getCategories()
      Swal.fire("Supprimer!", "Vous avez supprimé !.");
    }
  });
}

onMounted(() => {
  loadUserPermissions()
  getCategories()
})

</script>
<script>

const columns = [
  {
    sorter: true,
  },
  {
    title: "LIBELLÉ",
    dataIndex: "name",
    key: "id",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "DATE DE CRÉATION",
    dataIndex: "created_at",
    sorter: (a, b) => a.created_at.localeCompare(b.created_at),
  },
  // {
  //   title: "AUTEUR",
  //   dataIndex: "auteur",
  //   sorter: (a, b) => a.auteur.localeCompare(b.auteur),
  // },
  {
    title: "ACTION",
    key: "action",
    sorter: false,
  },
];
export default {
  data() {
    return {
      filter: false,
      title: "Liste des catégories de produits",
      title1: "Gestion des catégories",
      items: [
        {
          text: "Ajouter une catégorie",
          addname: "/addcategory",
        },
      ],
      columns,
    };
  },


  methods: {
  },
  name: "categorylist",
};
</script>
<style>
.ant-tooltip {
  display: none !important;
}
</style>
