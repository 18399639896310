<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <form @submit.prevent="handleSubmit">
          <!-- /add -->
          <div class="card">
            <div class="card-body">
              <div class="row">

                <div class="col-md-6">
                  <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Nom complet</label>
                          <input type="text" name="full_name" v-model="form.full_name" autocomplete="off" />
                          <span class="text-danger" v-if="errors?.full_name">{{ errors.full_name[0] }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Mobile</label>
                          <input type="text" name="personal_phone" v-model="form.personal_phone" />
                        </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Email</label>
                          <input type="text" name="email" v-model="form.email" autocomplete="off"/>
                          <span class="text-danger" v-if="errors?.email">{{ errors.email[0] }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Rôle</label>
                          <vue-select :options="roles" name="role" v-model="form.role" placeholder="Select" />
                          <span class="text-danger" v-if="errors?.role">{{ errors.role[0] }}</span>
                        </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Mot de passe</label>
                          <div class="pass-group">
                            <input
                              v-if="showPassword"
                              type="text"
                              class="pass-input"
                              name="password"
                              v-model="form.password"
                              autocomplete="new-password"
                            />
                            <input v-else type="password" class="pass-input" name="password" v-model="form.password" autocomplete="new-password"/>
                            <span
                              class="fas toggle-password"
                              @click="toggleShow"
                              :class="{
                                'fa-eye': showPassword,
                                'fa-eye-slash': !showPassword,
                              }"
                            ></span>
                          </div>
                          <span class="text-danger" v-if="errors?.password">{{ errors.password[0] }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Confirmez le mot de passe</label>
                          <div class="pass-group">
                            <input
                              v-if="showPassword1"
                              type="text"
                              class="pass-input"
                              v-model="form.password_confirmation"
                              name="password_confirmation"
                              autocomplete="new-password"
                            />
                            <input
                              v-else
                              type="password"
                              class="pass-input"
                              v-model="form.password_confirmation"
                              name="password_confirmation"
                              autocomplete="new-password"
                            />
                            <span
                              class="fas toggle-password"
                              @click="toggleShow1"
                              :class="{
                                'fa-eye': showPassword1,
                                'fa-eye-slash': !showPassword1,
                              }"
                            ></span>
                          </div>
                          <span class="text-danger" v-if="errors?.password_confirmation">{{ errors.password_confirmation[0] }}</span>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
                <div class="col-lg-12">
                  <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                    <div v-if="loading" class="btn-content">
                      <b-spinner small type="grow"></b-spinner>
                      <span class="ms-2">Veuillez patienter...</span>
                    </div>

                    <div v-else class="btn-content">Enregistrer</div>
                  </button>
                </div>
                </div>
            </div>
          </div>
          <!-- /add -->
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, reactive } from 'vue';
import useRoles  from '@/composables/roles'
import useUsers from '@/composables/users'


const { roles, selectRoles } = useRoles()

const { loading, errors, storeUser } = useUsers()


onMounted(() => {
  selectRoles()
})


const form = reactive({
  'full_name':'',
  'email':'',
  'personal_phone':'',
  'role': [],
  'password_confirmation':'',
  'password':''
})

const handleSubmit = async () => {
  await storeUser({...form})
}
</script>
<script>

export default {
  data() {
    return {
      title: "Utilisateurs",
      title1: "Ajouter/mettre à jour un utilisateur",
      showPassword: false,
      showPassword1: false,
      password: null,
      password1: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
  },

  name: "newuser",
};
</script>
