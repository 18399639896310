import { createStore } from 'vuex'
import axios from 'axios'


const store = createStore({
  state: {
    status: null,
    token: localStorage.getItem('token') || null,
    fullname: localStorage.getItem('fullname') || null,
    user : {},
    error : null,
    isAuthenticated:false,
    permissions: [],
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token){
      state.status = 'success'
      state.token = token
    },
    set_user(state,user){
      state.user = user
    },
    handle_error(state,error){
      state.error = error
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
    setAuthentication(state, value) {
      state.isAuthenticated = value;
    },

    setUserPermission(state, value) {
      state.permissions = value;
    },
  },
  actions: {
    login({commit}, user){
        return new Promise((resolve, reject) => {
          commit('auth_request')
          axios.post('signin',user)
          .then(resp => {
            const token = 'Bearer '+resp.data.access_token
            const user = resp.data.user
            localStorage.setItem('token', token)
            localStorage.setItem('fullname', user)
            axios.defaults.headers.common['Authorization'] = token
            commit('auth_success', token)
            commit('set_user',user)
            commit('handle_error', '')
            commit('setAuthentication',true)
            resolve(resp)
          })
          .catch(err => {
            localStorage.removeItem('token')
            reject(err)
          })
        })
    },
    Register({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: 'signup', data: user, method: 'POST' })
        .then(resp => {
          const token = 'Bearer '+resp.data.access_token
          const user = resp.data.user
          localStorage.setItem('token', token)
          localStorage.setItem('username', user)
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', token, user)
          commit('handle_error', '')
          resolve(resp)
        })
        .catch(error => {
          localStorage.removeItem('token')
          reject(error)
        })
      })
    },
    logout({commit}){
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('fullname')
        delete axios.defaults.headers.common['Authorization']
        commit('setAuthentication',false)
        resolve()
      })
    },

    // getUser({commit}){
    //   return new Promise((resolve, reject) => {
    //     axios.get('/user').then(res =>{
    //       commit('set_user',res.data)
    //       console.log(res.data);
    //       resolve(res)
    //     })
    //   }); 
    // },

    // getAuthPermission({commit}){
    //   return new Promise((resolve, reject) => {
    //     axios.get('auth-permissions').then(res =>{
    //       commit('setUserPermission',res.data.data)
    //       resolve(res)
    //     })
    //   }); 
    // }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    // getUser: state=> state.user,
    getError: state=> state.error,
    isAuthenticated: state=> state.setAuthentication,
  }
  })

export default store;