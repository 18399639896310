<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar></sidebar>
      <div class="page-wrapper">
        <div class="content">
          <pageheader :title="title" :title1="title1" />
          <form @submit.prevent="onSubmit" novalidate>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label> Nom unité</label>
                      <input type="text" name="name" id="name" v-model="form.name" />
                      <span class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Code unité</label>
                      <input type="text" name="code" v-model="form.code" />
                    </div>
                  </div>
  
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Description</label>
                      <textarea
                        id="textarea"
                        v-model="form.comment"
                        name="comment"
                        rows="3"
                        max-rows="6"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-lg-12">
  
                    <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                      <div v-if="loading" class="btn-content">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="ms-2">Veuillez patienter...</span>
                      </div>
  
                      <div v-else class="btn-content">Enregistrer</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  <script setup>
  import useUnits from '@/composables/units'
  import { reactive } from 'vue'
  
  const form = reactive({
      name: '',
      code: '',
      comment: '',
  })
  const { errors, storeUnit, loading } = useUnits()
  
  const onSubmit = async () => {

    await storeUnit(form)
  }
  
  </script>
  <script>
  export default {
    data() {
      return {
        title: "Unités",
        title1: "Ajouter / Modifier une unité",
      };
    },
    name: "addunit",
  };
  </script>
  