<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                <label>Boutiques</label>
                  <v-select
                    v-model="form.warehouse_id"
                    :reduce="(option) => option.id"
                    :options="warehouses"
                    placeholder="Rechercher une boutique"
                    :dropdown-should-open="dropdownShouldOpen"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Date d'achat</label>
                  <VueDatePicker v-model="form.order_date" name="order_date"  :format="format" :format-locale="fr"></VueDatePicker>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Date prévue de la livraison</label>
                  <VueDatePicker v-model="form.expected_delivery_date" name="expected_delivery_date"  :format="format" :format-locale="fr"></VueDatePicker>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>N° facture</label>
                  <input type="text" v-model="form.number" name="number" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Commentaire</label>
                  <textarea
                      class="form-control"
                      name="note"
                      v-model="form.note"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-12 col-sm-6 col-12">
                <div class="auto-complete-product-container">
                    <div class="form-group">
                      <label>Nom du produit</label>
                        <div class="input-groupicon">
                          <input
                            type="text"
                            placeholder="Scannez/recherchez le produit par code et sélectionnez..."
                            v-model='searchText'
                            @keyup='loadSuggestions()'
                          />
                          <div class="addonset">
                            <img src="../../../assets/img/icons/scanners.svg" alt="img" />
                          </div>
                        </div>
                        <div class='customer-search-suggestion' v-if="suggestiondata.length" >
                            <div class="customer-search-suggestion-inner">
                              <ul> 
                                  <li v-for= '(item,i) in suggestiondata' @click='addCart(item)' :key="item.id"> 
                                      {{ item.designation }} 
                                  </li>  
                              </ul>
                            </div>
                        </div>  
                    </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Nom du produit</th>
                      <th>Qté</th>
                      <th>Prix ​​de vente</th>
                      <th>Remise %</th>
                      <th>TVA %</th>
                      <th>Montant TTC</th>
                      <th class="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(itemcart, i) in form.order_items" :key="itemcart.id">
                      <span>

                      </span>
                      <td class="productimgname">
                        <span v-if="itemcart.product">
                          <a href="javascript:void(0);">{{ itemcart.product.designation }}</a>
                        </span>
                        <span v-else>
                          <a href="javascript:void(0);">{{ itemcart.designation }}</a>
                        </span>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.quantity"
                          name="number"
                          required
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="itemcart.unit_price"
                          name="number"
                          readonly
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.product_discount"
                          name="number"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.product_tax"
                          name="number"
                        />
                      </td>
                      <td class="text-end">
                          {{ (itemcart.quantity * itemcart.unit_price) - (itemcart.quantity * itemcart.unit_price * itemcart.product_discount / 100) +  (itemcart.quantity * itemcart.unit_price * itemcart.product_tax / 100)}}
                      </td>
                      <td>
                        <a class="delete-set" @click="deleteInvoiceItems(itemcart.id, i)"
                          ><img src="../../../assets/img/icons/delete.svg" alt="svg"
                        /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="row">
                <div class="col-lg-12 float-md-right">
                  <div class="total-order">
                    <ul>
                      <li>
                        <h4>TOTAL HT</h4>
                        <h5>{{ SubTotal() }}</h5>
                      </li>
                      <li>
                        <h4>TOTAL REMISE (-)</h4>
                          <h5>{{ TotalDiscount() }}</h5>
                      </li>
                      <li>
                        <h4>TOTAL TVA (+)</h4>
                        <h5>{{ TotalTax() }}</h5>
                      </li>
                      
                      <li class="total">
                        <h4>TOTAL TTC</h4>
                        <h5>{{ Total() }}</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <button class="btn btn-primary btn-md" type="submit" :disabled="loading" @click="handlerSubmit">
                    <div v-if="loading" class="btn-content">
                      <b-spinner small type="grow"></b-spinner>
                      <span class="ms-2">Veuillez patienter...</span>
                    </div>

                    <div v-else class="btn-content">Enregistrer</div>
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import axios from "axios"
import { ref, onMounted } from "vue";
import useWarehouses from '@/composables/warehouses' ;
import { fr } from 'date-fns/locale';
import { useRouter } from "vue-router";
const errors = ref('')

const { warehouses, selectShop } = useWarehouses();

const loading = ref(false)
const router = useRouter();
let searchText = ref('')
let suggestiondata = ref([])

let form = ref({
  id:''
})

const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

const addCart = (item) => {

  console.log(item);
  searchText.value = ''
  suggestiondata.value =''

  let product_check = form.value.order_items.find((product) => product.product_id === item.id);

  
  if (product_check) {
    product_check.quantity++;
  } else {
    const itemcart = {
      product_id: item.id,
      designation: item.designation,
      unit_price: item.price,
      quantity: 1,
      product_tax: 0,
      product_discount: 0
    }
    form.value.order_items.push(itemcart)
  }
 
}


const SubTotal = () => {
  let total = 0

  if (form.value.order_items) {

    form.value.order_items.map((data) =>{
      total = total + (data.quantity*data.unit_price)
    })
  }

  return total
}

const TotalDiscount = () => {
  let total = 0;
  if (form.value.order_items) {
    form.value.order_items.map((data) => {
      total = total + (data.quantity * data.unit_price * data.product_discount / 100);
    });
  }
  return total;
};

const TotalTax = () => {
  let total = 0;

  if (form.value.order_items) {
    form.value.order_items.map((data) => {
      total = total +  (data.quantity * data.unit_price * data.product_tax / 100);
    });
  }
  return total;
};

const Total = () => {

  if (form.value.order_items) {
    return SubTotal() - TotalDiscount() + TotalTax()
  }
}


const loadSuggestions = async () =>{
  let response = await axios.get('/product-search?s='+searchText.value)
  suggestiondata.value = response.data
}


onMounted( async () => {
   getInvoice()
   selectShop()
})

const date = ref(new Date());
// In case of a range picker, you'll receive [Date, Date]
const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

const getInvoice = async () => {
  let response = await axios.get('/view-invoice/'+props.id)
  console.log('form', response.data.data);

  form.value = response.data.data
}

const deleteInvoiceItems = (id,i) => {

  form.value.order_items.splice(i,1);

  if (id != undefined) {

    let response = axios.delete('/delete-invoice-items/' + id)

  }
}

const handlerSubmit = async () => {
  if (form.value.order_items.length >= 1){

    let subtotal =0
    subtotal = SubTotal()

    let total = 0
    total = Total()

       
    let totaldiscount = 0;
    totaldiscount = TotalDiscount();


    let totaltax = 0;
    totaltax = TotalTax();

      const formData = new FormData()

      formData.append('order_items', JSON.stringify(form.value.order_items))

      formData.append('warehouse_id', form.value.warehouse_id)
      formData.append('order_date', form.value.order_date)
      formData.append('expected_delivery_date', form.value.expected_delivery_date)
      formData.append('number', form.value.number)
      formData.append("totaldiscount", totaldiscount);
      formData.append("totaltax", totaltax);
      formData.append('subtotal', subtotal)
      formData.append('total', total)
      formData.append('_method', 'PUT');
      

      errors.value = ''
      loading.value = true
      try {
          let response = await axios.post("/update-invoice/"+form.value.id, formData)
          await router.push({name: 'orderslist'})
      } catch (e) {

          if (e.response.status === 422) {
              errors.value = e.response.data.errors
              loading.value = false
          }
      }
  }
}

</script>
<script>
export default {
  data() {
    return {
      title: "Commande client",
      title1: "Modifier/Ajouter une commande",
      Status: [{id: "0", text: "En cours"}, {id: "1", text: "Livré"}],
    };
  },
  name: "edit-order",
};
</script>
