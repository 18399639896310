import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useProducts() {
    const products = ref([])
    const product = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);

    const getProducts = async () => {
        let response = await axios.get('/products')
        products.value = response.data.data;
    }

    const getProduct = async (id) => {
        let response = await axios.get('/products/'+id)
        product.value = response.data.data;
    }

    const viewProduct = async (id) => {
        let response = await axios.get('/products/view/'+id)
        product.value = response.data.data;
    }

    const storeProduct = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            let response = await axios.post('/products/', data)
            await router.push({name: 'productlist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const updateProduct = async (id, data, config) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.post('/products/' +id, data, config)
            await router.push({name: 'productlist'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false;
            }
        }
    }

    const destroyProduct = async (id) => {
        await axios.delete('/products/' + id)
    }

    return {
        products,
        product,
        errors,
        getProducts,
        getProduct,
        storeProduct,
        updateProduct,
        destroyProduct,
        loading,
        viewProduct,
    }
}