<template>
    <div class="error-page">
      <!-- Main Wrapper -->
      <div class="main-wrapper">
        <div class="error-box">
          <h1>403</h1>
          <h3 class="h2 mb-3">
            <i class="fas fa-exclamation-circle"></i> Interdit
          </h3>
          <p class="h4 font-weight-normal">Oops, Vous n'êtes pas autorisé à accéder à la page que vous recherchez.</p>
          <router-link to="/" class="btn btn-primary">Retour page accueil</router-link>
        </div>
      </div>
      <!-- /Main Wrapper -->
    </div>
  </template>
  <script>
  export default {
    name: "error-403",
  };
  </script>
  