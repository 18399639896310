<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar></sidebar>
      <div class="page-wrapper">
        <div class="content">
          <pageheader :title="title" :title1="title1" />
  
          <!-- /add -->
          <form
          @submit.prevent="onSubmit" novalidate
          >
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label> Nom unité</label>
                      <input type="text" name="name" v-model="unit.name" />
                      <span class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Code de unité</label>
                      <input type="text" name="code" v-model="unit.code" />
                    </div>
                  </div>
  
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Description</label>
                      <textarea
                        id="textarea"
                        v-model="unit.comment"
                        name="comment"
                        rows="3"
                        max-rows="6"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-lg-12">
  
                    <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                      <div v-if="loading" class="btn-content">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="ms-2">Veuillez patienter...</span>
                      </div>
  
                      <div v-else class="btn-content">Enregistrer</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- /add -->
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import useUnits from '@/composables/units'
  import { onMounted } from 'vue';

  const { errors, getUnit, updateUnit, unit, loading } = useUnits()
  
  const props = defineProps({
      id: {
          required: true,
          type: String
      }
  })
  
  onMounted(() => getUnit(props.id))
  
  const onSubmit = async() =>{
    await updateUnit(props.id)
  }
  </script>
  <script>
  export default {
    data() {
      return {
        filter: true,
        title: "Unités",
        title1: "Ajouter/Modifier unités",
      };
    },
    name: "editcategory",
  };
  </script>
  