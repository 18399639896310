import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router';

export default function useRoles() {
    const roles = ref([])
    const role = ref([])
    const roleHasPermission = ref([])
    const router = useRouter()
    const errors = ref('')
    const loading = ref(false);

    const getRoles = async () => {
        let response = await axios.get('/roles')
        roles.value = response.data.data;
    }

    const getRole = async (id) => {
        let response = await axios.get('/roles/'+id)
        role.value = response.data.data.role.name;
        roleHasPermission.value = response.data.data.rolePermission;
    }

    const storeRole = async (data) => {
        errors.value = ''
        loading.value = true
        try {
            let response = await axios.post('/roles/', data)
            await router.push({name: 'rolelist'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
                loading.value = false
            }
        }
    }

    const selectRoles = async () => {
        let response = await axios.get('/select-role')
        roles.value = response.data.data;
    }


    const updateRole = async (id, data) => {
        errors.value = ''
        loading.value = true;
        try {
            let response = await axios.put('/roles/' +id, data)
            await router.push({name: 'rolelist'})
            
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors

                console.log(e.response.data.errors);
                loading.value = false;
            }
        }
    }

    const destroyRole = async (id) => {
        await axios.delete('/roles/' + id)
    }

    const updateStatusRole = async (id) => {
        let response = await axios.put('/update-status-role/' + id)
    }

   

    return {
        roles,
        role,
        errors,
        getRoles,
        getRole,
        storeRole,
        updateRole,
        destroyRole,
        loading,
        roleHasPermission,
        updateStatusRole,
        selectRoles,
    }
}