<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Fournisseur</label>
                  <vue-select
                    :options="suppliers"
                    placeholder="Choisir"
                    name="supplier_id"
                    v-model="form.supplier_id"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Date facture</label>
                  <VueDatePicker
                    v-model="form.purchase_date"
                    name="purchase_date"
                    :format="format"
                    :format-locale="fr"
                  ></VueDatePicker>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Date de livraison</label>
                  <VueDatePicker
                    v-model="form.expected_delivery_date"
                    name="expected_delivery_date"
                    :format="format"
                    :format-locale="fr"
                  ></VueDatePicker>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Numéro de facture</label>
                  <input
                    type="text"
                    v-model="form.number"
                    name="number"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Description</label>
                  <textarea class="form-control" name="note" v-model="form.note"></textarea
                  >
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Statut de livraison</label>
                  <vue-select
                    :options="deliveryStatus"
                    placeholder="Choisir"
                    name="delivery_status"
                    v-model="form.delivery_status"
                  />
                </div>
              </div>
              <div class="col-lg-12 col-sm-6 col-12">
                <div class="auto-complete-product-container">
                  <div class="form-group">
                    <label>Recherchez un produit</label>
                    <div class="input-groupicon">
                      <input
                        type="text"
                        placeholder="Scannez/recherchez le produit par code et sélectionnez..."
                        v-model="searchText"
                        @keyup="loadSuggestions()"
                      />
                      <div class="addonset">
                        <img
                          src="../../../assets/img/icons/scanners.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div
                      class="customer-search-suggestion"
                      v-if="suggestiondata.length"
                    >
                      <div class="customer-search-suggestion-inner">
                        <ul>
                          <li
                            v-for="(item, i) in suggestiondata"
                            @click="addCart(item)"
                            :key="item.id"
                          >
                            {{ item.designation }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Nom du produit</th>
                      <th>Qté</th>
                      <th>Prix ​​d'achat</th>
                      <th>Remise %</th>
                      <th>TVA %</th>
                      <th>Montant TTC</th>
                      <th class="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(itemcart, i) in listCart" :key="itemcart.id">
                      <td>
                        <!-- <a class="product-img">
                          <img :src="itemcart.image" alt="product" />
                        </a> -->
                        <a href="javascript:void(0);">{{
                          itemcart.designation
                        }}</a>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.quantity"
                          name="number"
                          required
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="itemcart.unit_price"
                          name="number"
                          readonly
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.product_discount"
                          name="number"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="itemcart.product_tax"
                          name="number"
                        />
                      </td>
                      <td class="text-end">
                        <span v-if="itemcart.quantity">
                          {{ (itemcart.quantity * itemcart.unit_price) - (itemcart.quantity * itemcart.unit_price * itemcart.product_discount / 100) +  (itemcart.quantity * itemcart.unit_price * itemcart.product_tax / 100)}}
                        </span>
                        <span v-else></span>
                      </td>
                      <td>
                        <a class="delete-set" @click="removeItem(i)"
                          ><img
                            src="../../../assets/img/icons/delete.svg"
                            alt="svg"
                        /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 float-md-right">
                <div class="total-order">
                  <ul>
                    <li>
                      <h4>TOTAL HT</h4>
                      <h5>{{ SubTotal() }}</h5>
                    </li>
                    <li>
                      <h4>TOTAL REMISE (-)</h4>
                        <h5>{{ TotalDiscount() }}</h5>
                    </li>
                    <li>
                      <h4>TOTAL TVA (+)</h4>
                      <h5>{{ TotalTax() }}</h5>
                    </li>
                    
                    <li class="total">
                      <h4>TOTAL TTC</h4>
                      <h5>{{ Total() }}</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <button
                  class="btn btn-primary btn-md"
                  type="submit"
                  :disabled="loading"
                  @click="handlerSubmit"
                >
                  <div v-if="loading" class="btn-content">
                    <b-spinner small type="grow"></b-spinner>
                    <span class="ms-2">Veuillez patienter...</span>
                  </div>

                  <div v-else class="btn-content">Enregistrer</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";
import useSuppliers from "@/composables/suppliers";
import usePurchases from "@/composables/purchases"
import { fr } from "date-fns/locale";


const { suppliers, selectSuppliers } = useSuppliers();
const { errors, storePurchase, loading } = usePurchases();


let searchText = ref("");
let suggestiondata = ref([]);
let form = ref([]);
let item = ref([]);
let listCart = ref([]);



const loadSuggestions = async () => {
    let response = await axios.get("/product-search?s=" + searchText.value);
    suggestiondata.value = response.data;
};

const addCart = (item) => {
  searchText.value = "";
  suggestiondata.value = "";

  let product_check = listCart.value.find((product) => product.product_id === item.id);

  if (product_check) {
    product_check.quantity++;
  } else {
    const itemcart = {
      product_id: item.id,
      designation: item.designation,
      unit_price: item.cost,
      quantity: 1,
      // image: item.image,
      product_tax: 0,
      product_discount: 0
    };
    listCart.value.push(itemcart);
  }
};

const removeItem = (i) => {
  listCart.value.splice(i, 1);
};

const SubTotal = () => {
  let total = 0;
  listCart.value.map((data) => {
    total = total + data.quantity * data.unit_price;
  });
  return total;
};

const TotalDiscount = () => {
  let total = 0;
  listCart.value.map((data) => {
    total = total + (data.quantity * data.unit_price * data.product_discount / 100);
  });
  return total;
};

const TotalTax = () => {
  let total = 0;
  listCart.value.map((data) => {
    total = total +  (data.quantity * data.unit_price * data.product_tax / 100);
  });
  return total;
};

const Total = () => {
  return SubTotal() - TotalDiscount() + TotalTax()
};

const indexForm = async () => {
  let response = await axios.get("/create-purchase");
  form.value = response.data;
};

onMounted(() => {
  indexForm()
  selectSuppliers()
})

const date = ref(new Date());
const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

const handlerSubmit = async () => {
  if (listCart.value.length >= 1) {
    let subtotal = 0;
    subtotal = SubTotal();

    let total = 0;
    total = Total();

    let totaldiscount = 0;
    totaldiscount = TotalDiscount();


    let totaltax = 0;
    totaltax = TotalTax();

    const formData = new FormData();

    formData.append("purchase_items", JSON.stringify(listCart.value));

    formData.append("supplier_id", form.value.supplier_id);
    formData.append("purchase_date", form.value.purchase_date);
    formData.append(
      "expected_delivery_date",
      form.value.expected_delivery_date
    );
    formData.append("number", form.value.number);
    formData.append("note", form.value.note);
    formData.append("subtotal", subtotal);
    formData.append("total", total);
    formData.append("totaldiscount", totaldiscount);
    formData.append("totaltax", totaltax);
    formData.append("delivery_status", form.value.delivery_status)

   await storePurchase(formData)
  }
};
</script>
<script>
export default {
  data() {
    return {
      title: "Achats fournisseurs",
      title1: "Ajouter/mettre à jour un achat",
      deliveryStatus: [
        { id: "0", text: "En attente" },
        { id: "1", text: "Livré" },
      ],
    };
  },
  name: "addpurchase",
};
</script>