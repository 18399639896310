<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <div class="search-path">
                  <a
                    class="btn btn-filter"
                    id="filter_search"
                    v-on:click="filter = !filter"
                    :class="{ setclose: filter }"
                  >
                    <img src="../../../assets/img/icons/filter.svg" alt="img" />
                    <span
                      ><img src="../../../assets/img/icons/closes.svg" alt="img"
                    /></span>
                  </a>
                </div>
                <div class="search-input">
                  <a class="btn btn-searchset"
                    ><img src="../../../assets/img/icons/search-white.svg" alt="img"
                  /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search..."
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"
                      ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                      ><img src="../../../assets/img/icons/excel.svg" alt="img"
                    /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                      ><img src="../../../assets/img/icons/printer.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->
            <div
              class="card"
              id="filter_inputs"
              :style="{
                display: filter ? 'block' : 'none',
              }"
            >
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <VueDatePicker
                          v-model="date_debut"
                          name="date_debut"
                          :format="format"
                          :format-locale="fr"
                        ></VueDatePicker>
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <VueDatePicker
                          v-model="date_fin"
                          name="date_fin"
                          :format="format"
                          :format-locale="fr"
                        ></VueDatePicker>
                    </div>
                  </div>
                  <div class="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div class="form-group">
                      <a class="btn btn-filters ms-auto"
                        ><img src="../../../assets/img/icons/search-whites.svg" alt="img"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Filter -->
            <div class="table-responsive">
              <a-table
                :columns="columns"
                :data-source="sales"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import useSales from '@/composables/sales'
import { onMounted, ref } from 'vue';
import { fr } from "date-fns/locale";

const { sales, getSaleReport } = useSales()
const date_debut = ref(new Date());
const date_fin = ref(new Date());

const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

onMounted(getSaleReport)

</script>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "DATE",
    dataIndex: "sale_date",
    sorter: {
      compare: (a, b) => {
        a = a.sale_date.toLowerCase();
        b = b.sale_date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "RÉFÉRENCE",
    key: "id",
    dataIndex: "reference",
    sorter: (a, b) => a.reference.localeCompare(b.reference),
  },
  {
    title: "CLIENT",
    dataIndex: "customer",
    sorter: {
      compare: (a, b) => {
        a = a.customer.toLowerCase();
        b = b.customer.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "BOUTIQUE",
    dataIndex: "warehouse_name",
    sorter: (a, b) => a.warehouse_name.localeCompare(b.warehouse_name),
  },

  {
    title: "TOTAL	",
    dataIndex: "total_amount",
    sorter: {
      compare: (a, b) => {
        a = a.total_amount.toLowerCase();
        b = b.total_amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "PAYÉ",
    dataIndex: "paid_amount",
    sorter: {
      compare: (a, b) => {
        a = a.paid_amount.toLowerCase();
        b = b.paid_amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "DÛ",
    dataIndex: "due_amount",
    sorter: {
      compare: (a, b) => {
        a = a.due_amount.toLowerCase();
        b = b.due_amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  
];
export default {
  data() {
    return {
      filter: false,
      title: "Rapports",
      title1: "Rapport des ventes",
      columns,
    };
  },
  name: "salesreport",
};
</script>
