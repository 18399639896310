<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /product list -->
        <form @submit.prevent="handlerSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="form-group">
                    <label>Libellé</label>
                    <input type="text" v-model="form.role" name="name"/>
                    <span class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</span>
                  </div>
                </div>
                
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="productdetails product-respon" style="margin-bottom: 10px;">
                    <ul>
                      <li v-for="(itemPerm, index) in permissions" :key="index">
                        <div class="input-checkset">
                          <ul>
                            <li>
                              <label class="inputcheck"
                                >{{ itemPerm.name }}
                                <input type="checkbox" :value="itemPerm.id" v-model="form.permission" :checked="isItemChecked(itemPerm.id, index)" @change="toggleCheckbox(index)"/>
                                <span class="checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button class="btn btn-primary btn-md" type="submit" :disabled="loading">
                    <div v-if="loading" class="btn-content">
                      <b-spinner small type="grow"></b-spinner>
                      <span class="ms-2">Veuillez patienter...</span>
                    </div>
                    <div v-else class="btn-content">Enregistrer</div>
                  </button>
                </div>
              </div>
            
            </div>
          </div>
        </form>
        <!-- /product list -->
      </div>
    </div>
  </div>
</template>
<script setup>
import axios from 'axios';
import { onMounted, ref, reactive } from 'vue'
import useRoles from '@/composables/roles';
import usePermissions from '@/composables/permissions';
import { useRouter } from "vue-router";
const { permissions , getPermissions } = usePermissions()
const router = useRouter();
const rolePermission = ref([]);

const { errors, loading, updateRole } = useRoles()

const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

const form = ref({
  role:'',
  permission: []
})


const getEdit = async (id) => {
    let response = await axios.get('/roles/'+id)
    form.value.role = response.data.data.role.name;
    rolePermission.value = response.data.data.rolePermission;
}

onMounted(() => {
  getEdit(props.id)
  getPermissions()
})

const isItemChecked = (value, index) => {
  if(value == rolePermission.value[value]) {
    form.value.permission[index] = value;
  }
  else{
    form.value.permission[index] = null;
  }
}

const toggleCheckbox = (index) => {
  if(form.value.permission[index] != null){
    rolePermission.value[form.value.permission[index]] = null;
    form.value.permission[index] = false;
  } else {
    rolePermission.value[form.value.permission[index]] =form.value.permission[index]
    form.value.permission[index] = form.value.permission[index]
  }
}

const handlerSubmit = async () => {

  let data = {
    name: form.value.role,
    permission: form.value.permission
  }
  await updateRole(props.id, data)
}
</script>
<script>
export default {
  data() {
    return {
      title: "Rôles",
      title1: "Modifier rôle",
    };
  },
  name: "editrole",
};
</script>
